import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CurrencySelector.css';
import { NigerianFlag, USFlag } from '../CurrencyFlags/CurrencyFlags';
import Header from '../PageHeader/Header';
import { CloseButton } from '../Buttons';

const CurrencySelector = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

 const currencies = [
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    symbol: '₦',
    flag: <NigerianFlag />
  },
  {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    flag: <USFlag />
  }
];

  const handleCurrencySelect = (currency) => {
    if (currency.code === 'NGN') {
      setShowModal(true);
    } else if (currency.code === 'USD') {
      navigate('usd-details');
    }
  };

  const handleModalContinue = () => {
    setShowModal(false);
    navigate('ngn-details');
  };

  return (
    <div className="currency-selector">
      <Header
        title="Select Currency"
        
      />

      <div className="currency-selector__list">
        {currencies.map((currency) => (
          <div key={currency.code} className="currency-item">
            <div className="currency-item__content">
              <div className="currency-item__flag">{currency.flag}</div>
              <div className="currency-item__info">
                <span>{currency.name} ({currency.symbol})</span>
              </div>
            </div>
            <button onClick={() => handleCurrencySelect(currency)}>
              <span className="sr-only">Select {currency.name}</span>
            </button>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="exchange-modal">
          <div className="exchange-modal__content">
            <div className="exchange-modal__header">
               <CloseButton onClick={() => setShowModal(false)} />
              <h2>About Exchange Rates</h2>
            </div>

            <div className="exchange-modal__rates">
              <div className="exchange-modal__rate-item">
                <div>
                  <p>USD Buy Rate</p>
                  <p className="text-soft">You're funding at this rate</p>
                </div>
                <p>₦1,690</p>
              </div>
              <div className="exchange-modal__rate-item">
                <div>
                  <p>USD Sell Rate</p>
                  <p className="text-soft">You're withdrawing at this rate</p>
                </div>
                <p>₦1,632</p>
              </div>
            </div>

            <p className="exchange-modal__disclaimer">
              These exchange rates are provided by independent third parties who handle fund conversions at the prevailing parallel rates and are not set, or controlled by Rise. They are subject to change based on market trends.
            </p>

            <button 
              className="exchange-modal__button"
              onClick={handleModalContinue}
            >
              Accept & Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencySelector;