import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ 
  checked = false, 
  onChange, 
  disabled = false,
  name,
  id 
}) => {
  return (
    <label className={`toggle-switch ${disabled ? 'disabled' : ''}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        id={id}
      />
      <span className="toggle-slider"></span>
    </label>
  );
};

export default ToggleSwitch;