import React from 'react';
import { Table } from "antd";
import './table.css'

const CustomTable = ({ columns, data, ...props }) => {
    return (
        <Table
            className='custom-table'
            columns={columns}
            dataSource={data}
            pagination={false}
            size={"middle"}
            {...props}
        />
    );
};

export default CustomTable;