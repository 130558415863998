import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../Buttons';
import './PreferencesForm.css';
import RadioUncheckedIcon from '../Icons/RadioUncheckedIcon';
import RadioCheckedIcon from '../Icons/RadioCheckedIcon';
import InfoIcon from '../Icons/InfoIcon';
import Header from '../PageHeader/Header';



const PREFERENCES = [
  { 
    id: '1', 
    label: 'Capital Growth', 
    value: '1' 
  },
  { 
    id: '0.5', 
    label: 'Stability', 
    value: '0.5' 
  },
  { 
    id: '0.1', 
    label: 'Safety', 
    value: '0.1' 
  }
];

const PreferencesForm = () => {
  const [preference, setPreference] = useState('');
  const navigate = useNavigate();
  const progressPercentage = 100;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (preference) {
      navigate('portfolio-view');
    }
  };

  const handlePreferenceChange = (value) => {
    setPreference(value);
  };

  const RadioButton = ({ option }) => (
    <label 
      key={option.id}
      className="preferences-form__radio-label"
    >
      <span className="preferences-form__radio-button">
        <input
          type="radio"
          name="preference"
          value={option.value}
          checked={preference === option.value}
          onChange={() => handlePreferenceChange(option.value)}
          className="preferences-form__radio-input"
        />
        <span className="preferences-form__radio-custom">
          <RadioUncheckedIcon className="preferences-form__radio-unchecked" />
          <RadioCheckedIcon className="preferences-form__radio-checked" />
        </span>
      </span>
      <span className="preferences-form__radio-text">
        {option.label}
      </span>
    </label>
  );

  return (
    <div className="preferences-form">
      <form onSubmit={handleSubmit}>
        <div className="preferences-form__content">
          <Header
            title="Preferences"
            
          />

          <div className="preferences-form__progress">
            <p className="preferences-form__progress-text">
              Question 5 of 5
            </p>
            <div className="preferences-form__progress-bar">
              <div 
                className="preferences-form__progress-fill"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>

          <p className="preferences-form__question">
            Which of these do you consider important?
          </p>

          <fieldset className="preferences-form__options">
            <div 
              className="preferences-form__radio-group" 
              role="radiogroup" 
              aria-label="preference"
            >
              {PREFERENCES.map((option) => (
                <RadioButton 
                  key={option.id} 
                  option={option} 
                />
              ))}
            </div>
          </fieldset>

          <div className="preferences-form__info-box">
            <InfoIcon />
            <span>
              You have to pick an option before clicking continue.
            </span>
          </div>

          <button
            type="submit"
            className={`preferences-form__submit-button ${!preference ? 'disabled' : ''}`}
            disabled={!preference}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default PreferencesForm;