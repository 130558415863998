import React from 'react';

const CardIcon = ({ className }) => {
  return (
    <svg 
      className={className}
      width="24" 
      height="24" 
      viewBox="0 0 42 42" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
    >
      <rect width="42" height="42" rx="21" fill="#71879C" fillOpacity="0.1" />
      <path 
        d="M14.8439 27.2949H27.2032C28.7267 27.2949 29.5001 26.5137 29.5001 25.0137V16.6621C29.5001 15.1543 28.7267 14.373 27.2032 14.373H14.8439C13.3126 14.373 12.5392 15.1465 12.5392 16.6621V25.0137C12.5392 26.5215 13.3126 27.2949 14.8439 27.2949ZM13.5079 16.709C13.5079 15.8105 13.9845 15.3418 14.8595 15.3418H27.1798C28.0392 15.3418 28.5392 15.8105 28.5392 16.709V17.334H13.5079V16.709ZM14.8595 26.3262C13.9845 26.3262 13.5079 25.8652 13.5079 24.959V18.784H28.5392V24.959C28.5392 25.8652 28.0392 26.3262 27.1798 26.3262H14.8595ZM15.8048 23.3652H17.7189C18.1798 23.3652 18.4923 23.0527 18.4923 22.6152V21.6621C18.4923 21.2246 18.1798 20.9199 17.7189 20.9199H15.8048C15.3517 20.9199 15.0392 21.2246 15.0392 21.6621V22.6152C15.0392 23.0527 15.3517 23.3652 15.8048 23.3652Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default CardIcon;