
import { Button, Flex, notification } from "antd";
import { accuracyRenderer, dateRenderer, directionRenderer, factorRenderer, linkRenderer, moneyRenderer, resultCellRenderer, selectRenderer, statusRenderer } from "./renderers";
import { useCallback, useMemo } from "react";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import EditableTable from "../../Component/EditableTable";

// (id, companyName, ticker, pattern, strategy, direction, entryDate, entry, releaseDate, output, days, risk, result, refund, factor, accuracy, portfolio, status)
const defaultColumns = [
    { title: "Тикер", dataIndex: "ticker", editable: true, render: (value) => _.isNil(value) ? '' : value.toUpperCase() },
    { title: "Название компании", dataIndex: "companyName", editable: false },
    { title: "Паттерн", dataIndex: "pattern" },
    { title: "Стратегия", dataIndex: "strategy" },
    { title: "Направление", dataIndex: "direction" },
    { title: "Дата входа", dataIndex: "entryDate", editable: true, type: "date", render: dateRenderer },
    { title: "Вход", dataIndex: "entry", editable: true, render: linkRenderer },
    { title: "Дата выхода", dataIndex: "releaseDate", editable: true, type: "date", render: dateRenderer },
    { title: "Выход", dataIndex: "output", editable: true, render: linkRenderer },
    { title: "Дни", dataIndex: "days" },
    { title: "Риск", dataIndex: "risk", editable: true, type: 'number', suffix: '₽', min: 0.01, render: moneyRenderer },
    { title: "Результат", dataIndex: "result", editable: true, type: 'number', suffix: '₽', render: resultCellRenderer },
    { title: "Возврат", dataIndex: "refund", align: 'right', render: (value) => _.isNil(value) ? '-' : value.toFixed(1) },
    { title: "Фактор", dataIndex: "factor", align: 'right', render: factorRenderer },
    { title: "Точность", dataIndex: "accuracy", align: 'center', render: accuracyRenderer },
    { title: "Портфель", dataIndex: "portfolio", render: moneyRenderer },
    { title: "Направление", dataIndex: "status" },
];

function DiaryTable({ isLoading, diarySettings, dataSource, onSyncUpdate, onClickAddEntry }) {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = useCallback((isSuccess) => {
        api[isSuccess ? "success" : "error"]({
            message: isSuccess ? "Обновление завершено": "Возникла проблема."
        });
    }, [api]);

    const addDiaryItemFielUpdate = useCallback((itemId, field, value) => {
        const update = { id: itemId, [field]: value };
        onSyncUpdate(update).then((isSuccess) => openNotification(isSuccess));
    }, [onSyncUpdate, openNotification]);

    const handleSave = useCallback((row, values) => {
        Object.keys(values).forEach(key => {
            addDiaryItemFielUpdate(row.id, key, values[key]);
        });
    }, [addDiaryItemFielUpdate]);
    
    const handleAddEntry = () => {
        onClickAddEntry().then((isSuccess) => openNotification(isSuccess));
    };

    const columns = useMemo(() => {
        const _columns = defaultColumns.map(column => {
            if (!column.editable) {
                return column;
            }
            return {
                ...column,
                onCell: (record) => ({
                    record,
                    editable: column.editable,
                    type: column.type,
                    suffix: column.suffix,
                    dataIndex: column.dataIndex,
                    title: column.title,
                    minnumber: column.min,
                    handleSave
                }),
            }
        });

        const getColumnByDataIndex = (dataIndex) => _columns.find(column => column.dataIndex === dataIndex);
        const patterns = _.isEmpty(diarySettings) ? [] : diarySettings.patterns;
        const strategies = _.isEmpty(diarySettings) ? [] : diarySettings.strategies;

        getColumnByDataIndex('pattern').render = selectRenderer(
            patterns, (id, pattern) => addDiaryItemFielUpdate(id, 'pattern', pattern)
        );

        getColumnByDataIndex('strategy').render = selectRenderer(
            strategies, (id, strategy) => addDiaryItemFielUpdate(id, 'strategy', strategy)
        );

        getColumnByDataIndex('direction').render = directionRenderer(
            (id, direction) => addDiaryItemFielUpdate(id, 'direction', direction)
        );

        getColumnByDataIndex('status').render = statusRenderer(
            (id, status) => addDiaryItemFielUpdate(id, 'status', status)
        );

        return _columns;
    }, [addDiaryItemFielUpdate, diarySettings, handleSave]);


    return (
        <div>
            {contextHolder}

            <Flex gap={8} className="table-buttons">
                <Button icon={<PlusOutlined />} type="primary" loading={isLoading}  onClick={handleAddEntry}>Добавить</Button>
            </Flex>

            <EditableTable
                className="diary-table"
                columns={columns}
                dataSource={dataSource}
                bordered
                size="small"
                pagination={{
                    postition: ['bottomRight'],
                    showSizeChanger: false,
                    pageSize: 10,
                }}
                scroll={{ x: 1500 }}
                loading={isLoading}
                
            />
        </div>
    );
}

export default DiaryTable