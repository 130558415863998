import React from 'react';
import AppHeader from "./AppHeader";
import SideMenu from "./Sidebar";
import PageContent from "./PageContent";
import AppFooter from "./AppFooter";

const DashboardBoardContainer = () => {
    return (
        <div className="App">
            <AppHeader/>
            <div className="SideMenuAndPageContent">
                <SideMenu></SideMenu>
                <PageContent></PageContent>
            </div>
            <AppFooter/>
        </div>
    );
};

export default DashboardBoardContainer;