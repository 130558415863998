import React from 'react'

function MeetExpertsSection() {
  return (
    <section className="meet container wide">
      <div className="meet__wrapper">
        <div className="meet__left">
          <p className='meet__title'> Meet the <span>Experts</span> </p>
        </div>
        <ul>
          <li>
            <img
              alt="John Doe"
              src="https://robohash.org/dd0"
              width={287}
              height={320}
              decoding="async"
              data-nimg="future"
              loading="lazy"
              style={{ color: "transparent" }}
            />
            <h3> John Doe </h3>
            <p> Founder &amp; CEO </p>
          </li>
          <li>
            <img
              alt="John Doe"
              src="https://robohash.org/dd2"
              width={287}
              height={320}
              decoding="async"
              data-nimg="future"
              loading="lazy"
              style={{ color: "transparent" }}
            />
            <h3> John Doe </h3>
            <p> Finance lead </p>
          </li>
          <li>
            <img
              alt="John Doe"
              src="https://robohash.org/dd42"
              width={287}
              height={320}
              decoding="async"
              data-nimg="future"
              loading="lazy"
              style={{ color: "transparent" }}
            />
            <h3> John Doe </h3>
            <p>Finance</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default MeetExpertsSection;