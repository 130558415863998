import { formatCurrency, getColorForNumber } from "../../utils";
import CustomTable from "../CustomTable/CustomTable";
import { useEffect, useState } from "react";
import { fetchFinancialResultStatistics } from "../../API/accountsService";
import { store } from "../../redux/store";
import { currentTinkoffAccountSelector } from "../../redux/slices/commonSlice";

const columns = [
    {
        title: '',
        dataIndex: 'title',
        render: (text) => <div style={{ fontWeight: 500 }}>{text}</div>,
        width: '68%',
    },
    {
        title: '',
        dataIndex: 'value',
        width: '68%',
        render: (num) => (
            <div style={{ color: getColorForNumber(num) }}>{formatCurrency(num, true)} ₽</div>
        ),
    },
];

export default function FinancialResult() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const state = store.getState();
    const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));

    useEffect(() => {
        return store.subscribe(() => {
        const state = store.getState();
        setSelectedAccount(currentTinkoffAccountSelector(state));
        });
    }, [setSelectedAccount]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await fetchFinancialResultStatistics(selectedAccount.id);
            const data = [
                { title: 'Kомиссия за операции, RUB', value: response.commisionFee },
                { title: 'Пополнение счета, RUB', value: response.depositMoney },
                { title: 'Оборот, RUB', value: response.tradingVolume },
            ];
            setData(data);
            setIsLoading(false);
        };
        fetchData().catch(console.error);
    }, []);

    return (
        <>
            <div className='details-titles-alignment'>
                <span className='details-titles-span'>Финансовый результат</span>
            </div>
            <CustomTable data={data} columns={columns} loading={isLoading} />
        </>
    );
}