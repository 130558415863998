import { DownloadAppButton, StartInvestButton } from "../../Component/Buttons";

export default function HeroSection() {
    return (
        <section className="hero">
            <div className="hero-container container wide">
                <div>
                    <div className="hero-content">
                        <h1 className="hero__title">
                            Dollar investments that help you <span className="hero__title--highlight">grow.</span>
                        </h1>
                        <p className="hero__description">
                            Rise gives you access to a carefully selected portfolio of global
                            investments, across US stocks, US real estate and fixed income assets.
                        </p>
                        <div className="hero__actions">
                            <StartInvestButton />
                            <DownloadAppButton />
                        </div>
                    </div>
                    <div className="hero-partners">
                        <h2 className="hero-partners__title">
                            Investors and Partners
                        </h2>
                        <img
                            src="https://risevest.com/_next/static/media/partners.f7ddc63e.webp"
                            alt="techstars,Flutterwave, Convera, Ventures Platform and ARM"
                            className="hero-partners__images"
                        />
                    </div>
                </div>
                <div></div>
            </div>
            <div className="hero-img">
                <div className="hero-img__container">
                    <div className="hero-img__ball-1">
                        <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                            <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                                <img
                                    style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }}
                                    alt=""
                                    aria-hidden="true"
                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271080%27%20height=%271080%27/%3e"
                                />
                            </span>
                            <img
                                alt=""
                                src="https://risevest.com/_next/static/media/ball.d87b5b9d.webp"
                                decoding="async"
                                className="hero-img__ball-img"
                                data-nimg="intrinsic"
                                style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
                            />
                        </span>
                    </div>
                    <div className="hero-img__ball-2">
                        <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                            <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                                <img
                                    style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0
                                    }}
                                    alt=""
                                    aria-hidden="true"
                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271080%27%20height=%271080%27/%3e"
                                />
                            </span>
                            <img
                                alt=""
                                src="https://risevest.com/_next/static/media/ball.d87b5b9d.webp"
                                decoding="async"
                                data-nimg="intrinsic"
                                className="hero-img__ball-img"
                                style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
                            />
                        </span>
                    </div>
                    <span className="hero-img__hand-container" style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                        <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                            <img
                                style={{
                                    display: "block",
                                    maxWidth: "100%",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0
                                }}
                                alt=""
                                aria-hidden="true"
                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271298%27%20height=%271847%27/%3e"
                            />
                        </span>
                        <img
                            alt=""
                            src="https://risevest.com/_next/static/media/holding-rise-app.68840e2c.webp"
                            decoding="async"
                            data-nimg="intrinsic"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: "100%",
                                maxHeight: "100%"
                            }}
                        />
                    </span>
                </div>
            </div>

        </section>
    );
}