import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import CardComponent from './CardComponent';
import './analyticsSection.css';
import { fetchCurrentAccountAnalytics } from '../../API/accountsService';
import { instrInRussian, sectorInRussian } from '../../utils';
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';
import { store } from '../../redux/store';

const { Title } = Typography;

const defaultData = {
    total: { value: 0, currency: "RUB" },
    count: 0,
    breakdown: []
}

function AnalyticsSection() {
    const [analytics, setAnalytics] = useState({
        assets: { ...defaultData },
        industry: { ...defaultData },
        companies: { ...defaultData }
    });
    const [isLoading, setIsLoading] = useState(true);
    const state = store.getState();
    const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState();
            setSelectedAccount(currentTinkoffAccountSelector(state));
        });
    }, [setSelectedAccount]);

    useEffect(() => {
        fetchCurrentAccountAnalytics(selectedAccount.id)
            .then((data) => {
                setAnalytics(data);
            })
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedAccount]);

    return (
        <div className='analytics-container'>
            <div>
                <div className='analytics-title-text'>
                    <Title level={2}>Аналитика</Title>
                </div>
                <div className='analytics-cards'>
                    <Row gutter={[16, 16]} justify="space-between">
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className='analytics-col'>
                            <div className="analytics-col-wrapper">
                                <CardComponent
                                    title="Активы"
                                    data={analytics.assets}
                                    isLoading={isLoading}
                                    formatter={instrInRussian}
                                />
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className='analytics-col'>
                            <div className="analytics-col-wrapper">
                                <CardComponent
                                    title="Компании"
                                    data={analytics.companies}
                                    isLoading={isLoading}
                                />
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} className='analytics-col'>
                            <div className="analytics-col-wrapper">
                                <CardComponent
                                    title="Отрасли"
                                    data={analytics.industry}
                                    isLoading={isLoading}
                                    formatter={sectorInRussian}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsSection;
