import axiosClient from "../axiosClient";

export async function createDiaryItem(payload) {
    return await axiosClient.post('/diary/items', payload);
}

export async function getAllDiaryItems() {
    return await axiosClient.get('/diary/items/all');
}

export async function updateDiaryItem(id, payload) {
    return await axiosClient.put(`/diary/items/${id}`, payload);
}

export async function getDiarySettings() {
    return await axiosClient.get('/diary/settings');
}

export async function updateDiarySettings(payload) {
    return await axiosClient.put('/diary/settings', payload);
}

export async function fetchTickerName(ticker) {
    return await axiosClient.get(`/ticker-name/${ticker}`);
}