import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CurrencySelector from '../CurrencySelector/CurrencySelector';
import FundWallet from '../FundWallet/FundWallet';
import FundWalletRoute from '../FundWalletRoute/FundWalletRoute';

const CurrencySelectorRoute = () => {
  return (
    <Routes>
      <Route index element={<CurrencySelector />} />
      <Route path="ngn-details/*" element={<FundWalletRoute />} />
      <Route path="usd-details" element={<FundWallet />} />
    </Routes>
  );
};

export default CurrencySelectorRoute;