import React from 'react'
import { CtaButton } from '../../Component/Buttons';

function RequestVCardSection() {
  return (
    <section className="request container wide">
      <div className="request__img-container">
        <img
          alt=""
          role="presentation"
          src="https://risevest.com/_next/static/media/virtual-card.6e909793.png"
          width={563}
          height={526}
          decoding="async"
          data-nimg="future"
          className="request__img"
          loading="lazy"
          style={{ color: "transparent" }}
        />
      </div>
      <div className="request__article">
        <p className="request__title">
          Make transactions online with <span>ease</span>
        </p>
        <p className="request__description">
          With the Rise US virtual card, you can pay for your subscriptions, shop
          online and enjoy a monthly transaction limit of up to $10,000.
        </p>
        <CtaButton href={"/home"} className="request__cta">Request for your US virtual card</CtaButton>
      </div>
    </section>

  );
}

export default RequestVCardSection