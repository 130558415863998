import React from 'react';
import { Typography, Divider } from 'antd';
import './customer.css';
import ContactIcon from '../Icons/ContactIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import PlayIcon from '../Icons/PlayIcon';


const { Title, Text } = Typography;

const CustomerCorner = () => {
  return (
    <div className="customer-corner">
      <Title level={4} className="customer-corner-title">Customer Corner</Title>
      <div className="customer-corner-items">
        <div className="customer-corner-item">
          <span className="icon-wrapper primary">
            <ContactIcon className="icon" />
          </span>
          <span className="content">
            <Text strong>Contact Us</Text>
            <Text type="secondary">Chat with a member of our<br/> customer success team.</Text>
          </span>
          <ChevronRightIcon className="arrow-icon" />
        </div>
        <Divider />
        <div className="customer-corner-item">
          <span className="icon-wrapper primary">
            <PlayIcon className="icon" />
          </span>
          <span className="content">
            <Text strong>Rise Onboarding Series</Text>
            <Text type="secondary">Watch our onboarding videos <br/>  to get started</Text>
          </span>
          <ChevronRightIcon className="arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default CustomerCorner;