import React, {useEffect, useState} from 'react';
import CustomTable from "../CustomTable/CustomTable";
import {formatNumber, formatNumberForQuantity, getColorForNumber, sectorInRussian} from "../../utils";
import {EllipsisOutlined} from "@ant-design/icons";
import './StocksinPortfolio.css'
import {currentTinkoffAccountSelector} from '../../redux/slices/commonSlice';
import {store} from '../../redux/store';
import {fetchPortFolioStocks} from '../../API/myportfolioService';


const columns = [
    {
        title: '',
        dataIndex: 'brandLogoUrl',
        width: '4%',
        render: (text) => (
            <span
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <img src={text} alt="brand logo" style={{marginLeft: '2px', width: '25px', height: '25px', borderRadius: '50%'}}/>
        </span>
        ),
    },


    {
        title: 'Акции',
        dataIndex: 'name',
        width: '10%'
    },
    {
        title: 'Сектор',
        dataIndex: 'sector',
        width: '10%'
    },
    {
        title: 'Кол-во',
        dataIndex: 'quantity',
        width: '10%',
        render: (num) => <div style={{color: '#131026'}}> {formatNumberForQuantity(num)} шт. </div>
    },
    {
        title: 'Цена/шт.',
        dataIndex: 'currentPrice',
        width: '10%',
        render: (text) => <div>{text.toLocaleString()} ₽</div>,
    },
    {
        title: 'Сумма',
        dataIndex: 'amount',
        width: '10%',
        render: (text) => <div>{text.toLocaleString()} ₽</div>,
    },
    {
        title: 'Средняя',
        dataIndex: 'averagePrice',
        width: '10%',
        render: (text) => <div>{text.toLocaleString()} ₽</div>,

    },

    {
        title: 'Профит',
        dataIndex: 'profit',
        width: '10%',
        render: (num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num)} ₽ </div>
    },
    {
        title: '%',
        dataIndex: 'percentage',
        width: '10%',
        render: (num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num)} % </div>

    },
    {
        title: 'Зафиксировано',
        dataIndex: 'fixedProfit',
        render: (num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num)} ₽ </div>

    },


];


const StocksInPortfolio = () => {
    const [Data, setData] = useState([]);
    const [totalProfit, setTotalProfit] = useState(0);

    const selectedAccount = currentTinkoffAccountSelector(store.getState()) || { id: "2175574538" };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchPortFolioStocks(selectedAccount.id);
                const processedData = data.map(stock => ({
                    ...stock,
                    percentage: ((stock.currentPrice - stock.averagePrice) / stock.averagePrice) * 100,
                    sector: sectorInRussian(stock.sector)
                }));
                setData(processedData);

                const totalProfit = processedData.reduce((sum, stock) => {
                    return sum + stock.profit;
                }, 0);
                setTotalProfit(totalProfit);
            } catch (error) {
                console.error('Error fetching portfolio stocks:', error);
            }
        };

        fetchData();
    }, [selectedAccount.id]);

    return (
        <div className='stocks-container'>
            <div className='titles-alignment'>
                <span style={{ fontWeight: 510, fontSize: 30 }}>Бумаги в портфеле</span>
                <div className='title-left'>
                    <span style={{ fontWeight: 510, fontSize: 21 }}>Открытые позиции</span>
                    <div className='title-right2'>
                        <span style={{
                            backgroundColor: '#E6521F',
                            display: 'flex',
                            justifyContent: "center",
                            alignContent: 'center',
                            fontWeight: 510,
                            fontSize: 17,
                            marginLeft: 10,
                            padding: 6,
                            border: 'solid',
                            borderRadius: 5,
                            borderColor: "#E6521F"
                        }}>
                            <text> {formatNumber(totalProfit)} ₽ </text>
                        </span>
                    </div>
                </div>
            </div>
            <div className='header-column'>
                <EllipsisOutlined style={{ fontSize: 32 }} />
            </div>
            <div className='scrollable-container'>
                <CustomTable data={Data} columns={columns} />
            </div>
        </div>
    );
};

export default StocksInPortfolio;