import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CustomCard from '../CustomCard/CustomCard';
import './PlansContent.css';

const PlansContent = ({ plans, assetClasses, goals, isCreateInvestmentPlan = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return (
        <div className="plans-content">
            {!isCreateInvestmentPlan && 
            (
                <>
                    <section className="section">
                        <h2>Your plans</h2>
                        <div className="card-scroll">
                            {plans.map((plan) => (
                                <Link key={plan.id} to={`${plan.id}`}>
                                    <CustomCard 
                                        planName={plan.name} 
                                        planBalance={plan.balance}
                                        planType={plan.description} 
                                        backgroundImage={plan.image}
                                    />
                                </Link>
                            ))}
                        </div>
                    </section>
                </>
            )}
            
            {isCreateInvestmentPlan && (
                <header className="create-plan-header">
                    <button className="return-button" onClick={() => navigate(-1)}>
                        <LeftOutlined />
                    </button>
                    <h1>Create Plan</h1>
                </header>
            )}

            <section className="section">
                <Link 
                    className="build-wealth-card"
                    to="build-wealth"
                    style={{
                        backgroundImage: "url('https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg')"
                    }}
                >
                    <div className="card-content">
                        <div className="card-text">
                            <p className="card-title">Build Wealth</p>
                            <p className="card-description">
                                Invest for long-term goals like retirement with this plan. 
                                It's your financial freedom partner. Unsure of what to invest in? Start here.
                            </p>
                            <p className="start-investing">Start Investing</p>
                        </div>
                        <svg className="arrow-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowRightIcon">
                            <path d="m10 17 5-5-5-5z" fill="white"></path>
                        </svg>
                    </div>
                </Link>
            </section>
            
            <section className="section">
                <h2>Asset classes</h2>
                <div className="card-scroll">
                    {assetClasses.map((assetClass) => (
                        <Link key={assetClass.id} to={`asset/${assetClass.id}`}>
                            <CustomCard 
                                planName={assetClass.name} 
                                planType={assetClass.risk} 
                                backgroundImage={assetClass.image}
                            />
                        </Link>
                    ))}
                </div>
            </section>
            
            <section className="section">
                <h2>Goals</h2>
                <div className="card-scroll">
                    {goals.map((goal) => (
                        <Link key={goal.id} to={`goal/${goal.id}`}>
                            <CustomCard 
                                planName={goal.name} 
                                backgroundImage={goal.image}
                            />
                        </Link>
                    ))}
                </div>
            </section>
            <Link to="matured-plans" className="matured-plans">
                <h2>Your matured plans</h2>
                <span className="count">0</span>
                <RightOutlined />
            </Link>
        </div>
    );
}

export default PlansContent;