import React from 'react';
import { useParams } from 'react-router-dom';
import FixedIncomePlan from '../PlansId/FixedIncomePlan';
import BuildWealth from '../BuildWealth/BuildWealth';
import StockPlan from '../StockPlan/StockPlan';
import StartBusinessPlan from '../StartBusinessPlan/StartBusinessPlan';
import RemadeRealEstatePlan from '../RemadeRealEstatePlan/RemadeRealEstatePlan';

export const DynamicPlanRouter = () => {
  const { id } = useParams();

  const getPlanComponent = (id) => {
    if (id.startsWith('549875')) {
      return <FixedIncomePlan id={id} />;
    } else if (id.startsWith('549874')) {
      return <RemadeRealEstatePlan id={id} />;
    } else {
      return <StartBusinessPlan id={id} />;
    }
  };

  return getPlanComponent(id);
};


export const BuildWealthRouter = () => {
  const { type } = useParams();
  return <BuildWealth type={type} />;
};


export const AssetRouter = () => {
  const { id } = useParams();
  
  const getAssetComponent = (id) => {
    switch(id) {
      case '1':
        return <StockPlan id={id} />;
      case '2':
        return <StartBusinessPlan id={id} />;
      case '4':
        return <FixedIncomePlan id={id} />;
      default:
        return <div>Unknown Asset Type</div>;
    }
  };

  return getAssetComponent(id);
};


export const GoalRouter = () => {
  const { id } = useParams();
  
  const getGoalComponent = (id) => {
    switch(id) {
      case '1':
        return <StartBusinessPlan id={id} />;
      default:
        return <div>Goal Planning Component for ID: {id}</div>;
    }
  };

  return getGoalComponent(id);
};