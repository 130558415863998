import React, { useState, useRef, useEffect } from 'react';
import './AssetMixChart.css';

const AssetMixChart = () => {
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const chartRef = useRef(null);

  const data = [
    { name: 'Stocks', value: 62.08, color: '#8A56E2' },
    { name: 'Real Estate', value: 37.92, color: '#00C6BE' }
  ];

  const total = data.reduce((sum, item) => sum + item.value, 0);
  let startAngle = 0;

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (chartRef.current) {
        const rect = chartRef.current.getBoundingClientRect();
        setMousePosition({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top
        });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="asset-mix-chart" ref={chartRef}>
      <h2>Your asset mix</h2>
      <div className="chart-container">
        <svg viewBox="0 0 100 100">
          {data.map((item, index) => {
            const angle = (item.value / total) * 360;
            const largeArcFlag = angle > 180 ? 1 : 0;
            const endAngle = startAngle + angle;
            
            const x1 = 50 + 40 * Math.cos((startAngle * Math.PI) / 180);
            const y1 = 50 + 40 * Math.sin((startAngle * Math.PI) / 180);
            const x2 = 50 + 40 * Math.cos((endAngle * Math.PI) / 180);
            const y2 = 50 + 40 * Math.sin((endAngle * Math.PI) / 180);

            const pathData = `
              M 50 50
              L ${x1} ${y1}
              A 40 40 0 ${largeArcFlag} 1 ${x2} ${y2}
              Z
            `;

            startAngle = endAngle;

            return (
              <path
                key={index}
                d={pathData}
                fill={item.color}
                className={hoveredSegment === index ? 'hovered' : ''}
                onMouseEnter={() => setHoveredSegment(index)}
                onMouseLeave={() => setHoveredSegment(null)}
              />
            );
          })}
          <circle cx="50" cy="50" r="25" fill="white" />
        </svg>
        <div className="center-icon">
          <span role="img" aria-label="fire">🔥</span>
        </div>
      </div>
      {hoveredSegment !== null && (
        <div 
          className="hover-info" 
          style={{ 
            left: `${mousePosition.x}px`, 
            top: `${mousePosition.y}px`,
            backgroundColor: data[hoveredSegment].color
          }}
        >
          <span>{data[hoveredSegment].name}: </span>
          <strong>{data[hoveredSegment].value.toFixed(2)}%</strong>
        </div>
      )}
    </div>
  );
};

export default AssetMixChart;