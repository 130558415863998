import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button 
      className="back-button"
      onClick={() => navigate(-1)}
      type="button"
    >
      <span className="sr-only">Go back to previous page</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path 
          d="M15 18l-6-6 6-6" 
          stroke="var(--action-primary)"
          strokeWidth="2" 
          fill="none" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default BackButton;