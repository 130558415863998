import React, { useEffect, useState } from 'react';
import './currenciesSection.css';
import {Typography} from 'antd';
import CustomTable from "../CustomTable/CustomTable";
import {formatNumber, getColorForNumber} from "../../utils";
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';
import { store } from '../../redux/store';
import { fetchPortFolioCurrencies } from '../../API/myportfolioService';


const {Title} = Typography;

const columns = [
        {
        title: '',
        dataIndex: 'flagUrl',
        width: '4%',
        render: (flagUrl) => (
            <span className='currency-flag-container'>
                <img src={flagUrl} alt="brand logo" className='currency-flag' />
            </span>
        ),
    },
    {
        title: 'Наименование',
        dataIndex: 'name',
        width: '10%'
    },
    {
        title: '',
        dataIndex: 'isoCode',
        render: (text) => <div
            style={{color: "#939393", fontWeight: 600, display: 'flex'}}>{text.toUpperCase()}</div>,
        width: '10%'
    },
    {
        title: 'Баланс',
        dataIndex: 'balance',
        width: '10%',
        render: (text) => `${text.toLocaleString()} шт`,
    },
    {
        title: 'Цена/шт.',
        dataIndex: 'unitPrice',
        width: '10%',
        render: (text) => `${text.toLocaleString()} ₽`,
    },
    {
        title: 'Сумма',
        dataIndex: 'total',
        width: '10%',
        render: (text) => `${text.toLocaleString()} ₽`,
    },
    {
        title: 'Средняя',
        dataIndex: 'averagePrice',
        width: '10%',
        render: (text) => `${text.toLocaleString()} ₽`,


    },

    {
        title: 'Профит',
        dataIndex: 'profit',
        render: (num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num).toLocaleString()} ₽ </div>,
        width: '10%'
    },
    {
        title: '%',
        dataIndex: 'percentage',
        render: (num) => <div style={{color: getColorForNumber(num)}}> {formatNumber(num)} % </div>

    },


];

const CurrenciesSection = () => {
      const [Data, setData] = useState([]);
    const selectedAccount = currentTinkoffAccountSelector(store.getState()) || { id: "2175574538"};

    useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPortFolioCurrencies(selectedAccount.id);

        const processedData = data.map(currency => ({
                    ...currency,
                    percentage: ((currency.unitPrice - currency.averagePrice) / currency.averagePrice) * 100
                }));
        setData(processedData);
      } catch (error) {
        console.error('Error fetching portfolio stocks:', error);
      }
    };

    fetchData();

  }, [selectedAccount.id]);
  console.log(Data);
    return (
        <div className='currencies-container'>

            <div className='currency-title'>
                <Title level={3}>Денежные средства</Title>
            </div>
            <div className='scrollable-container'>
                <CustomTable  data={Data} columns={columns} />
            </div>
        </div>
    );
};
export default CurrenciesSection;