import { useState } from "react";
import { AuthLayout } from "../../Component/AuthLayout";
import style from "./index.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import { login, loginWithTelegram } from "../../API/authService";
import { addAuth } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { TinkoffButton } from "../../Component/AuthLayout/TinkoffButton";
import { TelegramButton } from "../../Component/AuthLayout/TelegramButton";
import { InputField } from "../../Component/AuthLayout/InputField";
import { Alert } from "antd";

export default function LoginPage() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const[formData, setFormData] = useState({
        name: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setIsLoading(true);
        const payload = {
            ...formData,
            username: formData.name,
            password: formData.password,
        };
        login(payload).then(data => {
            dispatch(addAuth({ user: data }));
            navigate("/");
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleTelegramAuth = (authData) => {
        const payload = {
            telegramId: authData.id,
            username: authData.username,
            firstname: authData.first_name,
            lastname: authData.last_name,
            authDate: authData.auth_date,
            photoUrl: authData.photo_url,
            hash: authData.hash
        };
        setError(null);
        setIsLoading(true);

        loginWithTelegram(payload).then(data => {
            dispatch(addAuth({ user: data }));
            navigate("/");
        }).catch(error => {
            error.type = 'telegram';
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const Errors = () => {
        if (!error) return null;
        const { errors } = error;
        if (errors)
            return <ul className='error'>{Object.keys(errors).map(field =>
                <li key={field}>{`${field}: ${errors[field]}`}</li>
            )}</ul>;
        return <p className='error'>{error.detail}</p>;
    }

    return (
        <AuthLayout>
            <div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <h1 className={style.title}>Welcome back</h1>
                    <p className={style.description}>
                        Let's get you logged in to get back to building your
                        dollar-denominated investment portfolio.
                    </p>
                    { 
                        error &&
                        <Alert
                            message={Errors()}
                            type="error"
                            className={style.alert}
                            closable
                            onClose={() => setError(null)}
                        />
                    }
                    <InputField
                        label="Username"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <InputField
                        label="Password"
                        name="password" 
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                    />

                    <div className={style.authButtons}>

                        <button
                            className={style.submitButton}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <LoadingOutlined size={32} width="1rem" /> : "Sign in"}
                        </button>
                        <Divider plain style={{ borderColor: "#0003"}}> OR </Divider>
                        <TelegramButton onAuth={handleTelegramAuth} />
                        <TinkoffButton />
                    </div>

                    <div className={style.details}>
                        <a href="/send-reset-password" className={style.url}>I forgot my password</a>
                        <p>
                            Don't have an account?&nbsp;
                            <a href="/register" className={style.url}>Sign up</a>
                        </p>
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}



