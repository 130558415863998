import React from 'react';
import './ExchangeRateModal.css';
import Header from '../PageHeader/Header';

const ExchangeRateModal = ({ onClose, onAccept }) => (
  <div className="modal-overlay">
    <div 
      className="modal-container"
      tabIndex="-1"
    >
      <div className="modal-content">
        <Header
          title="About Exchange Rates"
          
          containerClassName="modal-header"
          titleClassName="modal-title"
          backButtonClassName="close-button"
          backIconClassName="close-icon"
        />

        <div className="rates-section">
          <div className="rate-item">
            <div className="rate-info">
              <p className="rate-label">USD Buy Rate</p>
              <p className="rate-subtitle">You're funding at this rate</p>
            </div>
            <p className="rate-value">
              <span className="currency">₦</span>1,762
            </p>
          </div>
          <div className="rate-item">
            <div className="rate-info">
              <p className="rate-label">USD Sell Rate</p>
              <p className="rate-subtitle">You're withdrawing at this rate</p>
            </div>
            <p className="rate-value">
              <span className="currency">₦</span>1,701
            </p>
          </div>
        </div>

        <p className="disclaimer">
          These exchange rates are provided by independent third parties who 
          handle fund conversions at the prevailing parallel rates and are not set, 
          or controlled by Rise. They are subject to change based on market trends.
        </p>

        <button 
          className="accept-button"
          onClick={onAccept}
          type="button"
        >
          Accept & Continue
        </button>
      </div>
    </div>
  </div>
);

export default ExchangeRateModal;