import React from 'react';
import { Link } from 'react-router-dom';
import './ViewAllPlans.css';
import BigPlanCard from '../BigPlanCard/BigPlanCard';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';



const ViewAllPlans = () => {
 const plans = [
  {
    id: "6f371a03-b7b0-4b14-bd10-1389b0c28361",
    name: "nkjjkjkjk",
    balance: "0.00",
    percentage: "0.00",
    profitLoss: "0.00",
    image: "https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg"
  },
  {
    id: "7ca295fb-121a-4f0f-b6cb-0560004e375f",
    name: "Emmanuel",
    balance: "0.00",
    percentage: "0.00",
    profitLoss: "0.00",
    image: "https://res.cloudinary.com/dsjxfieim/image/upload/v1634304409/Plan%20Covers/Untitled_2_ox4b5h.jpg"
  },
  {
    id: "4cef4d0b-fe8b-45eb-a26c-8dd5bc08413d",
    name: "Remade",
    balance: "77.50",
    percentage: "1.12",
    profitLoss: "0.87",
    image: "https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg"
  },
  {
    id: "654f71c7-379e-4f27-a2c6-c94ce5c68de3",
    name: "Test",
    balance: "0.00",
    percentage: "0.00",
    profitLoss: "0.00",
    image: "https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg"
  },
];


  return (
    <div className="dashboard">
      <div className="dashboard__content">
        <div className="dashboard__container">
          <Header
            title="Your Plans"
            
          />
          
          <div className="plans-grid">
            {plans.map((plan) => (
                <Link key={plan.id} to={`../${plan.id}`} relative="path">
                    <BigPlanCard 
                        href={`../${plan.id}`}
                        title={plan.name}
                        balance={plan.balance}
                        percentage={plan.percentage || "0.00"}
                        amount={plan.profitLoss || "0.00"}
                        backgroundImage={plan.image}
                    />
                </Link>
            ))}
          </div>
          
          <div 
            className="loader" 
            aria-hidden="true" 
            data-test-id="loader"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewAllPlans;