import React, { useEffect, useRef, useState } from 'react';
import { Badge, Empty, Segmented, Select, Tabs } from 'antd';
import { AppstoreOutlined, BarsOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import './details.css';
import FinancialResult from './FinancialResult';
import { ResponsiveContainer, Treemap } from 'recharts';
import { fetchAssetsProfit } from '../../API/accountsService';
import VerticalBarChart from '../VerticalBarChart';
import { store } from '../../redux/store';
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';
import _ from 'lodash';

const { TabPane } = Tabs;

const formatAmount = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(2)}k ₽`;
    } else {
        return `${value} ₽`;
    }
};


const DetailsSection = () => {
    const containerRef = useRef(null);
    const [activeTab, setActiveTab] = useState('1d');
    const [colorPercentages, setColorPercentages] = useState({});
    const [displayType, setDisplayType] = useState('treemap');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const state = store.getState();
    const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));

    useEffect(() => {
        return store.subscribe(() => {
        const state = store.getState();
        setSelectedAccount(currentTinkoffAccountSelector(state));
        });
    }, [setSelectedAccount]);

    const loadData = async (period) => {
        try {
            setIsLoading(true);
            const responseData = await fetchAssetsProfit(period,selectedAccount.id);
            if (_.isNil(responseData)) return;
            setData(responseData);
        } catch(e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData(activeTab);
    }, []);

    const handleTabChange = (key) => {
        setActiveTab(key);
        loadData(key);
    };

    const handleDisplayTypeChange = async (value) => {
        setDisplayType(value);
    };


    const total = data.reduce((acc, item) => acc + Math.abs(item.value), 0);

    const treemapData = data.map((data) => {
        return ({
            name: data.ticker,
            children: [{
                name: data.ticker,
                amount: Math.abs(data.value),
                sign: data.value > 0 ? '+' : '-',
                percentage: (data.value / total * 100).toFixed(2),
            }],
        });
    });

    const barChartData = data.map((data) => {
        return ({
            name: data.ticker,
            profit: (data.value / total * 100).toFixed(2),
        });
    });

    return (
        <div className='details-container'>
            <FinancialResult />
            <div className='details-titles-alignment'>
                <div className='details-titles-span'>Прибыль от активов</div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='details-titles-span2'>
                        <span style={{ fontWeight: "400", fontSize: 16 }}>Размер:</span>
                        <Select
                            bordered={false}
                            showSearch
                            style={{ width: 120 }}
                            placeholder="Прибыль"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[
                                {
                                    value: '1',
                                    label: 'Прибыль 1',
                                },
                                {
                                    value: '2',
                                    label: 'Прибыль 2',
                                },
                                {
                                    value: '3',
                                    label: 'Прибыль 3',
                                },
                            ]}
                        />
                        <Segmented
                            className='segmented'
                            options={[
                                {
                                    value: 'list',
                                    icon: <BarsOutlined />,
                                },
                                {
                                    value: 'treemap',
                                    icon: <AppstoreOutlined />,
                                },
                            ]}
                            value={displayType}
                            onChange={handleDisplayTypeChange}
                        />
                        <Badge dot={true} className="green-dot-badge">
                            <EllipsisOutlined style={{ fontSize: '20px' }} />
                        </Badge>
                    </div>
                </div>
            </div>
            <div className='treemap-tab' >
                <Tabs onChange={handleTabChange} tabBarStyle={{ borderBottom: 'none', outline: 'none' }}>
                    <TabPane tab="1D" key="1d" />
                    <TabPane tab="2D" key="2d" />
                    <TabPane tab="1M" key="1m" />
                    <TabPane tab="3M" key="3m" />
                    <TabPane tab="6M" key="6m" />
                    <TabPane tab="YTD" key="ytd" />
                    <TabPane tab="1r" key="1y" />
                    <TabPane tab="5n" key="5y" />
                    <TabPane tab="BCe" key="all" />
                </Tabs>
            </div>
            <br></br>
            <div className='details-treemap'>
                {
                    isLoading
                        ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                            <LoadingOutlined size={32} />
                        </div>
                        : data.length === 0
                            ? <Empty />
                            : <div>
                                {displayType === 'treemap' ? (
                                    <ResponsiveContainer width={'100%'} height={600}>
                                        <Treemap isAnimationActive={false} width={400} height={200} data={treemapData} dataKey="amount" stroke="#fff" fill="#8884d8" content={
                                            <CustomizedContent />
                                        } />
                                    </ResponsiveContainer>
                                ) : (
                                    <div style={{ marginTop: '5px', fontWeight: 550, fontSize: '14px' }}>
                                        <VerticalBarChart data={barChartData} />
                                    </div>
                                )}
                            </div>
                }
            </div>

        </div>

    );
};

function CustomizedContent(props) {
    const { area, root, depth, x, y, width, height, index, children, name } = props;
    const amount = children ? children[0].amount : 0;
    const sign = children ? children[0].sign : '';
    const percentage = children ? children[0].percentage : 0;
    const isSafeArea = width > 100 && height > 50;

    const getColor = (p) => {
        switch (true) {
            case p < -15: return "#A64D3B";
            case p <= -6: return "#D1685E";
            case p < 0: return "#DB7E7A";
            case p < 6: return "#A2C7C5";
            case p < 15: return "#67B8B4";
            default: return "#5C9A96";
        }
    };

    return (
        <g>
            <rect
                x={x}
                y={y}
                rx={5}
                width={width}
                height={height}
                style={{
                    fill: depth < 2 ? getColor(percentage) : '#ffffff00',
                    stroke: '#fff',
                    strokeWidth: 2 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10),
                }}
            />
            {depth === 1 && isSafeArea ? (
                <text x={x + width / 2} y={y + height / 2 - 4} textAnchor="middle" fill="#fff" fontSize={12}>
                    {name}
                </text>
            ) : null}
            {depth === 1 && isSafeArea ? (
                <text x={x + width / 2} y={y + height / 2 + 16} textAnchor="middle" fill='#fff' fontSize={10} fontWeight="lighter">
                    {sign}{formatAmount(amount)}
                </text>
            ) : null}
        </g>
    );
}

export default DetailsSection;
