import React from 'react';
import { Button, Card } from 'antd';
import {CalendarOutlined, SettingOutlined, QuestionOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './StartBusinessPlan.css';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const StartBusinessPlan = () => {
  const navigate = useNavigate();

   const handleClick = () => {
    navigate('plan-name')
  };
  return (
    <div className="start-business-plan">
      <Header
        title="Start a Business"
        
      />


      <img 
        src="https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304112/Plan%20Covers/Untitled_1_guke37.jpg" 
        alt="Business Plan Cover" 
        className="cover-image"
      />

      <p className="description">
        A short to mid-term investment plan to start your dream business.
      </p>

      <div className="features">
        <div className="feature">
          <div className="feature-icon">
            <QuestionOutlined />
          </div>
          <p>Getting started</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <CalendarOutlined />
          </div>
          <p>Turn on auto-invest</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <SettingOutlined />
          </div>
          <p>Modify as you progress</p>
        </div>
      </div>

      <Card className="info-card">
        <InfoCircleOutlined className="info-icon" />
        <p>To manage your investments, Risevest charges a fee of 0.5% per year on your funds.</p>
      </Card>

      <Button
        type="primary"
        size="large"
        block
        className="continue-button"
        onClick={handleClick}
      >
        Continue
      </Button>
    </div>
  );
};

export default StartBusinessPlan;