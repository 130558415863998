import React, {useState} from 'react';
import Registration from "../Component/Registration-page/Registration";
import Welcome from "../Component/welcomePage/Welcome";
import { userAuthenticated } from '../utils';
import { Navigate } from 'react-router-dom';

const WelcomePage = () => {
    const [showRegistration, setShowRegistration] = useState(false);

    const handleRegisterClick = () => {
        setShowRegistration(true);
    };

    if (userAuthenticated())
        return <Navigate to="/" replace />;

    return (
        <div>
            {showRegistration ? (
                <Registration />
            ) : (
                <Welcome onRegisterClick={handleRegisterClick} />
            )}
        </div>
    );
};

export default WelcomePage;