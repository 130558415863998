import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Typography, Button, Badge, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './dashboard.css';
import BalanceCard from '../Balance/BalanceCard';
import CustomCard from '../CustomCard/CustomCard';
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import CustomerCorner from '../CustomerCorner/CustomerCorner';
import QuoteCard from '../QuoteCard/QuoteCard';
import { BalanceVisibilityProvider } from '../BalanceVisibilityContext/BalanceVisibilityContext';
import { NotificationIcon2 } from '../Icons/NotificationIcon2';
import AddIconMain from '../Icons/AddIconMain';
import RiseLogo from '../Icons/RiseLogo';
import ChevronRightIcon from '../Icons/ChevronRightIcon';

const { Title, Text } = Typography;

const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) {
    return 'Good morning ⛅️';
  } else if (hour >= 12 && hour < 18) {
    return 'Good afternoon ☀️';
  } else if (hour >= 18 && hour < 22) {
    return 'Good evening 🌙';
  } else {
    return 'Good night 🌠';
  }
};

const slides = [
  { image: 'https://money.rise.capital/wp-content/uploads/2024/08/web-1-1.png', title: "Started" },
  { image: 'https://rise-feeds-campaigns.s3.eu-west-1.amazonaws.com/1701458154262.jpg', title: "Started2" },
  { image: 'https://rise-feeds-campaigns.s3.eu-west-1.amazonaws.com/1695819478247.png', title: "Started3" },
  { image: 'https://rise-feeds-campaigns.s3.eu-west-1.amazonaws.com/1695819478247.png', title: "Started4" },
];

const plans = [
  { 
    id: '549875', 
    name: 'Remade', 
    balance: '$0.00', 
    description: 'Fixed Income Plan', 
    image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg' 
  },
  { 
    id: '549874', 
    name: 'Test', 
    balance: '$0.00', 
    description: 'Stocks Plan', 
    image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304409/Plan%20Covers/Untitled_2_ox4b5h.jpg' 
  },
  { 
    id: '540593', 
    name: 'EmmaG Risking it', 
    balance: '$0.00', 
    description: 'Real Estate Plan Plan', 
    image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg' 
  },
];

const DashboardContent = () => {
  const greeting = getGreeting();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleShare = () => {
    console.log('Share button clicked');
  };

  const handleSlideChange = () => {
    setCurrentSlide(slides);
  };

  const handleCreatePlan = () => {
    navigate('plans/create-investment-plan');
  };

  const handleAddMoney = () => {
    navigate('wallet/fund-wallet');
  };

  return (
    <BalanceVisibilityProvider>
      <div className="dashboard-main-content">
        <div className="header">
          <div className="user-info">
            <Text className="greeting" data-test-id="greeting">{greeting}</Text>
            <Title level={4} className="username" data-test-id="username">dsmurts</Title>
          </div>
          <div className="actions">
            <Link to="more/refer">
              <Button className="refer-button">
                <img src="/tradetrackr/refer.png" alt="R" />
                <em>Refer & earn!</em>
              </Button>
            </Link>
            <Badge count={0} showZero={false} className="notification-badge">
              <Link to="more/notifications">
                <Button 
                  className="notification-button" 
                  icon={<NotificationIcon2 />}
                  type="text"
                  aria-label="Notifications"
                />
              </Link>
            </Badge>
          </div>
        </div>

        {isMobile && (
          <CustomCarousel slides={slides} onSlideChange={handleSlideChange} />
        )}

        <BalanceCard />
        <button className="add-money-button" onClick={handleAddMoney}>
          <AddIconMain />
          Add money
        </button>

        <div className="plans-section">
          <div className="plans-header">
            <h2 className="plans-title">Your plans</h2>
            <Link to="plans/your-plans" className="view-all-link">
              <span>View all plans</span>
              <ChevronRightIcon/>
            </Link>
          </div>
          <div className="plans-scroll-container">
            <div className="plans-row">
              <Card className="plan-card new-plan" onClick={handleCreatePlan}>
                <div className="circular-container">
                  <PlusOutlined />
                </div>
                <Text className="plan-text">Create an <br/> investment plan</Text>
              </Card>
              
              {plans.map((plan) => (
                <Link key={plan.id} to={`plans/${plan.id}`}>
                  <CustomCard 
                    planName={plan.name} 
                    planBalance={plan.balance}
                    planType={plan.description} 
                    backgroundImage={plan.image}
                  />
                </Link>
              ))}
            </div>
          </div>
          {isMobile && (
            <>
              <CustomerCorner />
              <QuoteCard 
                title="Make the best of Rise"
                quote="I will tell you how to become rich. Be fearful when others are greedy and be greedy when others are fearful."
                author="Warren Buffet"
                onShare={handleShare}
              />
            </>
          )}
        </div>
        <RiseLogo />
      </div>
    </BalanceVisibilityProvider>
  );
};

export default DashboardContent;