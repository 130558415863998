import React from 'react';
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './SuccessView.css';

const SuccessView = ({ userName = 'EMMANUEL' }) => {
  const navigate = useNavigate();

  const handleViewPlan = () => {
    navigate('/plan-details');
  };

  return (
    <div className="success-view">
      <div className="success-container">
        <div className="circular-container">
          <Check size={32} />
        </div>
        
        <h1>You just created your Build Wealth plan</h1>
        <p>Well done, {userName}</p>

        <button 
          className="view-plan-button"
          onClick={handleViewPlan}
        >
          View Plan
        </button>
      </div>
    </div>
  );
};

export default SuccessView;