import React from 'react';
import './PagesRightSide.css'
import Slider from "../../CustomSlider/Slider";

const theLink = "https://www.tinkoff.ru/invest/mgm/?masterData=94dd5554ad1cbce7aab6178c78e10ded3afe516a8bdbc489b3f0866fed2b319a69974f3acb5163bf763fb83dea2e65fa978bfd4d99676072159aa73c244724301ad8cf82e4a491e2a2d07cff9b81d03138a4810cea80c00ed0c14624ee32bcfa15988bab34dff479f0121834d28265765cdbe6e5f27de5140dd97254c89db1ffb467bbaf00c15f104189f818132b963b&utmSource=baf_invest_ios&utm_source=baf_invest_ios&utmMedium=personal&utm_medium=personal&short_link=AggG67x6NNd&httpMethod=GET"
const PagesRightSide = () => {
    const images = [
        {
            src: './LoginSignUp/tablet.svg',
            alt: 'Slide 1'
        },
        {
            src: './LoginSignUp/tablet.svg',
            alt: 'Slide 2'
        },
        {
            src: './LoginSignUp/tablet.svg',
            alt: 'Slide 3'
        },
    ];

    return (
        <div className='right-side-container hide-at-phone-size'>
            <div className='right-side-image1'>
                <img src={'./LoginSignUp/topFlower.svg'} alt='A beautiful.'/>
            </div>
            <div className='right-side-title-container'>
                <span className='right-side-title'>Учет доходности ваших инвестиций</span>
                <span style={{fontSize: 14, textAlign: 'center', marginTop: 10, fontWeight: 400}}>Учет инвестиций, анализ эффективности сделок и инструменты трейдеру. Интеграция c брокерскими счетами по API для <a
                    href={theLink} style={{color: '#727272'}}>Тинькофф Инвестиции</a>. </span>
            </div>
            <div style={{display: "flex", justifyContent: 'center'}}>
                <div className='right-side-image'>
                    <Slider images={images}/>
                </div>
            </div>
        </div>

    );
};

export default PagesRightSide;