import React from 'react';
// import './BankIcon.css';

const BankIcon = ({ 
  size = 35,
  className = '',
  color = 'currentColor'
}) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 35 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      className={`bank-icon ${className}`}
    >
      <path 
        d="M6.27006 14.9884L17.5363 8.33352L28.8025 14.9884C28.9129 15.0539 29.0348 15.0849 29.1548 15.0849C29.3924 15.0849 29.6238 14.963 29.7535 14.7438C29.9487 14.4137 29.8391 13.988 29.5085 13.7928L17.8895 6.92929C17.6716 6.80091 17.4013 6.80091 17.1835 6.92929L5.56402 13.7928C5.23392 13.988 5.12432 14.4137 5.31906 14.7438C5.51424 15.0739 5.93996 15.1835 6.27006 14.9884Z" 
        fill={color}
      />
      <path 
        d="M29.1558 27.0322H5.91691C5.53354 27.0322 5.22266 27.3431 5.22266 27.7265C5.22266 28.1099 5.53354 28.4207 5.91691 28.4207H29.1558C29.5392 28.4207 29.8501 28.1099 29.8501 27.7265C29.8501 27.3427 29.5392 27.0322 29.1558 27.0322Z" 
        fill={color}
      />
      <path 
        d="M12.2034 16.0584C12.5868 16.0584 12.8977 15.7476 12.8977 15.3642C12.8977 14.9808 12.5868 14.6699 12.2034 14.6699H9.87785C9.49448 14.6699 9.18359 14.9808 9.18359 15.3642C9.18359 15.7476 9.49448 16.0584 9.87785 16.0584H10.3464V24.4288H9.87785C9.49448 24.4288 9.18359 24.7397 9.18359 25.1231C9.18359 25.5065 9.49448 25.8174 9.87785 25.8174H12.2034C12.5868 25.8174 12.8977 25.5065 12.8977 25.1231C12.8977 24.7397 12.5868 24.4288 12.2034 24.4288H11.7349V16.0584H12.2034Z" 
        fill={color}
      />
      <path 
        d="M18.7034 16.0497C19.0868 16.0497 19.3977 15.7388 19.3977 15.3554C19.3977 14.972 19.0868 14.6611 18.7034 14.6611H16.3779C15.9945 14.6611 15.6836 14.972 15.6836 15.3554C15.6836 15.7388 15.9945 16.0497 16.3779 16.0497H16.8464V24.4201H16.3779C15.9945 24.4201 15.6836 24.7309 15.6836 25.1143C15.6836 25.4977 15.9945 25.8086 16.3779 25.8086H18.7034C19.0868 25.8086 19.3977 25.4977 19.3977 25.1143C19.3977 24.7309 19.0868 24.4201 18.7034 24.4201H18.2349V16.0497H18.7034Z" 
        fill={color}
      />
      <path 
        d="M25.1956 16.0399C25.579 16.0399 25.8898 15.729 25.8898 15.3456C25.8898 14.9623 25.579 14.6514 25.1956 14.6514H22.87C22.4867 14.6514 22.1758 14.9623 22.1758 15.3456C22.1758 15.729 22.4867 16.0399 22.87 16.0399H23.3386V24.4103H22.87C22.4867 24.4103 22.1758 24.7212 22.1758 25.1045C22.1758 25.4879 22.4867 25.7988 22.87 25.7988H25.1956C25.579 25.7988 25.8898 25.4879 25.8898 25.1045C25.8898 24.7212 25.579 24.4103 25.1956 24.4103H24.7271V16.0399H25.1956Z" 
        fill={color}
      />
    </svg>
  );
};


export default BankIcon;