import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddBankAccount.css';
import Header from '../PageHeader/Header';

const AddBankAccount = () => {
  const navigate = useNavigate();
  const [accountNumber, setAccountNumber] = useState('');


  const handleContinue = () => {
    if (accountNumber.length === 10) {
      navigate('select-bank')
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if ((/^\d*$/).test(value) && value.length <= 10) {
      setAccountNumber(value);
    }
  };

  return (
    <div className="add-bank-page">
      <div className="add-bank-container">
        <Header
        title = "Add a bank account"
        
        />

        <p className="warning-text">
          Please, only add a bank account that you own. Transactions to accounts that don't belong to you will be flagged
        </p>

        <div className="form-group">
          <div className="input-wrapper">
            <input
              id="accountNumber"
              type="text"
              value={accountNumber}
              onChange={handleInputChange}
              className={accountNumber ? 'has-value' : ''}
              placeholder=" "
            />
            <label htmlFor="accountNumber">Account Number</label>
          </div>
        </div>

        <button 
          className="continue-button"
          onClick={handleContinue}
          disabled={accountNumber.length !== 10}
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AddBankAccount;