import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FundWallet from '../FundWallet/FundWallet';
import BankAccountDetails from '../BankAccountDetails/BankAccountDetails';
import DebitCardFundingRoute from '../DebitCardFundingRoute/DebitCardFundingRoute';

const FundWalletRoute = () => {
  return (
    <Routes>
      <Route index element={<FundWallet />} />
      <Route path="bank-account" element={<BankAccountDetails />} />
      <Route path="card-payment/*" element={<DebitCardFundingRoute />} />
    </Routes>
  );
};

export default FundWalletRoute;