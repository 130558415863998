import axiosClient from "../axiosClient";

export async function fetchTinkoffAccounts() {
    const data = await axiosClient.get('/accounts');
    return data.map(account => ({ ...account }));
}

export function fetchCurrentAccountStatistics(currentAccountId) {
    return axiosClient.get(`/accounts/${currentAccountId}/statistics`);
}

export function fetchCurrentAccountAnalytics(accountId) {
    return axiosClient.get(`/accounts/${accountId}/analytics`);
}

export function fetchAssetsProfit(period,accountId) {
    return axiosClient.get(`/accounts/${accountId}/profit/${period}`);
}

export function fetchFinancialResultStatistics(accountId) {
    return axiosClient.get(`/accounts/${accountId}/statistics/financial-result`);
}

export function updateCheckedAccount(accountId, isChecked) {
    return axiosClient.put(`/accounts/${accountId}?isChecked=${isChecked}`);
}