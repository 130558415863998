import React from 'react';

function WhySection() {
  return (
   <section className="why-section container wide">
      <h2>Why Tradetrackr</h2>
      <ul>
        <div className="content-wrapper">
          <li>
            <img
              alt="Dollar Investment"
              src="https://risevest.com/_next/static/media/dollar.bbe009b2.webp"
              width="72"
              height="72"
              decoding="async"
              loading="eager"
            />
            <div style={{ paddingTop: '2rem' }}>
              <h3>Dollar denominated investments</h3>
              <p>
                Generations of Africans have worked hard and invested over years only to see their efforts at wealth creation hindered due to high inflation, low growth and currency devaluation. The richest among us avoid these and grow their wealth by investing globally in dollar denominated investments scattered across the world or in sophisticated assets at home. But every day people had no access to these kinds of investments, until now.
              </p>
            </div>
          </li>
          <li className="bg-secondary">
            <img
              alt="Verified Badge"
              src="https://risevest.com/_next/static/media/verified-badge.724753c0.webp"
              width="72"
              height="72"
              decoding="async"
              loading="eager"
            />
            <div style={{ paddingTop: '2rem' }}>
              <h3>Regulated investment adviser</h3>
              <p>
                Tradetrackr is a duly regulated investment adviser with 3rd party regulators that has made it possible for all Africans to invest at home or across the world in dollars and in their local currency. Expert managers research and discover the investments, build the portfolios into Tradetrackr and then provide regular updates to investors so that you can follow how your money is growing.
              </p>
            </div>
          </li>
        </div>
        <li className="bg-tertiary">
          <img
            alt="Investment Growth"
            src="https://risevest.com/_next/static/media/portfolio.b742b019.webp"
            width="72"
            height="72"
            decoding="async"
            loading="eager"
          />
          <div style={{ paddingTop: '2rem' }}>
            <h3 className="highlighted">8% to 15%</h3>
            <p>
              We offer investments across real estate, stocks, and fixed income, historically generating between 8% to 15% and more in returns for users. All users are welcome, and with a minimum of $10 you can start your investment journey with our expert support.
              <br /><br />
              Sign up on Tradetrackr, create and fund a plan, and let us help you create long-term wealth.
            </p>
          </div>
        </li>
      </ul>
    </section>

  );
}

export default WhySection;
