import React, { useEffect, useState } from 'react';
import { Card, Spin, Statistic } from 'antd';
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import { fetchPortFolio, fetchPortFolioForDates, fetchPortFolioForMonths, fetchPortFolioForWeeks } from '../../API/myportfolioService';
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';
import { store } from '../../redux/store';
import "./myPortfolio.css"

const formattedDay = dayjs().locale('ru').format('dddd');
const withSign = (number) => (number > 0 ? '+' : '') + number;

const MyPortfolioSection = () => {
    const [portfolioData, setPortfolioData] = useState({
        RUB: 0,
        EUR: 0,
        USD: 0
    });
    const [portfolioGrowthRate, setPortfolioGrowthRate] = useState(0);
    const [tpl, setTpl] = useState({
        forDate: { value: 0, commission: 0 },
        forWeek: { value: 0, commission: 0 },
        forMonth: { value: 0, commission: 0 }
    });
    const selectedAccount = currentTinkoffAccountSelector(store.getState()) || { id: "2175574538" };
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    const formattedMonth = formatMonth(currentDate);
    const formattedWeek = formatWeek(currentDate);
    const [selectedCurrency, setSelectedCurrency] = useState('RUB');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const portfolioData = await fetchPortFolio(selectedAccount.id);
                if (!portfolioData) return;
                const weeks = await fetchPortFolioForWeeks(selectedAccount.id, formattedWeek);
                const months = await fetchPortFolioForMonths(selectedAccount.id, formattedMonth);
                const dates = await fetchPortFolioForDates(selectedAccount.id, formattedDate);

                setPortfolioData({
                    RUB: portfolioData.RUB,
                    EUR: parseFloat(portfolioData.EUR).toFixed(2),
                    USD: parseFloat(Math.round(portfolioData.USD * 100) / 100)
                });

                setPortfolioGrowthRate(portfolioData.growthRate);

                setTpl({
                    forDate: {...dates},
                    forWeek: {...weeks},
                    forMonth: {...months}
                });
            } catch (error) {
                console.error('Error fetching portfolio data for account ID', selectedAccount.id, ':', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);


    const formatCurrency = (currency) => {
        switch (currency) {
            case 'RUB':
                return `${portfolioData.RUB.toLocaleString()} ₽`;
            case 'USD':
                return `$${portfolioData.USD.toLocaleString()}`;
            case 'EUR':
                return `€${portfolioData.EUR.toLocaleString()}`;
            default:
                return `${portfolioData.RUB.toLocaleString()}`;
        }
    };

    const todayInPercentage = ((tpl.forDate.value / portfolioData.RUB) * 100).toFixed(2);
    
    console.log("For date", tpl.forDate);
    console.log("For week", tpl.forWeek);
    console.log("For month", tpl.forMonth);
    

    const pnlForDateClass = `card card-h-no-suffix card-h-${tpl.forDate.value >= 0 ? 'green' : 'red'}`;
    const percentageForDateClass = `card card-h-no-suffix card-h-${todayInPercentage >= 0 ? 'green' : 'red'}`;
    const portfolioClass = `card card-h-portfolio card-h-${portfolioData.RUB >= 0 ? 'green' : 'red'} card-h-suffix-${portfolioGrowthRate >= 0    ? 'green' : 'red'}`;
    const pnlForWeekClass = `card card-h-with-suffix card-h-${tpl.forWeek.value >= 0 ? 'green' : 'red'} card-h-suffix-${tpl.forWeek.commission >= 0 ? 'green' : 'red'}`;
    const pnlForMonthClass = `card card-h-with-suffix card-h-${tpl.forMonth.value >= 0 ? 'green' : 'red'} card-h-suffix-${tpl.forMonth.commission >= 0 ? 'green' : 'red'}`;
    
    return (
        <div className='portfolio-section-container'>
            <div className='title-line'>
                <span className={'title-text'}>Мой портфель</span>
                <span className='title-line-text' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>подписка активна до 10 марта 2024 г</span>
            </div>
            {
                isLoading ? <Spinner /> : (
                    <div className='portfolio-section-row'>
                        <div>
                            <Card bordered={false} className={pnlForDateClass}>
                                <Statistic
                                    title={<>
                                        <span className='statistic-title-text'>Сегодня,</span>
                                        <span className='statistic-title-day'>{formattedDay}</span>
                                    </>}
                                    value={`${tpl.forDate.value.toLocaleString()} ₽`}
                                    precision={3}
                                    suffix={<span className='statistic-suffix'>{tpl.forDate.commission.toLocaleString() + ' ₽'}</span>}
                                />
                            </Card>
                        </div>
                        <div>
                            <Card bordered={false} className={percentageForDateClass}>
                                <Statistic
                                    title={<>
                                        <span className='statistic-title-text'>Сегодня</span>
                                        <span className='statistic-title-text2'>в процентах</span>
                                    </>}
                                    value={`${isNaN(todayInPercentage) ? 0 : withSign(todayInPercentage)} %`}
                                    precision={2}
                                />
                            </Card>
                        </div>
                        <div>
                            <Card bordered={false} className={portfolioClass}>
                                <Statistic
                                    title={<span className='statistic-title-text'>Стоимость портфеля</span>}
                                    value={formatCurrency(selectedCurrency)}
                                    precision={2}
                                    suffix={<span className='statistic-suffix'>{`${withSign(portfolioGrowthRate)} %`}</span>}
                                />
                                <div className='statistic-currencies'>
                                    <span onClick={() => setSelectedCurrency('RUB')} style={{ cursor: 'pointer', color: selectedCurrency === 'RUB' ? 'black' : 'grey' }}>RUB</span>
                                    <span onClick={() => setSelectedCurrency('USD')} className='usd-currency' style={{ cursor: 'pointer', color: selectedCurrency === 'USD' ? 'black' : 'grey' }}>USD</span>
                                    <span onClick={() => setSelectedCurrency('EUR')} className='eur-currency' style={{ cursor: 'pointer', color: selectedCurrency === 'EUR' ? 'black' : 'grey' }}>EUR</span>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Card bordered={false} className={pnlForWeekClass}>
                                <Statistic
                                    title={<span className='statistic-title-text'>На этой неделе</span>}
                                    value={`${tpl.forWeek.value.toLocaleString()} ₽`}
                                    precision={3}
                                    suffix={<span className='statistic-suffix'>{tpl.forWeek.commission.toLocaleString() + ' ₽'}</span>}
                                />
                            </Card>
                        </div>
                        <div>
                            <Card bordered={false} className={pnlForMonthClass}>
                                <Statistic
                                    title={<span className='statistic-title-text'>В этом месяце</span>}
                                    value={`${tpl.forMonth.value.toLocaleString()} ₽`}
                                    precision={3}
                                    suffix={<span className='statistic-suffix'>{tpl.forMonth.commission.toLocaleString() + ' ₽'}</span>}
                                />
                            </Card>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default MyPortfolioSection;

function Spinner() {
    return (
        <div className='flex-center' style={{ minHeight: 120, width: "100%", height: "100%" }}>
            <Spin style={{ fontSize: 24 }} spin />
        </div>
    );
}

function formatDate(date) {
    return dayjs(date).format("YYYY-MM-DD");
}


function formatMonth(date) {
    return dayjs(date).format("YYYY-M");
}


function formatWeek(date) {
    const day = dayjs(date).day();
    if (day === 0) {
        return dayjs(date).subtract(1, 'day').format("YYYY-ww");
    }
    return dayjs(date).format("YYYY-ww");
}



