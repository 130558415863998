import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { CalendarDays } from 'lucide-react';
import './PerformanceChart.css';

const data = [
  { date: '2024-09-13', balance: 76.63 },
  { date: '2024-09-23', balance: 76.63 },
];

const PerformanceChart = () => {
  const [activeTab, setActiveTab] = useState('1 month');

  const tabs = ['1 month', '3M', '6M', 'All'];

  return (
    <div className="performance-chart">
      <div className='wrap-performance-header'>
      <div className="performance-header">
        <p className="title">Performance</p>
        <p className="amount">$ 76.63</p>
        <p className="date">
          <CalendarDays size={16} />
          <span>September 23, 2024</span>
        </p>
      </div>
      
      <div className="performance-details">
        <div className="detail-item">
          <span>YTD Investments • $76.63</span>
        </div>
        <div className="detail-item">
          <span>YTD Returns • $0.00</span>
        </div>
      </div>
      </div>

      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#41BCC4" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#41BCC4" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#41BCC4" opacity={0.3} />
            <XAxis dataKey="date" hide={true} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `$${value.toFixed(2)}`}
              domain={['dataMin - 1', 'dataMax + 1']}
              ticks={[75.60, 75.80, 76.00, 76.20, 76.40, 76.60, 76.80, 77.00, 77.20, 77.40, 77.60, 77.80]}
            />
            <Area type="monotone" dataKey="balance" stroke="#41BCC4" fillOpacity={1} fill="url(#colorBalance)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="chart-tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PerformanceChart;