import React, { useState } from 'react';
import './Withdraw.css';
import WithdrawModal from '../WithdrawModal/WithdrawModal';
import { BackButton } from '../Buttons';
import BankIcon from '../Icons/BankIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import Header from '../PageHeader/Header';

const Withdraw = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleWithdrawClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="withdraw">
       <Header
        title="Withdraw"
        
      />

      <div className="withdraw__content">
        <div className="withdraw__option">
          <div className="withdraw__option-info">
            <span className="circular-container">
              <BankIcon />
            </span>
            <div className="withdraw__option-text">
              <span className="withdraw__option-title">Withdraw to Bank Account</span>
              <span className="withdraw__option-subtitle">This costs ₦50</span>
            </div>
          </div>
          <div className="withdraw__option-arrow">
            <ChevronRightIcon />
          </div>
          <button className="withdraw__option-button" onClick={handleWithdrawClick}>
            <span className="sr-only">Withdraw</span>
          </button>
        </div>
      </div>

      {isModalOpen && (
        <WithdrawModal onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default Withdraw;