import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProjectionView.css';
import { GoalSummary, Legend, ProjectionChart } from './ProjectionChart';
import { calculateProjection, formatCurrency } from './utils/Chartutils';
import { BackButton } from '../Buttons';
import InfoIcon from '../Icons/InfoIcon';
import Header from '../PageHeader/Header';

const ProjectionView = () => {
  const navigate = useNavigate();
  
  const [goal] = useState({
    amount: 0.01,
    targetDate: new Date('2029-10-29'),
    monthlyInvestment: 0,
  });

  const [chartData] = useState({
    investment: calculateProjection(0.01, 0, 0.05, 5),
    returns: calculateProjection(0, 0, 0.08, 5),
  });

  const handleContinue = () => {
    navigate('portfolio-editor');
  };

  return (
    <div className="projection-view">
      <Header
        title="Projection"
        
      />

      <GoalSummary 
        amount={goal.amount} 
        targetDate={goal.targetDate} 
      />

      <Legend 
        investment={goal.amount} 
        returns={0.01} 
      />

      <ProjectionChart data={chartData} />

      <div className="monthly-investment">
        <p>
          <span>Estimated Monthly Investment</span>
          <span>{formatCurrency(goal.monthlyInvestment)}</span>
        </p>
      </div>

      <div className="disclaimer">
        <InfoIcon />
        <span>
          Returns not guaranteed. Investing involves risk.
          <a 
            href="https://risevest.com/disclosure" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Read our Disclosures
          </a>
        </span>
      </div>

      <p className="settings-note">
        These are your starting settings, they can always be updated.
      </p>

      <button 
        className="continue-button"
        onClick={handleContinue}
      >
        Agree & Continue
      </button>
    </div>
  );
};

export default ProjectionView;