import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ConfirmAmount.css';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const ConfirmAmount = () => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate('confirmed');
  };

  return (
    <form className="confirm-amount">
      <Header
            title="Confirm Amount"
            
        />

      <div className="confirm-amount__content">
        <p className="total-amount">
          <span className="currency">₦</span> 1,713.65
        </p>

        <div className="amount-details">
          <div className="amount-details__item">
            <p>Amount added to wallet</p>
            <p>₦ 1,690</p>
          </div>
          <div className="amount-details__item">
            <p>Processing fee</p>
            <p>₦ 23.65</p>
          </div>
          <div className="amount-details__item">
            <p>Amount in USD</p>
            <p>$1</p>
          </div>
        </div>

        <button 
          className="confirm-button"
          type="button"
          onClick={handleConfirm}
        >
          Add&nbsp;<span>₦ 1,713.65</span>
        </button>
      </div>
    </form>
  );
};

export default ConfirmAmount;