import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PlanNameForm.css';
import ErrorIcon from '../Icons/ErrorIcon';
import Header from '../PageHeader/Header';

const PlanNameForm = () => {
    const navigate = useNavigate();
    const [planName, setPlanName] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!planName.trim()) {
            setError('Please enter a plan name');
            return;
        }

        setError('');

        try {
            navigate('income-form');
        } catch (err) {
            setError('Something went wrong. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        setPlanName(e.target.value);
        
        if (error) setError('');
    };

    return (
        <div className="plan-name-container">
            <div className="form-wrapper">
                <div className="form-content">
                    <form className="plan-form" onSubmit={handleSubmit}>
                        <div className="form-inner">
                            <Header
                                title="Name your plan"
                                
                            />

                            <div className="progress-section">
                                <p className="progress-text">Question 1 of 1</p>
                                <div className="progress-bar-container">
                                    <div 
                                        className="progress-bar-fill"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div className="input-section">
                                <p>Give this plan a name</p>
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        id="name"
                                        value={planName}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                        aria-label="Plan name"
                                        placeholder="Enter plan name"
                                        data-test-id="plan-name-input"
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={`submit-button ${!planName.trim() ? 'disabled' : ''}`}
                                data-test-id="create-plan-button"
                                disabled={!planName.trim()}
                            >
                                Continue
                            </button>

                            {error && (
                                <div 
                                    className="error-message"
                                    role="alert"
                                    data-test-id="create-plan-error-message"
                                >
                                    <ErrorIcon />
                                    <span>{error}</span>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PlanNameForm;