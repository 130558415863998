import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RetirementAgeForm.css';
import Header from '../PageHeader/Header';
import RemoveIcon from '../Icons/RemoveIcon';
import AddIcon from '../Icons/AddIcon';
import InfoIcon from '../Icons/InfoIcon';

const RetirementAgeForm = () => {
  const [retirementAge, setRetirementAge] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const currentAge = 30;
  const progressPercentage = 80;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateAge()) {
      navigate('preferences-form');
    }
  };

  const validateAge = () => {
    const age = parseInt(retirementAge);
    if (!age) {
      setError('Please enter a retirement age');
      return false;
    }
    if (age <= currentAge) {
      setError('Retirement age must be greater than your current age');
      return false;
    }
    if (age > 100) {
      setError('Please enter a realistic retirement age');
      return false;
    }
    setError('');
    return true;
  };

  const handleIncrement = () => {
    const current = parseInt(retirementAge) || 65;
    if (current < 100) {
      setRetirementAge((current + 1).toString());
      setError('');
    }
  };

  const handleDecrement = () => {
    const current = parseInt(retirementAge) || 65;
    if (current > currentAge) {
      setRetirementAge((current - 1).toString());
      setError('');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setRetirementAge(value);
    if (value) {
      validateAge();
    } else {
      setError('');
    }
  };

  const isDecrementDisabled = !retirementAge || parseInt(retirementAge) <= currentAge;
  const isIncrementDisabled = parseInt(retirementAge) >= 100;
  const isSubmitDisabled = !retirementAge || !!error;

  return (
    <div className="retirement-form">
      <form onSubmit={handleSubmit}>
        <div className="retirement-form__content">
          <Header
            title="Retirement Age"
            
          />

          <div className="retirement-form__progress">
            <p className="retirement-form__progress-text">Question 4 of 5</p>
            <div className="retirement-form__progress-bar">
              <div 
                className="retirement-form__progress-fill"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>

          <p className="retirement-form__question">
            At what age would you love to retire?
          </p>

          <div className="retirement-form__input-group">
            <button
              type="button"
              className={`retirement-form__button ${isDecrementDisabled ? 'disabled' : ''}`}
              onClick={handleDecrement}
              disabled={isDecrementDisabled}
            >
              <RemoveIcon />
            </button>

            <div className="retirement-form__input-wrapper">
              <input
                type="text"
                inputMode="decimal"
                placeholder="65"
                value={retirementAge}
                onChange={handleInputChange}
                className="retirement-form__input"
                autoComplete="off"
              />
            </div>

            <button
              type="button"
              className={`retirement-form__button ${isIncrementDisabled ? 'disabled' : ''}`}
              onClick={handleIncrement}
              disabled={isIncrementDisabled}
            >
              <AddIcon />
            </button>
          </div>

          <p className="retirement-form__helper-text">
            Your retirement age should be greater than your current age ({currentAge})
          </p>

          {error && (
            <div className="retirement-form__error" role="alert">
              <InfoIcon />
              <span>{error}</span>
            </div>
          )}

          <button
            type="submit"
            className={`retirement-form__submit-button ${isSubmitDisabled ? 'disabled' : ''}`}
            disabled={isSubmitDisabled}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default RetirementAgeForm;