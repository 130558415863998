import React from 'react';
import './portfolio.css';
import AssetMix from '../../API/AssetMix/AssetMix';
import AssetMixChart from '../AssetMixChart/AssetMixChart';
import AssetBreakdownList from '../AssetsBreakDownList/AssetBreakDownList';
import { useNavigate } from 'react-router-dom';  
import ChevronRightIcon from '../Icons/ChevronRightIcon';

const PortfolioComponent = () => {

  const navigate = useNavigate();

  const handlePortfolioClick = () => {
    navigate('/trackr-home/plans/rise-portfolio');
  };

  return (
    <div className="portfolio">
      <div className="header">
        <h2>Your portfolio</h2>
         <div onClick={handlePortfolioClick} className='see-our-portfolio' >
            <p style={{ marginRight: '4px' }}>See our portfolio</p>
            <ChevronRightIcon />
         </div>

      </div>
      <div className="stats">
        <div className="stat-card">
          <p>Net worth</p>
          <h2>$0.00</h2>
        </div>
        <div className="stat-card">
          <p>Asset classes</p>
          <h2>0</h2>
        </div>
      </div>
      <AssetMixChart />
      <AssetBreakdownList />
    </div>
  );
};

export default PortfolioComponent;