import React from 'react';
import './MaturedPlans.css';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const MaturedPlans = () => {
  const plans = [
    {
      id: 'bfee8c94-e103-432e-b94f-da490b41e26f',
      name: 'Drip',
      type: 'Real Estate',
      closedDate: '27 Jan, 2022',
      amount: 117.49,
      imageUrl: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg'
    },
    {
      id: '67d6c387-a0ed-4f61-aae3-bf873447e8f4',
      name: 'Starting Again',
      type: 'Real Estate',
      closedDate: '06 Apr, 2024',
      amount: 220.96,
      imageUrl: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg'
    },
    {
      id: 'ef5c839b-3064-4b17-9568-0fd0b5733061',
      name: 'Testing season',
      type: 'Fixed Income',
      closedDate: '25 Nov, 2021',
      amount: 144.64,
      imageUrl: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg'
    },
    {
      id: '42128c0b-d46f-41d2-9c37-f27e6132c9f5',
      name: 'Rema Wedding',
      type: 'Wedding',
      closedDate: '06 Sep, 2024',
      amount: 101.08,
      imageUrl: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304572/Plan%20Covers/Untitled_x6iipw.jpg'
    }
  ];

  return (
    <div className="grid">
      <div className="grid-content">
        <div className="content-wrapper">
          <Header
            title="Matured Plans"
            
          />

          <div>
            <p className="description">
              This is a list of all your plans that <br /> has reached maturity
            </p>
            <ul className="plans-list">
              {plans.map((plan) => (
                <li key={plan.id}>
                  <a href={`/plans/${plan.id}`} className="plan-item">
                    <div className="plan-image">
                      <img src={plan.imageUrl} alt={plan.name} />
                    </div>
                    <div className="plan-details">
                      <h3>{plan.name}</h3>
                      <span>{`${plan.type} • Closed ${plan.closedDate}`}</span>
                    </div>
                    <span className="plan-amount">
                      ${plan.amount.toFixed(2)}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="loader" aria-hidden="true" data-test-id="loader" />
        </div>
      </div>
    </div>
  );
};

export default MaturedPlans;