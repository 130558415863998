import React from 'react';
import Hero from './Hero';
import WhySection from './WhySection';
import './index.css';
import StandardLayout from '../../Component/StandardLayout';


export default function WhyTradeTrackr() {
  return (
    <StandardLayout>
    <div className="whysection">
      <Hero />
      <WhySection />
    </div>
    </StandardLayout>
  );
}