import React from 'react';

const StocksIcon = ({ className }) => (
  <svg 
    width="1em" 
    height="1em" 
    viewBox="0 0 11 14" 
    fill="currentColor"
    className={className}
  >
    <path d="M2.896 8.098l-1.178 4.594c-.124.485.491.816.846.456l7.996-8.124c.305-.31.08-.825-.36-.825H7.226L8.34.941A.49.49 0 007.866.3H3.2a.498.498 0 00-.48.353l-2 6.824a.49.49 0 00.48.621h1.696zM6.06 4.532a.49.49 0 00.474.642h2.494L3.144 11.15l.878-3.42a.49.49 0 00-.485-.606H1.863l1.714-5.85h3.596L6.059 4.533z" />
  </svg>
);

export default StocksIcon;