export default function SlantArrowUp({ size = 24, color = 'currentColor', ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"
        fill={color}
      />
    </svg>
  );
}