import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SavingsForm.css';
import { BackButton } from '../Buttons';
import RemoveIcon from '../Icons/RemoveIcon';
import AddIcon from '../Icons/AddIcon';
import Header from '../PageHeader/Header';

const SavingsForm = () => {
  const [savingsPercentage, setSavingsPercentage] = useState('');
  const navigate = useNavigate();
  
  const monthlyIncome = 2.00;

  const calculateSavingsAmount = () => {
    const percentage = parseFloat(savingsPercentage) || 0;
    return ((percentage / 100) * monthlyIncome).toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (savingsPercentage) {
      navigate('retirement-form');
    }
  };

  const handleIncrement = () => {
    const current = parseFloat(savingsPercentage) || 0;
    if (current < 100) {
      setSavingsPercentage((current + 1).toString());
    }
  };

  const handleDecrement = () => {
    const current = parseFloat(savingsPercentage) || 0;
    if (current > 0) {
      setSavingsPercentage((current - 1).toString());
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 100)) {
      setSavingsPercentage(value);
    }
  };

  const isDecrementDisabled = !savingsPercentage || parseFloat(savingsPercentage) <= 0;
  const isIncrementDisabled = parseFloat(savingsPercentage) >= 100;
  const progressPercentage = 60;

  return (
    <div className="savings-form">
      <form onSubmit={handleSubmit}>
        <div className="savings-form__content">
          <Header
            title="Savings"
            
          />

          <div className="savings-form__progress">
            <p className="savings-form__progress-text">Question 3 of 5</p>
            <div className="savings-form__progress-bar">
              <div 
                className="savings-form__progress-fill"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>

          <div className="savings-form__questions">
            <p className="savings-form__main-question">
              What percentage of that can you afford to invest each month?
            </p>
            <p className="savings-form__sub-question">
              You can always change this later.
            </p>
          </div>

          <div className="savings-form__input-group">
            <button
              type="button"
              className={`savings-form__button ${isDecrementDisabled ? 'disabled' : ''}`}
              onClick={handleDecrement}
              disabled={isDecrementDisabled}
            >
              <RemoveIcon />
            </button>

            <div className="savings-form__input-wrapper">
              <input
                type="text"
                inputMode="decimal"
                placeholder="0"
                value={savingsPercentage}
                onChange={handleInputChange}
                className="savings-form__input"
              />
              <span className="savings-form__input-suffix">%</span>
            </div>

            <button
              type="button"
              className={`savings-form__button ${isIncrementDisabled ? 'disabled' : ''}`}
              onClick={handleIncrement}
              disabled={isIncrementDisabled}
            >
              <AddIcon />
            </button>
          </div>

          <p className="savings-form__calculation">
            ₦{calculateSavingsAmount()} <span>of</span> ₦{monthlyIncome.toFixed(2)} <span>per month</span>
          </p>

          <button
            type="submit"
            className={`savings-form__submit-button ${!savingsPercentage ? 'disabled' : ''}`}
            disabled={!savingsPercentage}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default SavingsForm;