import React from 'react';

const CancelButton = ({ onClick, text = 'No, Cancel' }) => {
    return (
        <button 
            type="button"
            className="cancel-button"
            data-test-id="cancel"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default  CancelButton;