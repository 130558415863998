import React from 'react';
import './SeeOurPortfolio.css';
import { BackButton } from '../Buttons';
import FixedIncomeIcon from '../Icons/FixedIncomeIcon';
import RealEstateIcon from '../Icons/RealEstateIcon';
import StocksIcon from '../Icons/StocksIcon';
import Header from '../PageHeader/Header';

const AssetClass = ({ color, icon, name, percentage }) => (
  <a className="asset-class" href={`/plans/rise-portfolio/${name.toLowerCase().replace(' ', '-')}`}>
    <div className="asset-icon" style={{ backgroundColor: `${color}10`, color }}>
      {icon}
    </div>
    <div>
      <p>{name}</p>
      <div className="percentage-bar">
        <div className="bar">
          <div className="fill" style={{ width: `${percentage}%`, backgroundColor: color }}></div>
        </div>
        <p className="percentage" style={{ color }}>{percentage}%</p>
      </div>
    </div>
  </a>
);

const SeeOurPortfolio = () => {
  return (
    <div className="rise-portfolio">
       <Header
        title="Rise's Portfolio"
        
      />

      <div className="content">
        <div className="summary">
          <div className="summary-item">
            <p>Year to date returns</p>
            <h2 className="success">4.94%</h2>
          </div>
          <div className="summary-item">
            <p>Asset classes</p>
            <h2>3</h2>
          </div>
        </div>

        <div className="historical-performance">
          <h3>Historical Performance</h3>
          <div className="performance-grid">
            {[2023, 2022, 2021, 2020, 2019].map(year => (
              <div key={year} className="performance-item">
                <p>{year}</p>
                <h2 className="success">
                  {year === 2023 ? '15.34%' :
                   year === 2022 ? '0.69%' :
                   year === 2021 ? '11.42%' :
                   year === 2020 ? '22.67%' :
                   '15.08%'}
                </h2>
              </div>
            ))}
          </div>
          <p className="disclaimer">Past performance is not a guarantee of future returns.</p>
        </div>

        <div className="asset-mix">
          <h2>Our asset mix</h2>
          <div className="asset-list">
            <AssetClass
              color="rgb(188, 16, 88)"
              icon={<FixedIncomeIcon />}
              name="Fixed Income"
              percentage={25.28}
            />
            <AssetClass
              color="rgb(8, 152, 160)"
              icon={<RealEstateIcon />}
              name="Real Estate"
              percentage={66.98}
            />
            <AssetClass
              color="rgb(134, 109, 197)"
              icon={<StocksIcon />}
              name="Stocks"
              percentage={7.74}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeOurPortfolio;