import {Layout} from "antd";
import StatisticsPortfolioSection from "../Component/StatisticsPorfolio/StatisticsPortfolioSection";
import FinancialResultSection from "../Component/FinancialResultSection/FinancialResultSection";
import DetailsSection from "../Component/DetailsSection/DetailsSection";



function Statistics() {
    return (
        <Layout size={10} align='start' direction="vertical" style={{width: '98%', padding: '1px 16px 16px 16px',backgroundColor:'white',marginInline:10}}>
            <StatisticsPortfolioSection/>
            <FinancialResultSection/>
            <DetailsSection/>
        </Layout>
    );
}

export default Statistics;