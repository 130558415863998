import React from 'react';
import { Progress, Typography, Space } from 'antd';
import { FireFilled, HomeOutlined, StockOutlined, BankOutlined, DollarCircleOutlined } from '@ant-design/icons';
import './AssetMix.css';

const { Title, Text } = Typography;

const AssetMix = ({ assets }) => {
  const total = assets.reduce((sum, asset) => sum + asset.value, 0);

  const getIcon = (name) => {
    switch (name) {
      case 'Real Estate': return <HomeOutlined style={{ color: '#20c997' }} />;
      case 'Stocks': return <StockOutlined style={{ color: '#845ef7' }} />;
      case 'Fixed Income': return <DollarCircleOutlined style={{ color: '#ff6b6b' }} />;
      default: return null;
    }
  };

  return (
    <div className="asset-mix">
      <Title level={4}>Your asset mix</Title>
      <div className="chart">
        <Progress
          type="circle"
          percent={100}
          width={200}
          success={{ percent: 0 }}
          format={() => <FireFilled style={{ fontSize: 24, color: '#ff4d4f' }} />}
          strokeColor={{
            '0%': '#845ef7',
            '62.11%': '#845ef7',
            '62.12%': '#20c997',
            '100%': '#20c997',
          }}
          strokeWidth={16}
          trailColor="#f0f0f0"
        />
      </div>
        <Space direction="vertical" size="large" className="legend">
        {assets.map(asset => {
          const percentage = (asset.value / total * 100).toFixed(2);
          return (
            <div key={asset.name} className="legend-item">
              <div className="icon">{getIcon(asset.name)}</div>
              <Text className="name">{asset.name}</Text>
              <Progress 
                percent={parseFloat(percentage)} 
                size="small"
                status="active"
                strokeColor={asset.color}
                showInfo={false}
                className="progress-bar"
              />
              <Text className="percentage" style={{ color: asset.color }}>
                {percentage}%
              </Text>
              <Text strong className="value">${asset.value.toFixed(2)}</Text>
            </div>
          );
        })}
      </Space>
    </div>
  );
};

export default AssetMix;