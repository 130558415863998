import React from 'react';

const CallReceivedIcon = ({ className }) => (
  <svg 
    focusable="false" 
    aria-hidden="true" 
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M20 5.41 18.59 4 7 15.59V9H5v10h10v-2H8.41z" />
  </svg>
);

export default CallReceivedIcon;