export default function MeetSomeSection() {
    return (
        <section className="meet-some container wide">
            <h2 className="meet-some__title">
                Meet Some <span>Risers</span>
            </h2>
            <div>
                <div className="carousel-large">
                    <SmallCard name={"Sanni"} imgUrl={"https://res.cloudinary.com/dsjxfieim/image/upload/v1698246625/Risevest_Henry.00_01_11_04.Still002_qzzgdj.jpg"}
                        ball={<img
                            alt=""
                            src="https://risevest.com/_next/static/media/teal-bubble.9ea7b0dc.png"
                            width={75}
                            height={150}
                            decoding="async"
                            data-nimg="future"
                            className="small-card__ball-1"
                            loading="lazy"
                            style={{ color: "transparent" }}
                        />}
                    />
                    <SmallCard name={"Layo"} imgUrl={"https://res.cloudinary.com/dsjxfieim/image/upload/v1698246625/Risevest_Henry.00_01_35_15.Still004_rlzbzx.jpg"}
                        ball={<img
                            alt=""
                            src="https://risevest.com/_next/static/media/pink-bubble.7a7c0111.png"
                            width={75}
                            height={74}
                            decoding="async"
                            data-nimg="future"
                            className="small-card__ball-2"
                            loading="lazy"
                            style={{ color: "transparent" }}
                        />}
                    />
                    <SmallCard name={"Henry"} imgUrl={"https://res.cloudinary.com/dsjxfieim/image/upload/v1698246625/Risevest_Henry.00_01_11_04.Still002_qzzgdj.jpg"}
                        ball={<img
                            alt=""
                            src="https://risevest.com/_next/static/media/purple-bubble.bf3cbdb8.png"
                            width={192}
                            height={96}
                            decoding="async"
                            data-nimg="future"
                            className="small-card__ball-3"
                            loading="lazy"
                            style={{ color: "transparent" }}
                        />}
                    />
                </div>
                <div className="carousel-small">
                    <div className="carousel-item">
                        <div style={{ opacity: 1, transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)" }}>
                            <div
                                style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%) center center / cover no-repeat, url("https://res.cloudinary.com/dsjxfieim/image/upload/v1698246625/Risevest_Henry.00_01_11_04.Still002_qzzgdj.jpg"), lightgray' }}
                                className="carousel-small__content"
                            >
                                <button className="play-button">
                                    <svg
                                        width={32}
                                        height={32}
                                        viewBox="0 0 32 32"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M28.79 14.7374L10.78 3.72366C10.5525 3.5825 10.2911 3.50533 10.0233 3.50027C9.75562 3.49521 9.49149 3.56245 9.25877 3.69491C9.02775 3.82187 8.8353 4.00887 8.70175 4.23614C8.5682 4.46342 8.4985 4.72255 8.50002 4.98616V27.0137C8.4985 27.2773 8.5682 27.5364 8.70175 27.7637C8.8353 27.991 9.02775 28.1779 9.25877 28.3049C9.49149 28.4374 9.75562 28.5046 10.0233 28.4996C10.2911 28.4945 10.5525 28.4173 10.78 28.2762L28.79 17.2624C29.0068 17.1306 29.186 16.9452 29.3103 16.7241C29.4347 16.503 29.5 16.2536 29.5 15.9999C29.5 15.7462 29.4347 15.4968 29.3103 15.2757C29.186 15.0546 29.0068 14.8692 28.79 14.7374ZM28.2675 16.4087L10.2575 27.4237C10.1813 27.4705 10.0938 27.496 10.0043 27.4976C9.91486 27.4991 9.8266 27.4766 9.74877 27.4324C9.67287 27.3921 9.60946 27.3317 9.56539 27.2579C9.52133 27.1841 9.49829 27.0996 9.49877 27.0137V4.98616C9.49829 4.9002 9.52133 4.81574 9.56539 4.74193C9.60946 4.66812 9.67287 4.60777 9.74877 4.56741C9.8266 4.5232 9.91486 4.50069 10.0043 4.50223C10.0938 4.50377 10.1813 4.5293 10.2575 4.57616L28.2675 15.5912C28.3385 15.6332 28.3973 15.693 28.4382 15.7647C28.4791 15.8363 28.5006 15.9174 28.5006 15.9999C28.5006 16.0824 28.4791 16.1635 28.4382 16.2352C28.3973 16.3068 28.3385 16.3666 28.2675 16.4087Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                                <p className="card-name">
                                    Sanni
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="indicators">
                        <div className="indicators__selecters">

                            <button
                                className="indicators__selecter indicators__selecter--active"
                                tabIndex={0}
                                type="button"
                                aria-label="carousel indicator 1"
                            >
                                <span>
                                    <svg
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <circle cx={12} cy={12} r={8} />
                                    </svg>
                                </span>
                            </button>
                            <button
                                className="indicators__selecter"
                                tabIndex={0}
                                type="button"
                                aria-label="carousel indicator 2"
                            >
                                <span>
                                    <svg
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <circle cx={12} cy={12} r={8} />
                                    </svg>
                                </span>
                            </button>
                            <button
                                className="indicators__selecter"
                                tabIndex={0}
                                type="button"
                                aria-label="carousel indicator 3"
                            >
                                <span>
                                    <svg
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <circle cx={12} cy={12} r={8} />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        {/* <div className="indicators__arrows">
                            <button
                                className="MuiButtonBase-root MuiIconButton-root jss8  MuiIconButton-sizeSmall"
                                tabIndex={0}
                                type="button"
                                aria-label="previous slide"
                            >
                                <span className="MuiIconButton-label">
                                    <svg
                                        className="MuiSvgIcon-root jss9"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                    </svg>
                                </span>
                                <span className="MuiTouchRipple-root" />
                            </button>
                            <button
                                className="MuiButtonBase-root MuiIconButton-root jss8  MuiIconButton-sizeSmall"
                                tabIndex={0}
                                type="button"
                                aria-label="next slide"
                            >
                                <span className="MuiIconButton-label">
                                    <svg
                                        className="MuiSvgIcon-root jss9"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                    </svg>
                                </span>
                                <span className="MuiTouchRipple-root" />
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

    )
}

function SmallCard({ imgUrl, ball, name }) {
    return (
        <button
            className="small-card flex-center"
            style={{
                background:
                    `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${imgUrl}), lightgray 50% / contain no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}
        >
            {ball}
            <span className="play-button">
                <svg
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M28.79 14.7374L10.78 3.72366C10.5525 3.5825 10.2911 3.50533 10.0233 3.50027C9.75562 3.49521 9.49149 3.56245 9.25877 3.69491C9.02775 3.82187 8.8353 4.00887 8.70175 4.23614C8.5682 4.46342 8.4985 4.72255 8.50002 4.98616V27.0137C8.4985 27.2773 8.5682 27.5364 8.70175 27.7637C8.8353 27.991 9.02775 28.1779 9.25877 28.3049C9.49149 28.4374 9.75562 28.5046 10.0233 28.4996C10.2911 28.4945 10.5525 28.4173 10.78 28.2762L28.79 17.2624C29.0068 17.1306 29.186 16.9452 29.3103 16.7241C29.4347 16.503 29.5 16.2536 29.5 15.9999C29.5 15.7462 29.4347 15.4968 29.3103 15.2757C29.186 15.0546 29.0068 14.8692 28.79 14.7374ZM28.2675 16.4087L10.2575 27.4237C10.1813 27.4705 10.0938 27.496 10.0043 27.4976C9.91486 27.4991 9.8266 27.4766 9.74877 27.4324C9.67287 27.3921 9.60946 27.3317 9.56539 27.2579C9.52133 27.1841 9.49829 27.0996 9.49877 27.0137V4.98616C9.49829 4.9002 9.52133 4.81574 9.56539 4.74193C9.60946 4.66812 9.67287 4.60777 9.74877 4.56741C9.8266 4.5232 9.91486 4.50069 10.0043 4.50223C10.0938 4.50377 10.1813 4.5293 10.2575 4.57616L28.2675 15.5912C28.3385 15.6332 28.3973 15.693 28.4382 15.7647C28.4791 15.8363 28.5006 15.9174 28.5006 15.9999C28.5006 16.0824 28.4791 16.1635 28.4382 16.2352C28.3973 16.3068 28.3385 16.3666 28.2675 16.4087Z"
                        fill="white"
                    />
                </svg>
            </span>
            <p className="card-name">
                {name}
            </p>
        </button>
    )
}
