import axiosClient from '../axiosClient';

export async function fetchPortFolio(accountId) {
  try {
    const response = await axiosClient.get(`/accounts/${accountId}/portfolio-value`);
    return response;
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
}

export async function fetchPortFolioForWeeks(accountId, week) {
  try {
    const response = await axiosClient.get(`/accounts/${accountId}/tpl/for-weeks?weeks=${week}`);
    return response[week];
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
}

export async function fetchPortFolioForMonths(accountId, month) {
  try {
    const response = await axiosClient.get(`/accounts/${accountId}/tpl/for-months?months=${month}`);
    return response[month];
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
}

export async function fetchPortFolioForDates(accountId, date) {
  try {
    const response = await axiosClient.get(`/accounts/${accountId}/tpl/for-dates?dates=${date}`);
    return response[date];
  } catch (error) {
    console.error('Error fetching portfolio:', error);
    throw error;
  }
}

export function fetchPortFolioStocks(accountId) {
    return axiosClient.get(`/accounts/${accountId}/stocks`);
}

export function fetchPortFolioCurrencies(accountId) {
    return axiosClient.get(`/accounts/${accountId}/currencies`);
}

