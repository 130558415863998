import React from 'react';
import { Card, Typography } from 'antd';
import './card.css';

const { Text, Title } = Typography;

const CustomCard = ({ planName, planBalance, planType, backgroundImage }) => {
  const cardStyle = {
    backgroundImage: `url('${backgroundImage}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Card className="plan-card existing-plan" style={cardStyle}>
      <div className="plan-content">
        <Text className="plan-name">{planName}</Text>
        <Title level={3} className="plan-balance">{planBalance}</Title>
        <Text className="plan-type">{planType}</Text>
      </div>
    </Card>
  );
};

export default CustomCard;