import { DownloadAppButton, StartInvestButton } from "../Buttons";

const socials = [
  {
    name: "LinkedIn",
    url: "/home",
    icon: <LinkedInIcon />
  },
  {
    name: "Twitter",
    url: "/home",
    icon: <TwitterIcon />
  },
  {
    name: "Instagram",
    url: "/home",
    icon: <InstagramIcon />
  },
  {
    name: "Facebook",
    url: "/home",
    icon: <FacebookIcon />
  },
  {
    name: "Youtube",
    url: "/home",
    icon: <YoutubeIcon />
  }
];

const links = [
  {
    title: "Company",
    items: [
      { name: "About Us", url: "/about-us" },
      { name: "Careers", url: "/jobs" },
      { name: "FAQs", url: "/faq" }
    ]
  },
  {
    title: "Explore",
    items: [
      { name: "Why TradeTrackr", url: "/why-tradetrackr" },
      { name: "TradeTrackr for Business", url: "/tradetrackr-for-business" },
      { name: "Investment club", url: "/investment-club" },
      { name: "Developer", url: "/developers" },
      { name: "Blog", url: "/blog" }
    ]
  },
  {
    title: "Legal",
    items: [
      { name: "Privacy Policy", url: "/privacy" },
      { name: "Terms & Conditions", url: "/terms" },
      { name: "Disclosure", url: "/disclosure" }
    ]
  },
  {
    title: "Contact Us",
    items: [
      { name: "+xxx xxx xxx xxx", url: "tel:+xxxxxxxxxxxx" },
      { name: "+xxx xxx xxx xxx", url: "tel:+xxxxxxxxxxxx" },
      { name: "hello@example.com", url: "mailto:hello@example.com" }
    ]
  }
];


export default function Footer() {
  return (
    <div className="footer">
      <div className="container wide">
        <footer>
          <div className="footer-nav">
            <div className="footer-nav__logo">
              <div>
                {/* <svg
                  width="3.75rem"
                  height="1.125rem"
                  viewBox="0 0 60 18"
                  fill="none"
                >
                  <path
                    d="M57.45 5.653a2.55 2.55 0 100-5.1 2.55 2.55 0 000 5.1zM53.913 9.743H40.935c.096 3.228 2.1 5.036 4.94 5.036 2.324 0 3.906-1.388 4.326-3.26h3.583c-.517 4.1-4.197 6.327-7.91 6.327-5.262 0-8.717-3.034-8.717-8.91 0-5.425 3.39-8.782 8.524-8.782 5.1 0 8.232 3.713 8.232 8.297v1.292zM40.935 7.515h9.104c-.193-2.68-1.904-4.294-4.52-4.294-2.454 0-4.39 1.453-4.584 4.294zM19.83 11.906h3.874c.13 1.97 1.646 3.068 4.327 3.068 2.195 0 3.551-.807 3.551-2.163 0-1.001-.581-1.55-2.292-1.937l-4.198-.905c-3.034-.614-4.875-2.195-4.875-4.715 0-3.1 2.81-5.1 7.265-5.1 4.875 0 7.748 2.195 7.748 5.94h-3.777c0-1.905-1.42-3.067-3.842-3.067-2.195 0-3.52.807-3.52 2.13 0 .904.775 1.614 2.164 1.937l4.39.936c3.261.71 4.844 2.13 4.844 4.617 0 3.26-2.777 5.198-7.49 5.198-5.134.001-8.04-2.13-8.169-5.94zM13.262.411H17.2V17.33h-3.938V.41zM4.068 7.03C4.811 2.187 6.522.41 11.171.41v3.326c-5.198.322-7.135 1.97-7.232 7.038v6.554H0V.669h3.81l.129 6.36h.129z"
                    fill="currentColor"
                  />
                </svg> */}
                LOGO
                <p>
                  Lorem, ipsum dolor <br />sit amet.
                </p>
              </div>
            </div>
            <div className="footer-nav__buttons">
              <DownloadAppButton className="dapp"/>
              <StartInvestButton className="start-invest"/>
              <button
                data-id="appDownloadButton"
                className="py-4 sm:py-3 px-4 font-tomato flex items-center justify-center space-x-2 rounded-full smMax:whitespace-nowrap bg-teal300 text-sm text-white w-full focus:ring-0"
                style={{ display: 'none' }}
              >
                <span className="flex gap-2 items-center text-xs">
                  <svg
                    width="1.5em"
                    height="1.5625em"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <g clipPath="url(#apple-logo-clip-path)" fill="currentColor">
                      <path d="M16.499.5c-1.28.088-2.775.907-3.646 1.974-.796.967-1.45 2.404-1.194 3.801 1.397.043 2.842-.795 3.679-1.88.783-1.009 1.376-2.437 1.161-3.895z" />
                      <path d="M21.555 8.552c-1.228-1.54-2.955-2.435-4.585-2.435-2.153 0-3.063 1.03-4.559 1.03-1.542 0-2.713-1.027-4.575-1.027-1.828 0-3.775 1.118-5.01 3.029-1.735 2.69-1.438 7.75 1.374 12.06 1.007 1.542 2.35 3.276 4.109 3.29 1.564.016 2.005-1.003 4.125-1.013 2.12-.012 2.521 1.027 4.083 1.01 1.76-.013 3.177-1.934 4.183-3.476.722-1.106.99-1.662 1.55-2.91-4.07-1.55-4.722-7.337-.695-9.558z" />
                    </g>
                    <defs>
                      <clipPath id="apple-logo-clip-path">
                        <path
                          fill="currentColor"
                          transform="translate(0 .5)"
                          d="M0 0h24v24H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="inline-block w-0 h-3 border-l border-teal-300" />
                  <svg
                    width="1.5em"
                    height="1.5625em"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <g clipPath="url(#play-store-logo-clip-path)">
                      <path
                        d="M4.66955 0.877687C4.1228 0.52439 3.46608 0.417609 2.8501 0.563015L13.675 11.388L17.1834 7.87959L4.66955 0.877687Z"
                        fill="currentColor"
                      />
                      <path
                        d="M1.50669 1.44287C1.20477 1.84426 1.03101 2.33711 1.03101 2.85845V22.1409C1.03101 22.6622 1.20482 23.155 1.50669 23.5564L12.5634 12.4997L1.50669 1.44287Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.7623 10.4415L18.6092 8.67725L14.7869 12.4996L18.6094 16.3222L21.7628 14.5578C22.5183 14.1345 22.9693 13.3652 22.9693 12.4996C22.9693 11.634 22.5183 10.8648 21.7623 10.4415Z"
                        fill="currentColor"
                      />
                      <path
                        d="M13.6751 13.6116L2.85083 24.4359C3.0283 24.4776 3.20891 24.5001 3.38952 24.5001C3.83586 24.5001 4.28066 24.3732 4.66967 24.1218L17.1837 17.12L13.6751 13.6116Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="play-store-logo-clip-path">
                        <rect
                          width={24}
                          height={24}
                          fill="currentColor"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className="font-medium">Download app</span>
              </button>
              <a
                target="_blank"
                className="rounded-full text-sm font-medium group bg-primary inline-flex border justify-center border-white flex-shrink-0 gap-[0.62rem] py-4 sm:py-3 px-4 items-center"
                href="https://app.risevest.com/signup"
                style={{ display: 'none' }}
              >
                Start Investing
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="new-arrow-right "
                >
                  <path
                    d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
            <ul className="footer-nav__links">
              {
                links.map((link) => (
                  <li key={link.title}>
                    <h3 className="footer-nav__links-title">
                      {link.title}
                    </h3>
                    <ul className="footer-nav__links-list">
                      {
                        link.items.map((item) => (
                          <li key={item.name}>
                            <a href={item.url}>{item.name}</a>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="footer-text">
            <hr />
            <p className="footer-text__text">
              <span>
                ©2024 Tradetrackr.
              </span>
              <span> All rights reserved.</span>
            </p>
            <ul className="footer-text__list">
              {
                socials.map((social) => (
                  <li key={social.name}>
                    <a
                      href={social.url}
                      target="_blank"
                      rel="noreferrer"
                      className="items-center flex gap-2 md:text-sm"
                    >
                      {social.icon}
                      {social.name}
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
          <TradeTrackTextSvg className="footer-text__logo" />
        </footer>
      </div>
    </div>
  );
}

function LinkedInIcon() {
  return (
    <svg
      className="social-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
    </svg>
  );
}

function InstagramIcon() {
  return (
    <svg
      className="social-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
    </svg>
  );
}

function FacebookIcon() {
  return (
    <svg
      className="social-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z" />
    </svg>
  );
}

function TwitterIcon() {
  return (
    <svg
      className="social-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z" />
    </svg>
  );
}

function YoutubeIcon() {
  return (
    <svg
      className="social-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z" />
    </svg>
  );
}

function TradeTrackTextSvg(props) {
  return (
    <svg
      width="388.80765mm"
      height="47.140121mm"
      viewBox="0 0 388.80765 47.140121"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <text
        xmlSpace="preserve"
        style={{
          lineHeight: 0,
          InkscapeFontSpecification: "'Neue Power Ultra-Bold'"
        }}
        x={-126.78139}
        y={129.67685}
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={800}
        fontStretch="normal"
        fontSize="65.4724px"
        fontFamily="Neue Power"
        letterSpacing="2.45521px"
        wordSpacing="14.7313px"
        fill="#000"
        fillOpacity={1}
        stroke="none"
        strokeWidth={4.91043}
        transform="translate(125.145 -83.846)"
      >
        <tspan
          style={{
            InkscapeFontSpecification: "'Neue Power Ultra-Bold'"
          }}
          x={-126.78139}
          y={129.67685}
          fontStyle="normal"
          fontVariant="normal"
          fontWeight={800}
          fontStretch="normal"
          fontFamily="Neue Power"
          fill="#000"
          fillOpacity={1}
          stroke="none"
          strokeWidth={4.91043}
        >
          TradeTrack
        </tspan>
      </text>
    </svg>
  );
}