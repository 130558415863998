import EndPage from "./EndPage";
import Header from "./Header";
import "./index.css";

export default function StandardLayout({ children }) {
  return (
    <div className="standard-layout">
      <Header />
        {children}
      <EndPage />
    </div>
  );
};