
import React, { useRef } from 'react';
import { calculateScales, createPath, formatCurrency, formatDate } from './utils/Chartutils';
import { useChartDimensions, useTooltip } from './hooks/useChartDimensions';

const ProjectionChart = ({ data, onHover }) => {
  const chartRef = useRef(null);
  const dimensions = useChartDimensions(chartRef);
  const { tooltip, handleMouseMove, handleMouseLeave } = useTooltip();
  const scales = calculateScales([...data.investment, ...data.returns], dimensions);

  return (
    <div ref={chartRef} className="chart-container">
      <svg
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        onMouseMove={e => handleMouseMove(e, data)}
        onMouseLeave={handleMouseLeave}
      >
        <defs>
          <linearGradient id="investmentGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#22D8E2" stopOpacity="0.7" />
            <stop offset="100%" stopColor="#22D8E2" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="returnsGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#838F91" stopOpacity="0.7" />
            <stop offset="100%" stopColor="#838F91" stopOpacity="0" />
          </linearGradient>
        </defs>

        <g className="grid-lines">
          {Array.from({ length: 6 }).map((_, i) => {
            const y = i * (dimensions.height - dimensions.margin.top - dimensions.margin.bottom) / 5;
            return (
              <line
                key={i}
                x1={dimensions.margin.left}
                y1={y + dimensions.margin.top}
                x2={dimensions.width - dimensions.margin.right}
                y2={y + dimensions.margin.top}
                stroke="rgba(181, 181, 181, 0.2)"
              />
            );
          })}
        </g>

  
        <path
          d={createPath(data.investment, dimensions, scales)}
          fill="url(#investmentGradient)"
          className="investment-area"
        />

       
        <path
          d={createPath(data.returns, dimensions, scales)}
          fill="url(#returnsGradient)"
          className="returns-area"
        />

        {tooltip && (
          <g className="tooltip" transform={`translate(${tooltip.x}, ${tooltip.y})`}>
            <foreignObject x="-100" y="-50" width="200" height="100">
              <div className="chart-tooltip">
                <div className="tooltip-date">{tooltip.date}</div>
                <div className="tooltip-value">
                  <span className="dot investment"></span>
                  Investment: ${tooltip.investment}
                </div>
                <div className="tooltip-value">
                  <span className="dot returns"></span>
                  Returns: ${tooltip.returns}
                </div>
              </div>
            </foreignObject>
          </g>
        )}
      </svg>
    </div>
  );
};



const Legend = ({ investment, returns }) => (
  <div className="legend">
    <div className="legend-item">
      <div className="dot investment"></div>
      <span>Investment</span>
      <div className="separator"></div>
      <span>{formatCurrency(investment)}</span>
    </div>
    <div className="legend-item">
      <div className="dot returns"></div>
      <span>Returns</span>
      <div className="separator"></div>
      <span>{formatCurrency(returns)}</span>
    </div>
  </div>
);

const GoalSummary = ({ amount, targetDate }) => (
  <div className="goal-summary">
    <p className="label">Wealth Goal</p>
    <p className="amount">{formatCurrency(amount)}</p>
    <p className="date">by {formatDate(targetDate)}</p>
  </div>
);

export { ProjectionChart, Legend, GoalSummary };