import React, { useEffect, useRef } from 'react'
import { DownloadAppButton, StartInvestButton } from '../Buttons';
import Footer from './Footer';

function EndPage() {
  return (
    <section>
      <CallToDownload />
      <NewsletterSignIn />
      <Footer />


    </section>
  );
}

function CallToDownload() {
  const animatedRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          animatedRef.current.classList.add('isVisible');
          observer.unobserve(animatedRef.current); // Stop observing once the class is added
        }
      },
      { threshold: 0.1 }
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div class="container wide call-to-download">

      <div class="ctd-card">
        <div class="ctd-card__content">
          <p class="ctd-card__content-text">
            Download the Rise App
          </p>
          <p class="ctd-card__content-headline">
            Rise makes investing simple.
          </p>
          <div class="ctd-card__content-button-group">
            <StartInvestButton />
            <DownloadAppButton />
          </div>
        </div>
        <div className="ctd-card__image" ref={animatedRef}>
          <span
            style={{
              boxSizing: "border-box",
              display: "inline-block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: 0,
              margin: 0,
              padding: 0,
              position: "relative",
              maxWidth: "100%"
            }}
          >
            <span
              style={{
                boxSizing: "border-box",
                display: "block",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
                maxWidth: "100%"
              }}
            >
              <img
                style={{
                  display: "block",
                  maxWidth: "100%",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0
                }}
                alt=""
                aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27655%27%20height=%27431%27/%3e"
              />
            </span>
            <img
              alt=""
              src="https://risevest.com/_next/static/media/footer-app.f67eada3.webp"
              decoding="async"
              data-nimg="intrinsic"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%"
              }}
            />
          </span>
        </div>
      </div>
    </div>

  );
}

function NewsletterSignIn() {
  return (
    <div className="newsletter">
      <div className="container wide newsletter__wrapper">
        <div className="newsletter__content">
          <div className="newsletter__head">
            <h3 className="newsletter__head-title">
              Weekly newsletter
            </h3>
            <p className="newsletter__head-desc">
              Sign up for our weekly newsletter for fun, finance and more
            </p>
          </div>
          <form className="form">
            <div className="form__group-a">
              <div className="input-container">
                <label className="form__label">
                  <span className="sr-only">First name</span>
                  <input
                    type="text"
                    name="first_name"
                    defaultValue=""
                    required=""
                    title="Enter first name"
                    className="form__input"
                    placeholder="First name"
                  />
                </label>
              </div>
              <div className="input-container">
                <label className="form__label">
                  <span className="sr-only">Last name</span>
                  <input
                    type="text"
                    name="last_name"
                    defaultValue=""
                    required=""
                    title="Enter last name"
                    className="form__input"
                    placeholder="Last name"
                  />
                </label>
              </div>
            </div>
            <div className="form__group-b input-container">
              <label className="form__label">
                <span className="sr-only">Email address</span>
                <input
                  type="email"
                  name="email"
                  defaultValue=""
                  pattern=".+@.+\..{2,}$"
                  required=""
                  title="Enter a valid email address"
                  className="form__input"
                  placeholder="Email address"
                />
              </label>
              <button className="form__submit">
                <span className="sr-only">Subscribe</span>
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="transition"
                >
                  <path
                    d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            {/* <p
              aria-hidden="true"
              aria-live="polite"
              className="absolute duration-400 font-medium text-white transition-[opacity,bottom] left-8 -bottom-12 opacity-0 select-none"
            >
              Thank you for subscribing!
            </p> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EndPage;