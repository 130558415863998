import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addAuth: (state, action) => {
            return {...state, ...action.payload}
        },
        addToken: (state, action) => {
            return {
                ...state,
                user: {
                    ...state.user,
                    accessToken: action.payload
                }
            }
        },
         addAccountId: (state, action) => {
            return {
                ...state,
                user: {
                    ...state.user,
                    accountId: action.payload
                }
            }
        },
        removeAuth: () => {
            return {}
        },
        updateUser: (state, action) => {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        }
    }
})

export const {addAuth, removeAuth, addToken, updateUser, addAccountId} = authSlice.actions

export const refreshTokenSelector = (state) => _.get(state, 'auth.user.refreshToken', '')
export const tokenSelector = (state) => _.get(state, 'auth.user.accessToken', '')
export const accountIdSelector = (state) => _.get(state, 'auth.user.accountId', '')
export const userSelector = (state) => _.get(state, 'auth.user', '')
export const roleSelector = (state) => _.get(state, 'auth.user.role', '')

export default authSlice.reducer
