import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BuildCurrencySelector.css';
import { NigerianFlag, USFlag } from '../CurrencyFlags/CurrencyFlags';
import PageHeader from '../PageHeader/PageHeader';

const CURRENCIES = [
  {
    name: 'Nigerian Naira',
    symbol: '₦',
    path: '/build/naira',
    flag: <NigerianFlag />
  },
  {
    name: 'US Dollar',
    symbol: '$',
    path: '/build/usd',
    flag: <USFlag />
  }
];

const BuildCurrencySelector = ({ onSelect }) => {
  const navigate = useNavigate();

  const handleCurrencySelect = (currency) => {
    
    if (onSelect) {
      onSelect(currency);
    }
    navigate('build-currency');
  };


  const handleKeyPress = (event, currency) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleCurrencySelect(currency);
    }
  };

  return (
    <div className="currency-selector">
      <PageHeader
        title="Select Currency"
        
     />
      
      <p className="currency-selector__subtitle">
        In which currency would you like to calculate your wealth?
      </p>

      <ul className="currency-selector__list">
        {CURRENCIES.map((currency) => (
          <li 
            key={currency.name}
            className="currency-selector__item"
            onClick={() => handleCurrencySelect(currency)}
            onKeyPress={(e) => handleKeyPress(e, currency)}
            role="button"
            tabIndex={0}
          >
            {currency.flag}
            <span className="currency-selector__currency-name">{currency.name}</span>
            <span className="currency-selector__currency-symbol">({currency.symbol})</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BuildCurrencySelector;