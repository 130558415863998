import React, { useState } from 'react';
import './FaqItem.css';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="faq-item">
      <button 
        className={`faq-button ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="faq-question">{question}</p>
        <span className={`plus-icon ${isOpen ? 'open' : ''}`}>+</span>
      </button>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        <p>{answer}</p>
      </div>
    </li>
  );
};

export default FaqItem;