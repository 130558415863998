import React from 'react';

const UserIcon = ({ className }) => (
  <svg 
    className={className || 'icon'} 
    focusable="false" 
    aria-hidden="true" 
    viewBox="0 0 24 24"
  >
    <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 9c2.7 0 5.8 1.29 6 2v1H6v-.99c.2-.72 3.3-2.01 6-2.01m0-11C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4" />
  </svg>
);

export default UserIcon;