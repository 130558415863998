import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
});

export default rootReducer;
