import { Slider } from 'antd';
import React, { useState } from 'react'
import { formatNumberWithCommas } from '../../utils';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
  {
    name: "monthlyIncome",
    question: "How much do you earn monthly?",
    hint: "You can type in your exact income in the display field",
    min: 1, max: 35000, default: 1, formatter: (value) => `$${formatNumberWithCommas(value)}`
  },
  {
    name: "investPercentage",
    question: "What income % do you want to invest monthly?",
    hint: "We recommend 20% but feel free to start lower.",
    min: 1, max: 100, default: 20, formatter: (value) => `${value}%`
  },
  {
    name: "period",
    question: "For a period of",
    hint: "",
    min: 1, max: 65, default: 45, formatter: (value) => `${value} years`
  }
];

function cformat(num) {
  const absNum = Math.abs(num);
  if (absNum >= 1000000) {
    return (Math.sign(num) * (absNum / 1000000).toFixed(1)) + 'M';
  } else if (absNum >= 1000) {
    return (Math.sign(num) * (absNum / 1000).toFixed(1)) + 'K';
  } else {
    return Math.sign(num) * absNum;
  }
}

function EstimationSection() {
  const [infos, setInfos] = useState({
    monthlyIncome: 1,
    investPercentage: 20,
    period: 45
  });

  const handleSliderChange = (value, name) => {
    console.log(name, value)
    setInfos({
      ...infos,
      [name]: value
    });
  };

  function calculateInvestmentGrowth(period) {
    const annualReturnRate = 16.96;
    const monthlyReturnRate = annualReturnRate / 100 / 12;
    const months = (period || infos.period) * 12;
    const monthlyInvestment = infos.monthlyIncome * (infos.investPercentage / 100);

    const futureValue = monthlyInvestment *
      ((Math.pow(1 + monthlyReturnRate, months) - 1) / monthlyReturnRate) *
      (1 + monthlyReturnRate);
    const savings = monthlyInvestment * months;
    
    return [futureValue.toFixed(2), savings.toFixed(2)];
  }


  const [resultForYears, savings] = calculateInvestmentGrowth();

  const chartData = Array.from({ length: infos.period }, (_, i) => ({
    year: i + 1,
    prediction: parseFloat(calculateInvestmentGrowth(i + 1))
  }));

  return (
    <section className="container wide est">
      <div className="est__wrapper">
        <div className="est__container">
          <div className="form">
            <h1 className="form__title">
              Start building your future
            </h1>
            <form>
              <div className="form__inputs">
                {
                  data.map(item => (
                    <div className="form__input" key={item.id}>
                      <p className="form__input-label">
                        {item.question}
                      </p>
                      {
                        item.hint &&
                        <p className="form__input-sublabel">
                          {item.hint}
                        </p>
                      }
                      <div className="form__input-slider-container">
                        <Slider
                          min={item.min}
                          max={item.max}
                          defaultValue={item.default}
                          onChange={(value) => handleSliderChange(value, item.name)}
                          tooltip={{ open: false }}
                        />
                        <p>{item.formatter(infos[item.name])}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="form__result">
                <p className="form__result-a">
                  You get
                </p>
                <p className="form__result-b">
                  ${formatNumberWithCommas(resultForYears)}
                </p>
                <p className="form__result-c">
                  {`in ${infos.period} year${infos.period > 1 ? 's' : ''}`}
                </p>
              </div>
            </form>
          </div>
          <div className="results">
            <div className="results__wrapper-1">
              <div className="results__wrapper-2">
                <div className="results__item">
                  <span />
                  <p className="results__item-label">
                    Returns
                  </p>
                  <p className="results__item-value">
                    ${formatNumberWithCommas(resultForYears)}
                  </p>
                </div>
                <div className="results__item">
                  <span />
                  <p className="results__item-label">
                    Savings
                  </p>
                  <p className="results__item-value results__item-value--2">
                    ${formatNumberWithCommas(savings)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                width={500}
                height={200}
                margin={{
                  left: 30,
                }}
                data={chartData}
              >
                <CartesianGrid strokeDasharray="8" stroke='#04673966' vertical={false} />
                <XAxis dataKey="year" tick={false} stroke='#046739' />
                <YAxis tickFormatter={(val) => '$' + cformat(val)} stroke='#046739' tick={{ fill: "default" }} tickCount={6} domain={[0, 'dataMax']} />
                <Area type="monotone" dataKey="prediction" stroke="#046739" fill="#04673966" />
                <Tooltip formatter={(value) => [`$${formatNumberWithCommas(value)}`]} labelFormatter={(value) => `In ${value} years`} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </section>

  );
}

export default EstimationSection;