import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import './PlanReview.css';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const PlanReview = () => {
  const navigate = useNavigate();
  
  const planDetails = [
    { label: 'Build wealth for', value: 'EMMANUEL' },
    { label: 'Target amount', value: '$0.01' },
    { label: 'Estimated earnings', value: '$0.02' },
    { label: 'Interest rate', value: '0.00% per annum', highlight: true },
    { label: 'Target date', value: '30th October, 2029' },
    { label: 'Est. recurring investment', value: '$0.00' }
  ];

  const handleCreatePlan = (e) => {
    e.preventDefault();
    navigate('success');
  };

  return (
    <div className="plan-review">
      <div className="review-container">
        <form onSubmit={handleCreatePlan}>
           <Header
            title="Review"
            
          />

          <div className="plan-banner">
            <div 
              className="banner-content"
              style={{ backgroundImage: 'url("/assets/build-wealth-cover-705033a4.png")' }}
            >
              <div className="banner-text">
                <h2>(Build wealth)</h2>
                <h3>for EMMANUEL</h3>
              </div>
            </div>
          </div>

          <div className="plan-details">
            {planDetails.map(({ label, value, highlight }) => (
              <div key={label} className="detail-row">
                <p className="label">{label}</p>
                <h2 className={`value ${highlight ? 'highlight' : ''}`}>
                  {value}
                </h2>
              </div>
            ))}
          </div>

          <div className="action-button">
            <button 
              type="submit" 
              className="create-plan"
            >
              Create Plan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PlanReview;