import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CurrencySelector from '../CurrencySelector/CurrencySelector';
import TransferPage from '../TransferPage/TransferPage';

const TransferRoute = () => {
  return (
    <Routes>
      <Route index element={<TransferPage />} />
      <Route path="send-money" element={<CurrencySelector />} />
      <Route path="send-money-user" element={<CurrencySelector />} />
    </Routes>
  );
};

export default TransferRoute;