import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ChooseCard.css';
import SaveCardModal from '../SavedCardModal/SavedCardModal';
import { BackButton } from '../Buttons';
import CardIcon from '../Icons/CardIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import Header from '../PageHeader/Header';

const ChooseCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddCard = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveCard = () => {
    setIsModalOpen(false);
  };

  const handleDontSaveCard = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="choose-card">
      <div className="choose-card__container">
        <form className="choose-card__form">
          <Header
            title="Choose card"
            
          />

          <div className="choose-card__content">
            <div className="card-list">
              <p className="no-cards-message">You have no cards</p>
            </div>

            <button 
              className="add-card-button" 
              type="button"
              onClick={handleAddCard}
            >
              <div className="add-card-button__content">
                <CardIcon className="card-icon" />
                <div className="add-card-button__text">
                  <p>Pay with a new Nigerian Naira Card</p>
                  <p className="subtitle">We accept Mastercard, Visa and Verve.</p>
                </div>
              </div>
              <ChevronRightIcon className="arrow-icon" />
            </button>
          </div>
        </form>
      </div>

      {isModalOpen && (
        <SaveCardModal
          onClose={handleCloseModal}
          onSave={handleSaveCard}
          onDontSave={handleDontSaveCard}
        />
      )}
    </div>
  );
};

export default ChooseCard;