import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './DebitCardFunding.css';
import { BackButton } from '../Buttons';
import CircularArrowIcon from '../Icons/CircularArrowIcon';
import Header from '../PageHeader/Header';


const DebitCardFunding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ngnValue, setNgnValue] = useState('');
  const [usdValue, setUsdValue] = useState('');

  const handleNGNChange = (e) => {
    setNgnValue(e.target.value);
    const usd = (+e.target.value / 1690).toFixed(2);
    setUsdValue(usd);
  };

  const handleUSDChange = (e) => {
    setUsdValue(e.target.value);
    const ngn = (+e.target.value * 1690).toFixed(2);
    setNgnValue(ngn);
  };

  const handleAddMoney = () => {
    navigate(`${location.pathname}/confirm`);
  };

  return (
    <form className="debit-form">
       <Header
        title="Debit Card"
        
      />

      <div className="debit-form__content">
        <div className="wallet-info">
          <div>
            <p>Your Debit card</p>
            <p className="text-soft"></p>
          </div>
          <div>
            <p>Rise Wallet</p>
            <p className="text-soft">$0</p>
          </div>
        </div>

        <div className="arrow-icon">
          <CircularArrowIcon />
        </div>

        <div className="currency-inputs">
          <div className="currency-input">
            <label htmlFor="currency-value">NGN</label>
            <input
              type="text"
              id="currency-value"
              inputMode="numeric"
              autoComplete="off"
              placeholder="0.00"
              value={ngnValue}
              onChange={handleNGNChange}
            />
          </div>

          <div className="exchange-rate">
            <span>$1 = NGN 1690</span>
            <div className="rate-info">
              <button type="button">Why this rate?</button>
            </div>
          </div>

          <div className="currency-input">
            <label htmlFor="dollar-equivalent">$</label>
            <input
              type="text"
              id="dollar-equivalent"
              inputMode="numeric"
              autoComplete="off"
              placeholder="0.00"
              value={usdValue}
              onChange={handleUSDChange}
            />
          </div>
        </div>

        <p className="limit-text">There's a funding limit of ₦500,000</p>

        <button 
          className="submit-button" 
          type="button"
          disabled={!ngnValue || +ngnValue <= 0}
          onClick={handleAddMoney}
        >
          <span>Add Money</span>
        </button>
      </div>
    </form>
  );
};

export default DebitCardFunding;