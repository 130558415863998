import React from 'react';
import './NairaWallet.css';

const NairaWallet = () => {
  return (
    <section className="naira-wallet">
      <h1 className="naira-wallet__title">Make the best of Rise</h1>
      <div className="naira-wallet__container">
        <img src="/tradetrackr/naira-wallet.png" alt="Naira-wallet" className="naira-wallet__bg-image" />
        <div className="naira-wallet__content">
          <div className="naira-wallet__flex-container">
            <div className="naira-wallet__mobile-image-container">
              <img src="/assets/naira-wallet-mobile-bff4e776.png" alt="Naira Wallet" className="naira-wallet__mobile-image" />
            </div>
            <div className="naira-wallet__text-container">
              <h1 className="naira-wallet__subtitle">Rise Naira Wallet is here!</h1>
              <p className="naira-wallet__description">You can now hold and invest your Naira on Rise to earn up to 20% interest annually.</p>
            </div>
          </div>
          <div className="naira-wallet__cta">Get in app</div>
          <a
            className="naira-wallet__link"
            href="https://play.google.com/store/apps/details?id=com.rise.mobile"
            rel="noreferrer"
            target="_blank"
          >
            <p className="sr-only">Link to get naira wallet card in app</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default NairaWallet;