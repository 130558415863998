import './spinner.css';

function Spinner({color}) {
    color = color || 'white';
    
    return (
        <div className="lds-ellipsis">
            <div style={{background: color}}></div>
            <div style={{background: color}}></div>
            <div style={{background: color}}></div>
        </div>
    );
}

export default Spinner;