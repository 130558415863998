import React, { } from 'react';
import { Alert, Button, Flex, Form, InputNumber, Table } from 'antd';
import './style.css';

function AmountsForm({ data, onChange, onSubmit, loading }) {

    const onFinish = () => {
        onSubmit();
    };
    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };
    const onFieldsChange = (changedFiels) => {
        const name = changedFiels[0].name[0];
        const value = changedFiels[0].value;
        onChange({
            ...data,
            [name]: parseFloat(value || 0)
        });
    };

    const totalPercentages = data.tradingPortfolioPct + data.dividendPortfolioPct + data.growthPortfolioPct;

    return (
        <>
            {totalPercentages > 100 && <Alert style={{ marginBottom: '2rem' }} type='warning' message="Внимание: Сумма процентов превышает 100%." closeIcon closable />}
            <Flex gap="large" align='center'>
                <Form
                    name="diary-settings"
                    labelCol={{
                        span: 12,
                    }}
                    style={{
                        flex: 1
                    }}
                    labelAlign='left'
                    onFinish={onFinish}
                    onFieldsChange={onFieldsChange}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={data}
                >
                    <Form.Item
                        label="Общая сумма средств"
                        name="totalFunds"
                        rules={[{ required: true, message: '' }]}
                    >
                        <InputNumber disabled={loading} value={data.totalFunds} suffix="₽" />
                    </Form.Item>

                    <Form.Item
                        label="Trading portfolio (%)"
                        name="tradingPortfolioPct"
                        rules={[{ required: true, message: '' }]}
                    >
                        <InputNumber disabled={loading} value={data.tradingPortfolioPct} suffix="%" min={0} max={100.0} />
                    </Form.Item>

                    <Form.Item
                        label="Dividend portfolio (%)"
                        name="dividendPortfolioPct"
                        rules={[{ required: true, message: '' }]}
                    >
                        <InputNumber disabled={loading} value={data.dividendPortfolioPct} suffix="%" min={0} max={100.0} />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        label="Growth portfolio (%)"
                        name="growthPortfolioPct"
                        rules={[{ required: true, message: '' }]}
                    >
                        <InputNumber disabled={loading} value={data.growthPortfolioPct} suffix="%" min={0} max={100.0} />
                    </Form.Item>
                </Form>
                <AmountsSyncTableView data={data} />
            </Flex >
                    <Flex gap="middle">
                        <Button loading={loading} type="button" onClick={onFinish} className='diary-submit-button'>
                            Сохранить
                        </Button>
                    </Flex>
        </>
    );
}

export function AmountsSyncTableView({ data }) {
    let { totalFunds, tradingPortfolioPct, dividendPortfolioPct, growthPortfolioPct } = data;
    const existTotal = !!totalFunds;
    tradingPortfolioPct = existTotal ? `${totalFunds * tradingPortfolioPct / 100} ₽` : '-';
    dividendPortfolioPct = existTotal ? `${totalFunds * dividendPortfolioPct / 100} ₽` : '-';
    growthPortfolioPct = existTotal ? `${totalFunds * growthPortfolioPct / 100} ₽` : '-';
    totalFunds = existTotal ? `${totalFunds} ₽` : '-';

    return (
        <div style={{ flex: 1 }}>
            <Table
                columns={[
                    { title: "Счет", dataIndex: "account", key: "account" },
                    { title: "Стоимость", dataIndex: "cost", key: "cost" },

                ]}
                dataSource={[
                    { key: 1, account: "Общая сумма средств: ", cost: totalFunds },
                    { key: 2, account: "Trading portfolio", cost: tradingPortfolioPct },
                    { key: 3, account: "Dividend portfolio", cost: dividendPortfolioPct },
                    { key: 4, account: "Growth portfolio", cost: growthPortfolioPct }
                ]}
                size="middle"
                pagination={false}
            />
        </div>
    );
}

export default AmountsForm;