import React from 'react';

const RealEstateIcon = ({ className }) => (
  <svg 
    width="12" 
    height="14" 
    viewBox="0 0 12 14" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M5.69056 0.607254C5.87207 0.464249 6.12793 0.464249 6.30944 0.607254L11.0472 4.34002C11.6489 4.81407 12 5.53777 12 6.30375V12.5C12 12.9602 11.6269 13.3333 11.1667 13.3333H7.83333C7.3731 13.3333 7 12.9602 7 12.5V9.16667C7 9.07462 6.92538 9 6.83333 9H5.16667C5.07462 9 5 9.07462 5 9.16667V12.5C5 12.9602 4.6269 13.3333 4.16667 13.3333H0.833333C0.373096 13.3333 0 12.9602 0 12.5V6.30375C0 5.53777 0.351149 4.81407 0.95282 4.34002L5.69056 0.607254ZM6 1.63654L1.57169 5.12551C1.21069 5.40994 1 5.84416 1 6.30375V12.3333H4V9.16667C4 8.52233 4.52233 8 5.16667 8H6.83333C7.47767 8 8 8.52233 8 9.16667V12.3333H11V6.30375C11 5.84416 10.7893 5.40994 10.4283 5.12551L6 1.63654Z" fill="currentColor" />
  </svg>
);

export default RealEstateIcon;