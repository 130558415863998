import React from 'react';

const CloseButton = ({ onClick, className = '' }) => {
  return (
    <button 
      className={`close-button ${className}`}
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24"
      >
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
      </svg>
    </button>
  );
};

export default CloseButton;