import { LoginButton } from "@telegram-auth/react";
import style from "./style.module.css";

export function TelegramButton({ onAuth }) {
    return (
        <div className={style.telegramButton}>
            <LoginButton
                botUsername="TinkoffAuthTestBot"
                onAuthCallback={onAuth}
                buttonSize="large"
                cornerRadius={8}
                showAvatar={true}
                lang="ru"
            />
        </div>
    );
}