import React from 'react';
import './index.css';

const Disclose = () => {
  return (
    <>
      <section className="disclose-section">
        <div className="banner">
          <h1>Disclosure</h1>
          <img
            className="dark-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/dark-ball.2a2bff54.webp"
            width="213"
            height="208"
            decoding="async"
            data-nimg="future"
          />
          <img
            className="light-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/light-ball.6236400f.webp"
            width="462"
            height="252"
            decoding="async"
            data-nimg="future"
          />
        </div>
      </section>

      <main className="disclose-main">
        <div className="sidebar">
          <div className="sidebar-content">
            <a href="#important">Important</a>
            <a href="#contact">Contact Information</a>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="main-content">
          <h2>Disclosure</h2>
          <div className="last-modified">
            <svg width="1em" height="1em" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M13.75 1.65625H11.7188V0.875C11.7188 0.75068 11.6694 0.631451 11.5815 0.543544C11.4935 0.455636 11.3743 0.40625 11.25 0.40625C11.1257 0.40625 11.0065 0.455636 10.9185 0.543544C10.8306 0.631451 10.7812 0.75068 10.7812 0.875V1.65625H4.21875V0.875C4.21875 0.75068 4.16936 0.631451 4.08146 0.543544C3.99355 0.455636 3.87432 0.40625 3.75 0.40625C3.62568 0.40625 3.50645 0.455636 3.41854 0.543544C3.33064 0.631451 3.28125 0.75068 3.28125 0.875V1.65625H1.25C0.959919 1.65625 0.68172 1.77148 0.476602 1.9766C0.271484 2.18172 0.15625 2.45992 0.15625 2.75V15.25C0.15625 15.5401 0.271484 15.8183 0.476602 16.0234C0.68172 16.2285 0.959919 16.3438 1.25 16.3438H13.75C14.0401 16.3438 14.3183 16.2285 14.5234 16.0234C14.7285 15.8183 14.8438 15.5401 14.8438 15.25V2.75C14.8438 2.45992 14.7285 2.18172 14.5234 1.9766C14.3183 1.77148 14.0401 1.65625 13.75 1.65625ZM1.25 2.59375H3.28125V3.375C3.28125 3.49932 3.33064 3.61855 3.41854 3.70646C3.50645 3.79436 3.62568 3.84375 3.75 3.84375C3.87432 3.84375 3.99355 3.79436 4.08146 3.70646C4.16936 3.61855 4.21875 3.49932 4.21875 3.375V2.59375H10.7812V3.375C10.7812 3.49932 10.8306 3.61855 10.9185 3.70646C11.0065 3.79436 11.1257 3.84375 11.25 3.84375C11.3743 3.84375 11.4935 3.79436 11.5815 3.70646C11.6694 3.61855 11.7188 3.49932 11.7188 3.375V2.59375H13.75C13.7914 2.59375 13.8312 2.61021 13.8605 2.63951C13.8898 2.66882 13.9062 2.70856 13.9062 2.75V5.40625H1.09375V2.75C1.09375 2.70856 1.11021 2.66882 1.13951 2.63951C1.16882 2.61021 1.20856 2.59375 1.25 2.59375ZM13.75 15.4062H1.25C1.20856 15.4062 1.16882 15.3898 1.13951 15.3605C1.11021 15.3312 1.09375 15.2914 1.09375 15.25V6.34375H13.9062V15.25C13.9062 15.2914 13.8898 15.3312 13.8605 15.3605C13.8312 15.3898 13.7914 15.4062 13.75 15.4062Z" fill="currentColor"></path>
            </svg>
            <p>Last modified: July 9, 2019</p>
          </div>
          <p>All returns numbers given are based on historical results. Past performance is no guarantee of future results. Any historical returns, expected returns, or probability projections, are hypothetical in nature and may not reflect actual future performance.</p>
          
          <div id="important">
            <h2 className="section-title">Important</h2>
            <p>All Rise performance results show the results of the broad portfolio. Individual account returns may be different.</p>
          </div>

          <p>Historical performance, All-Time IRR , and similar returns information are from Rise's predecessor funds or partners' funds including Cashestate. Performance results are net of fees and include dividends and other adjustments.</p>

          <p>All-Time IRR (internal rate of return) is net of fees and includes dividends and other adjustments. IRR is calculated using Microsoft Excel's XIRR function based on an illustrative starting Rise account value of $1,000 at inception on Rise's launch date of March 11, 2019 and its ending value on September 10, 2019. Alpha measures performance vs. a benchmark on a risk-adjusted basis.</p>

          <p>Results for the Rise Equity Index as compared to the performance of the Standard &amp; Poor's 500 Index (the "S&amp;P 500") is for informational purposes only. Account holdings are for illustrative purposes only and are not investment recommendations. The S&amp;P 500 is an unmanaged market capitalization-weighted index of 500 common stocks chosen for market size, liquidity, and industry group representation to represent U.S. equity performance.</p>

          <p>The investment program does not mirror this index and the volatility may be materially different than the volatility of the S&amp;P 500. Reference or comparison to an index does not imply that the portfolio will be constructed in the same way as the index or achieve returns, volatility, or other results similar to the index. Indices are unmanaged, include the reinvestment of dividends and do not reflect transaction costs.</p>

          <div id="contact">
            <h2 className="section-title">Contact Information</h2>
            <div>
              <p>Rise Vest Technologies Global Limited<br />P.O. Box 31119 Grand Pavilion<br />Hibiscus Way, 802 West Bay Road<br />KYI-1205<br />Cayman Islands</p>
              <br />
              <p>Or</p>
              <br />
              <p>358 Herbert Macaulay way,<br />Yaba, Lagos<br />Nigeria<br /></p>
              <p>Email:&nbsp;<a href="mailto:hello@risevest.com">hello@risevest.com</a></p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Disclose;

