import React from 'react';
import { Card, Typography, Button } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import './quote.css';

const { Title, Text } = Typography;

const QuoteCard = ({ title, quote, author, onShare }) => {
  return (
    <>
      <Title level={5} className="make-best">{title}</Title>
      <Card className="quote-card">
        <Text className="quote-label">TODAY'S QUOTE</Text>
        <Text className="quote-text">{quote}</Text>
        <div className="quote-footer">
          <Text type="secondary">{author}</Text>
          <Button type="text" icon={<ShareAltOutlined />} onClick={onShare} />
        </div>
      </Card>
    </>
  );
};

export default QuoteCard;