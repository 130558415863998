import { CloseOutlined } from "@ant-design/icons";
import MenuIcon from "../Icons/MenuIcon";
import { useRef, useState } from "react";

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        const vcls = "header-menu--visible";
        const open = !isOpen;
        setIsOpen(open);
        if (open) {
            menuRef.current.classList.add(vcls);
            animateIn();
        } else {
            menuRef.current.classList.remove(vcls);
        }
    };

    const animateIn = () => {
        const links = document.querySelectorAll('.header-menu__nav-link');
        const maxDelay = 300, minDelay = 100;
        links.forEach((link, index) => {
            link.animate([
                { transform: 'translateY(100%)', opacity: 0 },
                { transform: 'translateY(0)', opacity: 1 }
            ], {
                duration: 300,
                easing: 'ease-out',
                fill: 'both',
                // increasing delay for each link to create stagger effect
                delay: minDelay + (maxDelay - minDelay) * (index / links.length)
            });
        });
    }

    return (
        <header className="header">
            <div className="header-content container flex-center wide">
                <a href="/">
                    <div className="flex relative">
                        <img
                            src="./logos/logo_with_text.png"
                            alt="TradeTrackr Logo"
                            className="header-logo"
                        />
                    </div>
                    <span className="sr-only">Home page</span>
                </a>
                <div className="mobile-nav">
                    <button className="mobile-nav__button" onClick={toggleMenu}>
                        <MenuIcon size={24} />
                        <span className="sr-only">open menu</span>
                    </button>
                    <div className="header-menu" ref={menuRef}>
                        <div className="header-menu__header">
                            <a href="/">
                                LOGO
                            </a>
                            <button className="header-menu__button" onClick={toggleMenu}>
                                <CloseOutlined />
                                <span className="sr-only">close menu</span>
                            </button>
                        </div>
                        <a
                            className="header-menu__nav-link active"
                            href="/why-rise"
                        >
                            Why Rise
                        </a>
                        <a
                            className="header-menu__nav-link"
                            href="/why-rise"
                        >
                            Why Rise
                        </a>
                        <a
                            className="header-menu__nav-link"
                            href="/why-rise"
                        >
                            Why Rise
                        </a>
                        <a
                            className="header-menu__nav-link"
                            href="/why-rise"
                        >
                            Why Rise
                        </a>
                        <div className="header-menu__actions header-menu__nav-link">
                            <div>
                                <a
                                    className="tsignin"
                                    href="https://app.risevest.com/login"
                                >
                                    Log In
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://app.risevest.com/signup"
                                    className="tsignup"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="larger-nav flex-center">
                    <div className="nav-links flex-center">
                        <a className="nav-links__item" href="/why-rise"> Why Rise </a>
                        <a className="nav-links__item" href="/why-rise"> Why Rise </a>
                        <a className="nav-links__item" href="/why-rise"> Why Rise </a>
                    </div>
                    <div className="nav-actions flex-center">
                        <a className="nav-actions__login" href="https://app.risevest.com/login"> Log In </a>
                        <a className="nav-actions__signup" href="https://app.risevest.com/signup"> Get started </a>
                    </div>
                </nav>
            </div>
        </header>
    );
}