import './style.css';

const test_data = [
    { name: 'Материалы', profit: 50 },
    { name: 'Информационные технологии', profit: 33 },
    { name: 'ETF', profit: 5.4 },
    { name: 'Облигации', profit: 4.8 },
    { name: 'Промышленность', profit: -3.8 },
    { name: 'Товары повседневного спроса', profit: -4.4 },
    { name: 'Финансы', profit: -11 },
    { name: 'Энергетика', profit: -14 },
    { name: 'Коммунальные услуги', profit: -58 },
];

export default function VerticalBarChart({ data = test_data, dataKey = 'profit' }) {
    const max = Math.max(...data.map(d => Math.abs(d[dataKey])));
    const limit = Math.round((max / 10) + .5) * 10;

    const format = (value) => `${value > 0 ? '+' : ''}${value} %`;
    const getColor = (value) => value > 0 ? '#45BAB4' : '#F0686F';

    return (
        <table className='vbar-chart'>

            <tbody>
                {data.map((d, i) => (
                    <tr key={i}>
                        <td className='vbar-chart__name'>{d.name}</td>
                        <td className='vbar-chart__line'>
                            <div style={{
                                width: `${Math.abs(d[dataKey]) / limit * 100}%`,
                                backgroundColor: getColor(d[dataKey])
                            }}>
                                <span style={{ color: getColor(d[dataKey]) }}>{format(d[dataKey])}</span>
                            </div>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td></td>
                    <td className='vbar-chart__xaxis'>
                        <div>
                            {Array.from({ length: (limit / 10) + 1 }, (_, i) => {
                                const v = i * 10;
                                return (
                                    <div className='vbar-chart__label' style={{ left: `${v / limit * 100}%` }} key={i}> {v}% </div>
                                );
                            })}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}