import React, { useState } from 'react';
import { BackButton } from '../Buttons';
import './AddMoneyPlan.css';
import HorizontalArrowIcon from '../Icons/HorizontalArrowIcon';
import Header from '../PageHeader/Header';

const AddMoneyPlan = () => {
  const [amount, setAmount] = useState('');

  

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  return (
      <div className="add-money-container2">
      <div className="add-money-grid2">
        <div className="add-money-content2">
          <div className="content-wrapper">
             <Header
               title= "Add money to plan"
               
            />

            <div className="wallet-info">
              <div className="wallet-details">
                <p>Rise Wallet</p>
                <p className="amount">$0.00</p>
              </div>
              <HorizontalArrowIcon />
              <div className="wallet-details">
                <p>Test</p>
                <p className="amount">$0.00</p>
              </div>
            </div>

            <div className="amount-input">
              <label htmlFor="dollar-amount">$</label>
              <input
                type="text"
                id="dollar-amount"
                inputMode="numeric"
                autoComplete="off"
                placeholder="0.00"
                value={amount}
                onChange={handleAmountChange}
              />
            </div>

            <button 
              className="continue-button" 
              type="button" 
              disabled={!amount}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMoneyPlan;