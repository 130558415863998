import { Route, Routes } from "react-router-dom";
import Dashboard from "../../Pages/Dashboard";
import Settings from "../../Pages/Settings";
import Statistics from "../../Pages/Statistics";
import Diary from "../../Pages/Diary";
import DiarySettings from "../../Pages/DiarySettings";
import RequireToken from "../RequireToken";
import Subcription from "../Subscription/Subscription";


function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireToken />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/diary" element={<Diary />} />
        <Route path="/diary-settings" element={<DiarySettings />} />
      </Route>
      <Route path="/settings" element={<Settings />} />
      <Route path="/subscription-settings" element={<Subcription />} />
    </Routes>
  );
}
export default AppRoutes;