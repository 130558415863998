import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Typography, Checkbox, Empty, message } from 'antd';
import "./settings.css"
import { saveTinkoffToken } from '../../API/authService';
import Spinner from '../Spinner';
import { fetchTinkoffAccounts, updateCheckedAccount } from "../../API/accountsService";
import { addTinkoffAccounts, existTinkoffTokenSelector, setExistTinkoffToken, tinkoffAccountsSelector } from '../../redux/slices/commonSlice';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';

const { Title } = Typography;
const mdot = '●'.repeat(50);

const SettingsPage = () => {
  const tokenExist = existTinkoffTokenSelector(store.getState());
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    apiToken: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    errors: {
      apiToken: 'apiToken Required'
    }
  });
  const accountList = tinkoffAccountsSelector(store.getState());
  const [messageApi, contextHolder] = message.useMessage();
  const openNotification = (isSuccess, message) => {
    messageApi.open({
      type: isSuccess ? 'success' : 'error',
      content: message,
      className: 'custom-class',
      style: {
        marginTop: '6vh',
      },
    });
  };
  const redirectMessage = () => {
    setTimeout(() => {
      messageApi.error({
        content: 'Неверно: перенаправлено',
        duration: 2,
      });
    }, 1000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (checkedValues) => {
    const checkedList = checkedValues.filter(checked => !accountList.find(account => account.id === checked).isChecked);
    const unCheckedList = accountList.filter(account => !checkedValues.includes(account.id)).map(account => account.id);

    const updateAccountStatus = async (accountId, isChecked) => {
      const newAccounts = accountList.map(account => ({
        ...account,
        isChecked: account.id === accountId ? isChecked : account.isChecked
      }));

      dispatch(addTinkoffAccounts(newAccounts));
      try {
        await updateCheckedAccount(accountId, isChecked);
      } catch {
        openNotification(false, 'Не синхронизировано');
      }
    };

    if (checkedList.length !== 0) {
      updateAccountStatus(checkedList[0], true);
    } else if (unCheckedList.length !== 0) {
      updateAccountStatus(unCheckedList[0], false);
    }
  };



  const handleFormSubmit = async (values) => {
    setError(null);
    setIsLoading(true);

    try {
      await saveTinkoffToken(values.apiToken);
      openNotification(true, 'сохранено');
      const accountList = await fetchTinkoffAccounts();
      dispatch(addTinkoffAccounts(accountList));
      dispatch(setExistTinkoffToken(true));
      setFormData({ apiToken: mdot });
    } catch (e) {
      if (typeof (e) === 'string') {
        setError({ detail: e, type: 'apiToken' });
      } else {
        e.type = 'apiToken';
        setError(e);
      }
      dispatch(addTinkoffAccounts([]));
      dispatch(setExistTinkoffToken(false));
      redirectMessage();
    }
    setIsLoading(false);
  };

  const onTokenChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      apiToken: value
    });
  };

  const Errors = () => {
    if (!error) return null;
    const { errors } = error;
    if (errors)
      return <ul className='error'>{Object.keys(errors).map(field =>
        <li key={field}>{`${field}: ${errors[field]}`}</li>
      )}</ul>;
    return <p className='error'>{error.detail}</p>;
  }

  const validateStatus = tokenExist ? 'success' : (formData.apiToken && formData.apiToken.length < 5) || !formData.api ? 'error' : 'success';

  return (
    <div>
      {contextHolder}
      <div className='api-form-title'>
        <Title level={2}>
          Импорт операций — Тинькофф инвестиции
        </Title>
      </div>

      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 1000 }}
          initialValues={{ remember: true, apiToken: tokenExist ? mdot : '' }}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          disabled={isLoading || tokenExist}
        >

          <div className="api-form-group">
            <Title level={3} className="responsive-typography">
              API токен:
            </Title>
            <div style={{ position: 'relative' }}>
              <Form.Item
                name="apiToken"
                style={{ marginBottom: 0 }}
                validateStatus={validateStatus}
              >
                <Input
                  id="apiToken"
                  name="apiToken"
                  className="responsive-input"
                  style={{ marginRight: '8px' }}
                  onChange={onTokenChange}
                  value={formData.apiToken}
                />
              </Form.Item>

              {(formData.apiToken && formData.apiToken.length < 5) || !formData.apiToken ? (
                <div style={{ position: 'absolute', top: '100%', marginTop: '5px', color: 'red' }}>
                  {formData.apiToken && formData.apiToken.length < 5 ?
                    'Токен API должен содержать не менее 5 символов.' : null
                  }
                </div>
              ) : null}

            </div>

            <Button className='api-form-add' disabled={isLoading} htmlType="submit">
              {
                isLoading && (formData.email || formData.name) !== '' ? <Spinner /> : 'Добавить'
              }
            </Button>
          </div>
        </Form>
      </div>
      <div>
        {error?.type === 'apiToken' && Errors()}
      </div>

      <div>
        <Typography className='brokerage-desc'>Операции по брокерскому счету автоматически добавляются в статистику.</Typography>
      </div>
      <div>
        <Title level={2}>Брокерские счета</Title>
        <Typography className='brokerage-account'>На тарифе «Премиум» доступно обновление для 4 счетов, у вас 9 счетов для которых можно настроить обновление операций,
          <br></br> подробнее о лимитах на <span className='brokerage-account2'>странице тарифов.</span>
        </Typography>
        <div className='accounts'>
          {
            accountList.length === 0 ? <Empty description="Пустой" /> :
              <Checkbox.Group
                style={{
                  width: '100%',
                }}
                onChange={onChange}
                defaultValue={accountList.filter(account => account.isChecked).map(account => account.id)}
              >
                <Row>
                  {accountList.map((account) => (
                    <Col span={24} className='accountslevel1' key={account.id}>
                      <Checkbox value={account.id} className='accountslevel2'>
                        {account.name}
                      </Checkbox>
                      <span className='accountslevel2'>
                        {account.access === 'read' ? 'Доступ на чтение' : 'Полный доступ'}
                      </span>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
          }
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
