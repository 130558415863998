import React from 'react';
import './ArticleDetails.css';
import BackArrowIcon from '../Icons/BackArrowIcon';
import { BackButton } from '../Buttons';
import TwitterIcon from '../Icons/TwitterIcon';
import { Share2 } from 'lucide-react';

const ArticleDetails = () => {
  return (
    <article className="article-details">
      <div 
        className="hero-image"
        style={{
          backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          ), url('/tradetrackr/nig.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="back-button-container">
          <BackButton />
        </div>
        
        <div className="share-container">
          
          <button className="share-button">
            <Share2 size={18} />
            </button>
        </div>
      </div>

      <div className="content">
        <h1>How Does the US Election Affect my Portfolio</h1>
        
        <div className="meta">
          <p className="category">Investing</p>
          <p className="date">Posted 5th Nov 2024</p>
        </div>

        <div className="article-body">
          <p>Come November 5th, the most important political event in the world will take place—the US elections. Everyone is wondering who will emerge as the winner: Donald Trump or Kamala Harris.</p>

          <p>At Rise, we are pondering about that too but more importantly, about what this election means for your investments and how it affects your stocks portfolio.</p>
          
          <h2>How do US stocks generally perform in an election year?</h2>
          <p>Historically, it's common for stocks to stumble slightly and for market volatility to rise before an election, often followed by a post-election relief rally that lasts through the inauguration...</p>
          
          <h2>What are the big economic risks this time around?</h2>
          <p>If Harris wins the White House, that's likely to continue many of the current administration's policies, where she's second-in-command...</p>
          
          <h2>What does this mean for my Rise Portfolio?</h2>
          <p>The stock market has been in retreat as companies release their quarterly earnings report, reflecting investors' careful assessment of corporate performance...</p>
        </div>
      </div>
    </article>
  );
};

export default ArticleDetails;