import axios from 'axios';
import { addToken, refreshTokenSelector, removeAuth, tokenSelector } from './redux/slices/authSlice';
import { store } from './redux/store';
import { refreshToken } from './API/authService';

const baseURL = "https://api.tradetrackr.ru/api";
const headers = { "Content-Type": "application/json" };

const axiosClient = axios.create({ baseURL, headers });

const publicAxiosClient = axios.create({ baseURL, headers });

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const responseInterceptors = [
    (response) => response.data,
    async (error) => {
        const e = error.response.data;
        const originalConfig = error.config;

        if (e.status === 401 && !originalConfig._retry) {
            if (e.title === "Invalid Token") {
                console.error("Invalid Token: ", refreshTokenSelector(store.getState()));
                store.dispatch(removeAuth());
                return Promise.reject(error);
            } else if (e.title === "Expired Token") {
                if (!isRefreshing) {
                    isRefreshing = true;

                    try {
                        const newAccessToken = await refreshToken();
                        store.dispatch(addToken(newAccessToken));
                        processQueue(null, newAccessToken);
                        isRefreshing = false;
                        originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
                        return axiosClient(originalConfig);
                    } catch (refreshError) {
                        processQueue(refreshError, null);
                        isRefreshing = false;
                        console.error("Error while updating accessToken:", refreshError);
                        return Promise.reject(refreshError);
                    }
                } else {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    }).then(token => {
                        originalConfig.headers.Authorization = `Bearer ${token}`;
                        return axiosClient(originalConfig);
                    }).catch(err => {
                        return Promise.reject(err.response.data);
                    });
                }
            }
        }

        return Promise.reject(error.response.data);
    }
];

axiosClient.interceptors.request.use(config => {
    const token = tokenSelector(store.getState());
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use(...responseInterceptors);

publicAxiosClient.interceptors.response.use(...responseInterceptors);

export default axiosClient;
export { publicAxiosClient };
