import AppRoutes from "./AppRoutes";

function PageContent() {
    return (
        <div className="PageContent">
            <AppRoutes/>
        </div>
    );
}

export default PageContent;