import React, { useEffect, useState } from 'react';
import CustomTable from "../CustomTable/CustomTable";
import './GeneralStatisticsSection.css'
import { Tooltip } from 'antd';
import { formatNumber, getColorForNumber } from "../../utils";
import { fetchCurrentAccountStatistics } from '../../API/accountsService';
import { store } from '../../redux/store';
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';

const renderCell = (val) => (
    <div style={{
        color: getColorForNumber(val),
        textWrap: 'nowrap'
    }}> {formatNumber(val)} ₽ </div>
);

const renderCell2 = (val) => (
    <div style={{
        color: getColorForNumber(val),
        textWrap: 'nowrap'
    }}> ${formatNumber(val)} </div>
);

const columns = [
   {
        title: 'Профит и баланс в выбранной валюте',
        dataIndex: 'profitBalance',
        width: "100%",
        render: (text, record) => {
            let tooltipContent;
            switch (record.key) {
                case 'currentProfit':
                    tooltipContent = (
                  <div>
                    <p>Общий зафиксированный профит по всем валютам. Формула расчета:</p>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <li>+ доход с продажи активов</li>
                        <li>+ дивиденды</li>
                        <li>+ выплаты за купоны</li>
                        <li>+ сделки РЕПО</li>
                        <li>+ вариационная маржа</li>
                        <li>− убытки от продажи активов</li>
                        <li>− комиссии (операции, обслуживание, маржинальная торговля, автоследование)</li>
                        <li>− выплата НДФЛ</li>
                        <li>− вывод средств</li>
                    </ul>
                    <p>Конвертация по текущему биржевому курсу.</p>
                </div>


                    );
                    break;
                case 'portfolioValue':
                    tooltipContent = (
                        <div>
                            <p>Сумма всех активов в портфеле по всем валютам.</p>
                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                            <li>Конвертация по текущему биржевому курсу.</li>
                            </ul>
                        </div>
                    );
                    break;
                case 'earnedProfit':
                    tooltipContent = (
                        <div>
                            <p>Формула расчета:</p>
                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                <li>+ доход с продажи активов</li>
                                <li>+ дивиденды</li>
                                <li>+ выплаты за купоны</li>
                                <li>+ сделки РЕПО</li>
                                <li>+ вариационная маржа</li>
                                <li>− убытки от продажи активов</li>
                                <li>− комиссии (операции, обслуживание, маржинальная торговля, автоследование)</li>
                                <li>− выплата НДФЛ</li>
                                <li>− вывод средств</li>
                            </ul>
                            <p>Расчет отдельно по каждой из валют!</p>
                        </div>
                    );
                    break;
                case 'currentProfitIncCommissions':
                    tooltipContent = (
                        <div>
                            <p>Формула расчета:</p>
                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                <li>+ доход с продажи активов</li>
                                <li>+ дивиденды</li>
                                <li>+ выплаты за купоны</li>
                                <li>+ сделки РЕПО</li>
                                <li>+ вариационная маржа</li>
                                <li>− убытки от продажи активов</li>
                                <li>− комиссии (операции, обслуживание, маржинальная торговля, автоследование)</li>
                                <li>− выплата НДФЛ</li>
                                <li>− вывод средств</li>
                                <li>+ текущий доход/убыток по незафиксированным позициям</li>

                            </ul>
                            <p>Расчет отдельно по каждой из валют!</p>
                        </div>
                    );
                    break;
                case 'operationsCommissions':
                    tooltipContent = null;
                    break;
                default:
                    tooltipContent = null;
            }

            return (
                <span style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                    <span style={{ marginRight: 5, color: '#131026' }}>{text}</span>
                    {tooltipContent && (
                        <Tooltip overlayStyle={{ maxWidth: '600px' }} title={tooltipContent}>
                            <div style={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '18px',
                                height: '18px',
                                borderRadius: '50%',
                                backgroundColor: 'gray',
                                color: 'white',
                                fontWeight: 'bold',
                                fontStyle: 'normal',
                                cursor: 'pointer'
                            }}>
                                i
                            </div>
                        </Tooltip>
                    )}
                </span>
            );
        }
    },

    {
        title: 'RUB',
        dataIndex: 'rub',
        render: renderCell,
    },
    {
        title: 'USD',
        dataIndex: 'usd',
        render: renderCell2
    }
];

const defaultData = [
    {
        key: 'currentProfit',
        profitBalance: 'Текущий профит',
        rub: 0,
        usd: 0
    }, {
        key: 'portfolioValue',
        profitBalance: 'Стоимость портфеля',
        rub: 0,
        usd: 0
    }, {
        key: 'earnedProfit',
        profitBalance: 'Зафиксированный профит, учитывая все комиссии',
        rub: 0,
        usd: 0
    }, {
        key: 'currentProfitIncCommissions',
        profitBalance: 'Текущий профит, учитывая все комиссии и активы в портфеле',
        rub: 0,
        usd: 0
    }, {
        key: 'operationsCommissions',
        profitBalance: 'Комиссия за операции',
        rub: 0,
        usd: 0
    },

];

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

};
const GeneralStatisticsSection = () => {
    const [stats, setStats] = useState(defaultData);
    const state = store.getState();
    const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));

    useEffect(() => {
        return store.subscribe(() => {
        const state = store.getState();
        setSelectedAccount(currentTinkoffAccountSelector(state));
        });
    }, [setSelectedAccount]);

    useEffect(() => {
        fetchCurrentAccountStatistics(selectedAccount.id)
            .then((data) => {
                const newData = Object.keys(data).map((key) => {
                    return {
                        ...defaultData.find((item) => item.key === key),
                        ...data[key]
                    }
                });
                setStats(newData);
            })
            .catch(console.error);
    }, []);

    return (
        <div className='general-statistic-container'>
            <span className='general-statistic-title'>Общая статистика</span>
            <div className='scrollable-container'>
                <CustomTable data={stats} columns={columns}  />
            </div>
        </div>
    );
};

export default GeneralStatisticsSection;