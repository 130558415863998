import React from 'react';
import './index.css';
import StandardLayout from '../../Component/StandardLayout';
import Terms from './Terms';


export default function TermsConditions() {
  return (
    <StandardLayout>
    <div className="terms">
      <Terms />
    </div>
    </StandardLayout>
  );
}