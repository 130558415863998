import React, { useState } from 'react';
import { BackButton } from '../Buttons';
import './AutoInvest.css';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Header from '../PageHeader/Header';

const AutoInvest = () => {
  const [isAutoInvestEnabled, setIsAutoInvestEnabled] = useState(false);
  const [amount, setAmount] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
   
  };

  return (
    <div className="auto-invest-container">
      <div className="auto-invest-wrapper">
        <form className="auto-invest-form" onSubmit={handleSubmit}>
          <div className="auto-invest-content">
             <Header
              title="Auto-invest"
            />

            <div className="auto-invest-body">
              <button 
                type="button" 
                className="amount-button"
                onClick={() => {}}
              >
                <p className="amount">${amount.toFixed(2)}</p>
                <p className="amount-hint">Tap to edit</p>
              </button>

              <p className="amount-description">
                This amount will be automatically charged to your Rise Wallet.
              </p>

              <div className="settings-container">
                <div className="setting-item">
                  <span>Auto-Invest</span>
                  <ToggleSwitch
                    checked={isAutoInvestEnabled}
                    onChange={(e) => setIsAutoInvestEnabled(e.target.checked)}
                  />
                </div>

                <button 
                  type="button" 
                  className="frequency-button"
                  disabled={!isAutoInvestEnabled}
                >
                  <div className="frequency-content">
                    <p>Auto-invest frequency</p>
                    <p className="frequency-value"></p>
                  </div>
                  <KeyboardArrowRight />
                </button>
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={!isAutoInvestEnabled}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AutoInvest;


const KeyboardArrowRight = () => (
  <svg viewBox="0 0 24 24">
    <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z" />
  </svg>
);