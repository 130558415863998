import React, { useEffect, useState } from 'react';
import { Divider, notification } from 'antd';
import './style.css';
import { getDiarySettings, updateDiarySettings } from '../../API/diaryService';
import AmountsForm from './AmountsForm';
import StrategyTableForm from './StrategyTableForm';
import PatternTableForm from './PatternTableForm';

function DiarySettings() {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true);

    const [initialSettings, setInitialSettings] = useState({});
    const [amountsSettings, setAmountsSettings] = useState({
        totalFunds: 0,
        tradingPortfolioPct: 0.0,
        dividendPortfolioPct: 0.0,
        growthPortfolioPct: 0.0,
    });
    const [strategiesSettings, setStrategiesSettings] = useState([]);
    const [patternsSettings, setPatternsSettings] = useState([]);

    const [key, setKey] = useState(0);

    const openNotification = (isSuccess) => {
        api[isSuccess ? "success" : "error"]({
            message: isSuccess ? "Обновление настроек прошло успешно" : "Что-то пошло не так"
        });
    }

    const shareSettings = (initialSettings) => {
        setAmountsSettings({
            totalFunds: initialSettings.totalFunds,
            tradingPortfolioPct: initialSettings.tradingPortfolioPct,
            dividendPortfolioPct: initialSettings.dividendPortfolioPct,
            growthPortfolioPct: initialSettings.growthPortfolioPct,
        });
        setStrategiesSettings(initialSettings.strategies);
        setPatternsSettings(initialSettings.patterns);
    };

    const handleFormSubmitPart = (settings) => {
        setIsLoading(true);
        const updatedSettings = { ...initialSettings, ...settings };
        updateDiarySettings(updatedSettings)
            .then(() => {
                openNotification(true);
                setInitialSettings(updatedSettings);
                shareSettings(updatedSettings);
            })
            .catch((error) => {
                openNotification(false);
                console.error(error);
            })
            .finally(() => setIsLoading(false));
    };

    const handleAmountsFormSubmit = () => handleFormSubmitPart(amountsSettings);

    const handleStrategiesFormSubmit = (strategies) => handleFormSubmitPart({ strategies });

    const handlePatternsFormSubmit = (patterns) => handleFormSubmitPart({ patterns });

    useEffect(() => {
        getDiarySettings()
            .then((response) => {
                setInitialSettings(response);
                shareSettings(response);
                setKey(key + 1);
            }).catch((error) => {
                console.error(error);
            }).finally(() => setIsLoading(false));
    }, []);


    return (
        <div className='diary-settings'>
            {contextHolder}
            <h1 className='title'>Настройки дневника</h1>
            <AmountsForm
                key={key}
                data={amountsSettings}
                onChange={setAmountsSettings}
                onSubmit={handleAmountsFormSubmit}
                loading={isLoading}
            />

            <Divider />
            
            <StrategyTableForm 
                loading={isLoading}
                strategies={strategiesSettings} 
                onStrategiesChange={handleStrategiesFormSubmit}
            />
            
            <PatternTableForm 
                loading={isLoading}
                patterns={patternsSettings}
                onPatternsChange={handlePatternsFormSubmit}
            />
        </div>
    );
}

export default DiarySettings;