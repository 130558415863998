
import {Layout, notification} from "antd";
import SettingsPage from "../Component/SettingsPage/SettingsPage";
import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Settings() {
    const [api, contextHolder] = notification.useNotification();
    const location = useLocation();
    const show = useRef(false);

    useEffect(() => {
        if (location.state?.error && !show.current) {
            api["error"]({ message: location.state.error });
            show.current = true;
        }
    }, [api]);
    
    return (
        <Layout size={10} align='start' direction="vertical" style={{width: '98%', padding: '1px 16px 16px 16px',backgroundColor:'white',marginInline:10}}>
            {contextHolder}
            <SettingsPage/>
        </Layout>
    );
}

export default Settings;