import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils";
import _ from "lodash";

export const directionRenderer = (onChange) => (direction, record) => {
    const items = [
        { key: 'NEUTRAL', label: 'NEUTRAL' },
        { key: 'LONG', label: 'LONG' },
        { key: 'SHORT', label: 'SHORT' }
    ];

    const handleMenuClick = (e) => {
        if (e.key !== direction)
            onChange(record.key, e.key);
    };

    const menuProps = {
        items,
        onClick: handleMenuClick
    };

    let modifierClass = '';
    if (direction === 'LONG') modifierClass = "dropdown--green";
    else if (direction === 'SHORT') modifierClass = "dropdown--red";

    return (
        <Dropdown menu={menuProps} className={`dropdown ${modifierClass}`}>
            <Button size="small">
                <Space >
                    {direction.toUpperCase()}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};

export const dateRenderer = (stringDate) => {
    if (_.isEmpty(stringDate))
        return '-';
    const date = new Date(stringDate);
    return dayjs.utc(date).format('DD.MM.YYYY');
}

export const linkRenderer = (link) => {
    return (
        <a href={link} className="table-link" target="_blank" rel="noreferrer">{link}</a>
    );
};

export const resultCellRenderer = (value) => {
    const obj = {
        children: moneyRenderer(value),
        props: {},
    };
    if (!_.isNil(value))
        obj.props.className = parseFloat(value) > 0 ? 'cell--green' : 'cell--red';
    return obj;
};

export const factorRenderer = (value) => {
    const obj = {
        children: value.toFixed(1),
        props: {},
    };
    let classname = "";
    if (value < 1.2) classname = 'cell--gray';
    else if (value >= 2 && value <= 3) classname = 'cell--green';
    else if (value > 3) classname = 'cell--green-2';
    obj.props.className = classname;
    return obj;
};

export const accuracyRenderer = (value) => {
    const obj = {
        children: value.toFixed(0) + '%',
        props: {},
    };
    obj.props.className = parseFloat(value) > 55 ? 'cell--green' : 'cell--red';
    return obj;
};

export const statusRenderer = (onChange) => (status, record) => {
    const items = [
        { key: 'OPEN', label: 'Открыта' },
        { key: 'CLOSE', label: 'Закрыта' }
    ];

    const handleMenuClick = (e) => {
        if (e.key !== status)
            onChange(record.key, e.key);
    };

    const menuProps = {
        items,
        onClick: handleMenuClick
    }

    return (
        <Dropdown menu={menuProps} className={`dropdown ${status === 'OPEN' ? 'dropdown--green' : ''}`}>
            <Button size="small">
                <Space >
                    {status === "OPEN" ? "Открыта" : "Закрыта"}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}    

export const moneyRenderer = (value) => {
    return (
        <span style={{ textWrap: "nowrap" }}>{
            _.isNil(value) ? '-' : formatCurrency(value)
        } ₽</span>
    );
}

// options = [string, string, ...]
export const selectRenderer = (options, onChange) => (value, record) => {
    const items = options.map(option => ({ key: option, label: option }));
    const handleMenuClick = (e) => {
        if (e.key !== value)
            onChange(record.key, e.key);
    };

    const menuProps = {
        items,
        onClick: handleMenuClick
    };

    return (
        <Dropdown menu={menuProps} className="dropdown">
            <Button size="small">
                <Space >
                    {
                        items.length === 0
                            ? <span className="dropdown-empty">Нет данных</span>
                            : items.find(item => item.key === value)?.label
                    }
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}