import React, { useState } from 'react';
import { Typography, Input, Button, Flex } from 'antd';
import EditableTable from '../../Component/EditableTable';


const { Title } = Typography;

const defaultColumns = [
    {
        title: '#',
        dataIndex: 'id',
    },
    {
        title: 'Название Стратегия',
        dataIndex: 'strategy',
        editable: true,
    },
    {
        title: 'Действия',
        dataIndex: 'actions',
        width: '20%',
    },
];

function StrategyTableForm({ loading, strategies, onStrategiesChange }) {
    const [value, setValue] = useState('');

    const handleSave = (record, values) => {
        const newStrategies = strategies.map(item => {
            if (record.strategy === item) {
                return values.strategy;
            }
            return item;
        });
        onStrategiesChange(newStrategies);
    }

    const handleAddStrategy = () => {
        if (!value) return;
        const newStrategies = [...strategies, value];
        onStrategiesChange(newStrategies);
        setValue('');
    }


    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    columns[2].render = (text, record) => (
        <Button type="text" danger onClick={() => {
            const newStrategies = strategies.filter((strategy, index) => index !== record.key);
            onStrategiesChange(newStrategies);
        }}>Удалить</Button>
    );

    const dataSource = strategies.map((strategy, index) => ({
        key: index,
        id: index + 1,
        strategy,
    }));

    return (
        <Flex gap="large" vertical className='table-form'>
            <Title level={3}>Создать стратегию</Title>
            <Input
                placeholder="Введите текст"
                value={value}
                onChange={e => setValue(e.target.value)}
                onPressEnter={handleAddStrategy}
                disabled={loading || false}
                className='table-form-input'
            />
            <EditableTable
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={false}
                loading={loading}
            />
        </Flex>
    );
}

export default StrategyTableForm;