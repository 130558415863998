import React from 'react';
import './TransferPage.css';
import MoneyIcon from '../Icons/MoneyIcon';
import UserIcon from '../Icons/UserIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const TransferPage = () => {
  return (
    <div className="transfer-page">
      <Header
        title="Transfer"
        
      />
      
      <div className="transfer-options">
        <a className="transfer-option" href="transfer-wallet/send-money">
          <div className="option-content">
            <div className="icon-container">
              <MoneyIcon />
            </div>
            <div className="option-text">
              <span className="option-title">Send To Plan</span>
              <span className="option-description">Fund one of your investment plans.</span>
            </div>
          </div>
          <ChevronRightIcon />
        </a>
        
        <a className="transfer-option" href="transfer-wallet/send-money-user">
          <div className="option-content">
            <div className="icon-container">
              <UserIcon />
            </div>
            <div className="option-text">
              <span className="option-title">Send To Rise User</span>
              <span className="option-description">Send to a friend on Rise.</span>
            </div>
          </div>
          <ChevronRightIcon />
        </a>
      </div>
    </div>
  );
};

export default TransferPage;