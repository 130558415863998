import React from 'react';

export const NigerianFlag = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7462_152519)">
      <path d="M12.0005 24C18.6279 24 24.0005 18.6274 24.0005 12C24.0005 5.37258 18.6279 0 12.0005 0C5.37307 0 0.000488281 5.37258 0.000488281 12C0.000488281 18.6274 5.37307 24 12.0005 24Z" fill="#F0F0F0"/>
      <path d="M0 12C0 17.1596 3.2565 21.5581 7.82611 23.2537V0.74646C3.2565 2.44193 0 6.84049 0 12Z" fill="#6DA544"/>
      <path d="M23.9999 12C23.9999 6.84049 20.7434 2.44193 16.1738 0.74646V23.2537C20.7434 21.5581 23.9999 17.1596 23.9999 12Z" fill="#6DA544"/>
    </g>
    <defs>
      <clipPath id="clip0_7462_152519">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const USFlag = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7251_144357)">
      <path d="M11.9998 24C18.6272 24 23.9998 18.6274 23.9998 12C23.9998 5.37258 18.6272 0 11.9998 0C5.37234 0 -0.000244141 5.37258 -0.000244141 12C-0.000244141 18.6274 5.37234 24 11.9998 24Z" fill="#F0F0F0"/>
      <path d="M11.4783 12.0015H24C24 10.9185 23.8556 9.8692 23.5866 8.87109H11.4783V12.0015Z" fill="#D80027"/>
      <path d="M11.4783 5.73983H22.2387C21.5041 4.54114 20.5649 3.48162 19.4698 2.60938H11.4783V5.73983Z" fill="#D80027"/>
      <path d="M11.9998 24.0013C14.824 24.0013 17.4198 23.0251 19.4697 21.3926H4.53003C6.57987 23.0251 9.17567 24.0013 11.9998 24.0013Z" fill="#D80027"/>
      <path d="M1.76122 18.2613H22.2387C22.8284 17.299 23.2857 16.2471 23.5865 15.1309H0.41333C0.714127 16.2471 1.17149 17.299 1.76122 18.2613Z" fill="#D80027"/>
      <path d="M5.55838 1.87397H6.65193L5.63474 2.61295L6.02329 3.80869L5.00615 3.0697L3.98901 3.80869L4.32463 2.7757C3.42904 3.52172 2.64407 4.39575 1.99719 5.36963H2.34758L1.7001 5.84002C1.59922 6.0083 1.50247 6.17925 1.40976 6.35273L1.71894 7.30434L1.1421 6.88523C0.998709 7.18903 0.867553 7.49967 0.749662 7.81678L1.0903 8.86528H2.34758L1.3304 9.60427L1.71894 10.8L0.701803 10.061L0.0925215 10.5037C0.0315371 10.9939 -0.000244141 11.4932 -0.000244141 12H11.9998C11.9998 5.37262 11.9998 4.59131 11.9998 0C9.62919 0 7.41937 0.687656 5.55838 1.87397Z" fill="#0052B4"/>
    </g>
  </svg>
);