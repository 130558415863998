import React, { useState } from 'react';
import './WelcomePageLeftSide.css';
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

const WelcomePageLeftSide = ({ onRegisterClick }) => {
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setChecked((prevChecked) => !prevChecked);
    };

    const redirectToLogin = () => {
        const loginPath = '/login'; 
        navigate(loginPath);
    };

    return (
        <div className='welcome-container'>
            <div className='welcome-title-container'>
                <span className='welcome-title'>СЕРВИС ДЛЯ УЧЕТА ИНВЕСТИЦИЙ</span>
            </div>
            <div style={{display:"flex", flex:1}}>
                <div className='welcome-card2'>
                    <div className='welcome-title-container'>
                        <span className='welcome-title2'>РЕГИСТРАЦИЯ</span>
                    </div>
                    <span className='welcome-card-text1'>
                        После регистрации вам будет бесплатно подключен тариф <span style={{color:"#42CC7D"}}>«Трейдер»</span> с пробным периодом в 7 дней. Подробнее о действующих тарифах читайте <span style={{color:"#2475FE"}}>здесь</span>.
                    </span>
                    <div className='welcome-card-text2-container'>
                        <div onClick={handleClick}>
                            {checked ? <CheckSquareOutlined /> : <BorderOutlined />}
                        </div>
                        <span className='welcome-card-text2'>
                            Вы подтверждаете, что прочитали и согласны с условиями <span style={{color:"#2475FE"}}>пользовательского соглашения</span> и <span style={{color:"#2475FE"}}>конфиденциальности</span>.
                        </span>
                    </div>
                    <button 
                        onClick={onRegisterClick} 
                        className={`welcome-card-button ${!checked ? 'disabled' : ''}`} 
                        disabled={!checked}
                    >
                        <span className='welcome-card-button-text'>Продолжить регистрацию</span>
                    </button>
                    <span className='welcome-card-bottom-text'>
                        Вернуться в <span style={{ color: "#2475FE", cursor: "pointer" }} onClick={redirectToLogin}>форму авторизации</span>.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WelcomePageLeftSide;
