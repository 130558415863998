import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BankSelector.css';
import SearchIcon from '../Buttons/SearchIcon';
import Header from '../PageHeader/Header';

const BankSelector = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  
  const banks = [
    "9mobile 9Payment Service Bank", "Abbey Mortgage Bank", "Above Only MFB", 
    "Abulesoro MFB", "Access Bank", "Access Bank (Diamond)", 
    "Accion Microfinance Bank", "Aella MFB", "AG Mortgage Bank",
    "Wema Bank", "Yes MFB", "Zenith Bank"
  ];

  const filteredBanks = banks.filter(bank => 
    bank.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBankSelection = (bank) => {
    navigate('account-details', { 
      state: { 
        selectedBank: bank 
      }
    });
  };

  return (
    <div className="bank-selector">
      <div className="bank-selector__container">
        
        <Header
        title="Select Bank"
        
        />

        <div className="bank-selector__search">
          <div className="bank-selector__search-wrapper">
            <SearchIcon className="bank-selector__search-icon" />
            <input
              type="text"
              className="bank-selector__search-input"
              placeholder="Search banks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="bank-selector__list">
          {filteredBanks.map((bank, index) => (
            <button
              key={index}
              className="bank-selector__list-item"
              type="button"
              onClick={() => handleBankSelection(bank)}
            >
              {bank}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BankSelector;