import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FundWallet.css';
import FundingInfoModal from '../FundingInfoModal/FundingInfoModal';
import { BackButton } from '../Buttons';
import BankIcon from '../Icons/BankIcon';
import CardIcon from '../Icons/CardIcon';
import ChevronRight from '../Icons/ChevronRightIcon';
import Header from '../PageHeader/Header';

const FundWallet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isUSDDetails = location.pathname.includes('usd-details');
  const [showModal, setShowModal] = useState(false);

  const fundingMethods = [
    {
      id: 'bank-transfer',
      title: 'Bank Transfer',
      description: 'Transfer to your wallet account number.',
      icon: <BankIcon />
    },
    {
      id: 'card',
      title: 'Debit / Credit card',
      description: 'Fund with Naira cards',
      icon: <CardIcon />
    }
  ];
    
  const fundingUSD = [
    {
      id: 'card',
      title: 'Debit / Credit card',
      description: 'Fund with Naira cards',
      icon: <CardIcon />
    }
  ];

  const handleMethodSelect = (methodId) => {
    if (methodId === 'bank-transfer') {
      navigate(`${location.pathname}/bank-account`);
    } else if (methodId === 'card') {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalContinue = () => {
    setShowModal(false);
    navigate(`${location.pathname}/card-payment`);
  };

  return (
    <div className="fund-wallet">
      <Header
        title="Fund Wallet"
        
      />

      <div className="funding-methods">
        {(isUSDDetails ? fundingUSD : fundingMethods).map((method) => (
          <div key={method.id} className="funding-method">
            <div className="funding-method__content">
              <div className="funding-method__icon">
                {method.icon}
              </div>
              <div className="funding-method__info">
                <span className="funding-method__title">{method.title}</span>
                <span className="funding-method__description">{method.description}</span>
              </div>
            </div>
            <div className="funding-method__arrow">
              <ChevronRight />
            </div>
            <button onClick={() => handleMethodSelect(method.id)}>
              <span className="sr-only">Use {method.title}</span>
            </button>
          </div>
        ))}
      </div>

      {showModal && (
        <FundingInfoModal
          onClose={handleModalClose}
          onContinue={handleModalContinue}
        />
      )}
    </div>
  );
};

export default FundWallet;