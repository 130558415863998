import {Layout} from "antd";
import MyPortfolioSection from "../Component/MyPortfolio/MyPortfolioSection";
import CurrenciesSection from "../Component/CurrenciesSection/CurrenciesSection";
import StocksinPortfolio from "../Component/StocksinPortfolioSection/StocksinPortfolio";
import GeneralStatisticsSection from "../Component/GeneralStatisticsSection/GeneralStatisticsSection";
import AnalyticsSection from "../Component/AnalyticsSection/AnalyticsSection";


function Dashboard() {
    return (
        <Layout size={10} align='start' direction="vertical"
                style={{backgroundColor: 'white', marginInline: 10}}>
            <MyPortfolioSection/>
            <StocksinPortfolio/>
            <CurrenciesSection/>
            <AnalyticsSection/>
            <GeneralStatisticsSection/>
        </Layout>
    );
}


export default Dashboard;