import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreVertical } from 'lucide-react';
import './PageHeader.css';
import BackArrowIcon from '../Icons/BackArrowIcon';
import { Button } from 'antd';

const PageHeader = ({ 
  title, 
  subtitle, 
  showOptions = false,
  className = '',
  titleClassName = '',
  backgroundImage = ''
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
      navigate(-1);
  };

  const headerStyle = backgroundImage ? {
    backgroundImage: `url(${backgroundImage})`,
  } : {};

  return (
    <header 
      className={`page-header ${backgroundImage ? 'with-background' : ''} ${className}`}
      style={headerStyle}
    >
      <Button 
        type="text"
        className="back-button"
        onClick={handleBack}
      >
        <BackArrowIcon />
        <span className="sr-only">Go back</span>
      </Button>

      <div className="title-container">
        <h2 className={`page-title ${titleClassName}`}>
          {title}
        </h2>
        {subtitle && (
          <p className="subtitle">{subtitle}</p>
        )}
      </div>

      {showOptions && (
        <Button 
          type="text"
          className="options-button"
        >
          <MoreVertical />
          <span className="sr-only">Open options menu</span>
        </Button>
      )}
    </header>
  );
};

export default PageHeader;