import React from 'react';

const DonutChart = ({ className }) => {
  return (
    <svg 
      width="260" 
      height="183" 
      className={className || 'donut-chart'}
    >
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2"/>
          <feOffset dx="5" dy="5"/>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.15"/>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <circle 
        cx="130" 
        cy="91" 
        r="81"
        stroke="#BC1058"
        strokeWidth="28"
        fill="none"
        filter="url(#shadow)"
      />
    </svg>
  );
};

export default DonutChart;