import React from 'react';
import './Faq.css';
import Header from '../PageHeader/Header';
import FaqItem from '../FaqItem/FaqItem';

const Faq = () => {
  const faqs = [
    {
      id: 'faq-participation',
      question: "How can I participate in the referral program?",
      answer: "To participate in the referral program, you need to be fully verified on Rise and have at least $50 invested in a Rise plan."
    },
    {
      id: 'faq-commission-rate',
      question: "What is the referral commission rate on Rise?",
      answer: "The referral commission rate on Rise is 3%. This means that when you refer someone and they create and fund an investment plan on Rise, you earn 3% of their investment amount, which will run each time they top up their plan for an an entire year."
    },
    {
      id: 'faq-withdrawal',
      question: "Are my referral commissions immediately available for me to withdraw?",
      answer: "No, your commissions are not immediately available for withdrawal, as the referred person MUST stay invested for a period of 3 months."
    },
    {
      id: 'faq-early-withdrawal',
      question: "What happens if my referral withdraws their investment early?",
      answer: "If your referral withdraws their investment in less than 3 months, your commission will be lost."
    }
  ];

  return (
    <div className="faq-container">
      <Header
        title="Referrals FAQs"
      />
      <ul className="faq-list">
        {faqs.map((faq) => (
          <FaqItem
            key={faq.id}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </ul>
    </div>
  );
};

export default Faq;