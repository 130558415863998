import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardContent from '../TrackrAppDashboard/DashboardContent';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import MorePage from '../MorePage/MorePage';
import AffiliateReferrals from '../Referrals/AffiliateReferrals';
import Faq from '../Faq/Faq';
import ReferralForm from '../ReferralForm/ReferralForm';
import ArticleDetails from '../ArticleDetails/ArticleDetails';

const MoreRoute = () => {
  return (
    <Routes>
      <Route index element={<MorePage />} />
      <Route path=":id" element={<ArticleDetails />} />
      <Route path="notifications" element={<NotificationsPage />} />
      <Route path="refer" element={<AffiliateReferrals />} />
       <Route path="refer/faq" element={<Faq />} />
       <Route path="refer/referral" element={<ReferralForm />} />
    </Routes>
  );
};

export default MoreRoute;