// utils/chartUtils.js
export const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);
};

export const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
};

export const calculateScales = (data, dimensions) => {
  const { width, height, margin } = dimensions;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xValues = data.map(d => new Date(d.x).getTime());
  const yValues = data.map(d => d.y);

  const xScale = (x) =>
    ((x - Math.min(...xValues)) / (Math.max(...xValues) - Math.min(...xValues))) * chartWidth;

  const yScale = (y) =>
    chartHeight - ((y - Math.min(...yValues)) / (Math.max(...yValues) - Math.min(...yValues))) * chartHeight;

  return { xScale, yScale };
};

export const createPath = (data, dimensions, scales) => {
  const { xScale, yScale } = scales;
  const { height, margin } = dimensions;

  const points = data.map(d => ({
    x: xScale(new Date(d.x).getTime()),
    y: yScale(d.y),
  }));

  const line = points
    .map((point, i) => (i === 0 ? `M ${point.x},${point.y}` : `L ${point.x},${point.y}`))
    .join(' ');

  const area = `${line} L ${points[points.length - 1].x},${height - margin.bottom} L ${
    points[0].x
  },${height - margin.bottom} Z`;

  return area;
};

export const calculateProjection = (initialAmount, monthlyContribution, annualReturn, years) => {
  const monthlyReturn = annualReturn / 12;
  const points = [];
  let currentValue = initialAmount;
  let currentDate = new Date();

  for (let month = 0; month <= years * 12; month++) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() + month);

    currentValue = currentValue * (1 + monthlyReturn) + monthlyContribution;
    points.push({
      x: date.toISOString(),
      y: currentValue,
      date,
    });
  }

  return points;
};