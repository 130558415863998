import DownloadAppButton from "../../Component/Buttons/DownloadAppButton";
import StartInvestButton from "../../Component/Buttons/StartInvestButton";

export default function Hero() {
    return (
        <section className="hero">
            <div className="hero-container container wide">
                <div>
                    <div className="hero-content">
                        <h1 className="hero__title">
                           Making wealth <br/> creation and <br/> management <br/> easier for africans
                        </h1>
                        <h1 className="hero__mobile">
                           Making wealth creation and <br/> management easier for africans
                        </h1>

                        <div className="hero__actions">
                            <StartInvestButton />
                            <DownloadAppButton />
                        </div>
                    </div>
                    {/* <div className="hero-partners">
                        
                    </div> */}
                </div>
                <div></div>
            </div>
            <div className="hero-img">
                <div className="hero-img__container">
                    <div className="hero-img__ball-1">
                        <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                            <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                                <img
                                    style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }}
                                    alt=""
                                    aria-hidden="true"
                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271080%27%20height=%271080%27/%3e"
                                />
                            </span>
                            <img
                                alt=""
                                src="https://risevest.com/_next/static/media/ball.d87b5b9d.webp"
                                decoding="async"
                                className="hero-img__ball-img"
                                data-nimg="intrinsic"
                                style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
                            />
                        </span>
                    </div>
                    <div className="hero-img__ball-2">
                        <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                            <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                                <img
                                    style={{
                                        display: "block",
                                        maxWidth: "100%",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0
                                    }}
                                    alt=""
                                    aria-hidden="true"
                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271080%27%20height=%271080%27/%3e"
                                />
                            </span>
                            <img
                                alt=""
                                src="https://risevest.com/_next/static/media/ball.d87b5b9d.webp"
                                decoding="async"
                                data-nimg="intrinsic"
                                className="hero-img__ball-img"
                                style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
                            />
                        </span>
                    </div>
                    <span className="hero-img__hand-container" style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                        <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                            <img
                                style={{
                                    display: "block",
                                    maxWidth: "100%",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    // border: 10,
                                    borderRadius:20,
                                    margin: 0,
                                    padding: 0
                                }}
                                alt=""
                                aria-hidden="true"
                                src="/tradetrackr/uvid.PNG"
                            />
                        </span>
                        <img
                            alt=""
                            src="/tradetrackr/uvid.PNG"
                            decoding="async"
                            data-nimg="intrinsic"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                // border: 20,
                                borderRadius:25,
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: "100%",
                                maxHeight: "100%"
                            }}
                        />
                    </span>
                </div>
            </div>

        </section>
    );
}

function ArrowRightIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="new-arrow-right"
        >
            <path
                d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                fill="currentColor"
            />
        </svg>
    );
}

function AppleLogo() {
    return (
        <svg width="1.5em" height="1.5625em" viewBox="0 0 24 25" fill="none">
            <g clipPath="url(#apple-logo-clip-path)" fill="currentColor">
                <path d="M16.499.5c-1.28.088-2.775.907-3.646 1.974-.796.967-1.45 2.404-1.194 3.801 1.397.043 2.842-.795 3.679-1.88.783-1.009 1.376-2.437 1.161-3.895z" />
                <path d="M21.555 8.552c-1.228-1.54-2.955-2.435-4.585-2.435-2.153 0-3.063 1.03-4.559 1.03-1.542 0-2.713-1.027-4.575-1.027-1.828 0-3.775 1.118-5.01 3.029-1.735 2.69-1.438 7.75 1.374 12.06 1.007 1.542 2.35 3.276 4.109 3.29 1.564.016 2.005-1.003 4.125-1.013 2.12-.012 2.521 1.027 4.083 1.01 1.76-.013 3.177-1.934 4.183-3.476.722-1.106.99-1.662 1.55-2.91-4.07-1.55-4.722-7.337-.695-9.558z" />
            </g>
            <defs>
                <clipPath id="apple-logo-clip-path">
                    <path fill="currentColor" transform="translate(0 .5)" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

function PlayStoreLogo(props) {
    return (
        <svg
            width="1.5em"
            height="1.5625em"
            viewBox="0 0 24 25"
            fill="none"
            {...props}
        >
            <g clipPath="url(#play-store-logo-clip-path)" fill="currentColor">
                <path d="M4.67.878A2.353 2.353 0 002.85.563l10.825 10.825 3.508-3.508L4.67.878zM1.507 1.443a2.352 2.352 0 00-.476 1.415v19.283c0 .521.174 1.014.476 1.415L12.563 12.5 1.507 1.443zM21.762 10.441L18.61 8.678 14.787 12.5l3.822 3.822 3.154-1.764a2.331 2.331 0 001.206-2.058 2.33 2.33 0 00-1.207-2.059zM13.675 13.612L2.851 24.436a2.356 2.356 0 001.819-.314l12.514-7.002-3.509-3.508z" />
            </g>
            <defs>
                <clipPath id="play-store-logo-clip-path">
                    <path
                        fill="currentColor"
                        transform="translate(0 .5)"
                        d="M0 0H24V24H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}