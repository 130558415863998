import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  tinkoffAccounts: [],
  currentTinkoffAccount: null,
  existTinkoffToken: false
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    addTinkoffAccounts: (state, action) => {
      const newState = { ...state };
      const payload = action.payload;
      if (_.isArray(payload)) {
        newState.tinkoffAccounts = payload;
        const checkedAccounts = payload.filter(account => account.isChecked);
        if (checkedAccounts.length > 0) {
          newState.currentTinkoffAccount = payload[0];
        } else {
          newState.currentTinkoffAccount = null;
        }
      }
      return newState;
    },
    setCurrentTinkoffAccount: (state, action) => {
      return { ...state, currentTinkoffAccount: action.payload }
    },
    setExistTinkoffToken: (state, action) => {
      return { ...state, existTinkoffToken: action.payload }
    },
  },
});

export const { addTinkoffAccounts, setCurrentTinkoffAccount, setExistTinkoffToken } = commonSlice.actions;

export const tinkoffAccountsSelector = (state) => _.get(state, 'common.tinkoffAccounts', []);
export const currentTinkoffAccountSelector = (state) => _.get(state, 'common.currentTinkoffAccount', null);
export const existTinkoffTokenSelector = (state) => _.get(state, 'common.existTinkoffToken', false);

export default commonSlice.reducer;