import React from 'react';

const FixedIncomeIcon = ({ className }) => {
  return (
    <svg 
      width="14" 
      height="13" 
      viewBox="0 0 14 13" 
      fill="currentColor"
      className={className}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.764 0c.27 0 .488.218.488.488v.204a2.764 2.764 0 012.274 2.72v.65a.488.488 0 11-.975 0v-.65c0-.818-.55-1.508-1.299-1.72v4.502l.54.264a3.088 3.088 0 011.734 2.775v.341c0 1.359-.98 2.49-2.274 2.72v.218a.488.488 0 01-.975 0v-.217A2.764 2.764 0 010 9.575v-.638a.488.488 0 01.975 0v.637c0 .82.551 1.51 1.302 1.721V6.804l-.542-.264A3.088 3.088 0 010 3.764v-.352C0 2.052.983.92 2.277.692V.487c0-.27.218-.488.487-.488zM.975 3.412c0-.82.55-1.51 1.302-1.721v4.028l-.115-.056a2.113 2.113 0 01-1.187-1.9v-.351zm2.277 7.883V7.279l.112.055a2.113 2.113 0 011.187 1.9v.34c0 .818-.549 1.508-1.299 1.72z" />
      <path d="M8.127 6.5c0-.11.002-.218.007-.326h1.13a.488.488 0 000-.974H8.245c.16-.86.475-1.62.89-2.214.613-.88 1.414-1.362 2.243-1.362.303 0 .632.088.87.244a.488.488 0 00.536-.814A2.62 2.62 0 0011.378.65c-1.236 0-2.305.719-3.044 1.78-.53.76-.907 1.714-1.078 2.77h-.917a.488.488 0 000 .974h.82a8.301 8.301 0 000 .652h-.82a.488.488 0 100 .975h.917c.171 1.057.548 2.011 1.078 2.772.74 1.06 1.808 1.78 3.044 1.78.452 0 .986-.11 1.418-.41a.488.488 0 00-.556-.801c-.223.155-.545.235-.862.235-.829 0-1.63-.481-2.244-1.362C8.72 9.42 8.406 8.66 8.245 7.8h1.019a.488.488 0 100-.975h-1.13a7.325 7.325 0 01-.007-.325z" />
    </svg>
  );
};

export default FixedIncomeIcon;