import React, { useState } from 'react';
import './ReferralForm.css';
import Header from '../PageHeader/Header';
import ErrorIcon from '../Icons/ErrorIcon';
import InputWrapper from '../InputWrapper/InputWrapper';
import Button from '../Buttons/Button/Button';


const ReferralForm = () => {
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  const isFormValid = referralCode.trim().length > 0;

  return (
    <div className="referral-container">
      <div className="referral-header">
        <Header
          title="Affiliate & Referrals"
        />
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <p className="form-description">
            Please, type or paste your friend's referral code.
          </p>

          <div className="form-field">
            <InputWrapper
              id="referralCode"
              label="Referral Code"
              value={referralCode}
              onChange={handleReferralCodeChange}
            />
          </div>

          {error && (
            <div className="error-alert" role="alert">
              <ErrorIcon />
              <p>{error}</p>
            </div>
          )}

          <Button
            type="submit"
            disabled={!isFormValid}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ReferralForm;