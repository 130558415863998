import AppleLogo from "../Icons/AppleLogo";
import PlayStoreLogo from "../Icons/PlayStoreLogo";

export default function DownloadAppButton({ className = "" }) {
    return (
        <button className={`da-button ${className}`}>
            <span className="da-button__icon">
                <AppleLogo />
                <span className="da-button__icon-sep" />
                <PlayStoreLogo />
            </span>
            <span className="da-button__text">Download app</span>
        </button>
    );
}