import React from 'react';
import './ConfirmationModal.css';
import { CancelButton, CloseButton, ContinueButton } from '../Buttons';

const ConfirmationModal = ({ onClose, onConfirm }) => {
    
    return (
        <div 
            className="confirmation-modal"
            data-test-id="confirmation-modal"
            tabIndex="-1"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <CloseButton onClick={onClose} />
                    <h2>Confirm creation</h2>
                </div>

                <div className="modal-body">
                    <p>Stocks are volatile and your investments may sometimes fall below the amount you invested.</p>
                    <p>Do you still wish to continue?</p>
                </div>

                <div className="modal-actions">
                   <ContinueButton onClick={onConfirm} text="Proceed with Payment" />
                   <CancelButton onClick={onClose} text="Back to Cart" />
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;