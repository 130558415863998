import React from 'react';
import './FundingInfoModal.css';
import CloseIcon from '../Icons/CloseIcon';

const FundingInfoModal = ({ onClose, onContinue }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <button className="modal__close" onClick={onClose}>
              <span className="sr-only">Close</span>
              <CloseIcon />
            </button>
            <h2>Funding via debit / credit card</h2>
          </div>

          <div className="modal__body">
            <p>
              <span>Duration</span>
              <span>Instant</span>
            </p>
            <p>
              <span>Funding limit</span>
              <span>₦500,000 / transaction</span>
            </p>
          </div>

          <button className="modal__button" onClick={onContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default FundingInfoModal;