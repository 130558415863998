import React from 'react';

const AddIconMain = () => (
  <svg 
    className="add-icon" 
    focusable="false" 
    aria-hidden="true" 
    viewBox="0 0 24 24"
  >
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
  </svg>
);

export default AddIconMain;