import React from 'react'
import { CtaButton } from '../../Component/Buttons';

const articles = [
  {
    category: "Investment",
    title: "The Psychology of Greed: Why People Pursue Money at Any Cost",
    image: "https://money.rise.capital/wp-content/uploads/2024/08/web-1-2.png"
  },
  {
    category: "Rise News",
    title: "Risevest July Portfolio Report",
    image: "https://money.rise.capital/wp-content/uploads/2024/08/web-1-1.png"
  },
  {
    category: "Recommended",
    title: "Can You Win the House? A Careful Look at Nigeria's Sports Betting Subculture",
    image: "https://money.rise.capital/wp-content/uploads/2024/08/Sports-betting.png"
  }
];

function LastNewsSection() {
  return (
    <section className="news container wide">
      <div className="news__show">
        <h2 className="news__title">
          Get smarter about <br /> your money.
        </h2 >
        <p className="news__description">
          The latest news, market insight and everything you need to know about
          managing your money.
        </p>
        <CtaButton className="news__cta news__cta--des">Read the blog</CtaButton>
      </div>
      <div className="news__links">
        {
          articles.map((item => (
            <a
              className="news__links-item"
              href="/blog/the-psychology-of-greed-and-money"
            >
              <span
                className="img"
                style={{
                  backgroundImage: `url("${item.image}")`
                }}
              />
              <div className='title'>
                <span className="title__category">{item.category}</span>
                <span className="title__title">
                  {item.title}
                </span>
              </div>
              <span className="flex-center">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="arrow transition"
                >
                  <path
                    d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </a>
          )))
        }
      </div>
      <CtaButton className="news__cta news__cta--mob">Read the blog</CtaButton>
    </section>
  );
}

export default LastNewsSection;