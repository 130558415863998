import React, { useState } from 'react';
import './Slider.css';

const Slider = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

      const handleDotClick = (index) => {
        setCurrentSlide(index);
    };



    return (
        <div className="slider-container">

            <div className="pagination">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={currentSlide === index ? 'dot active' : 'dot'}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
            <div className="slides">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt}
                        className={currentSlide === index ? 'active' : ''}
                    />
                ))}
            </div>

        </div>
    );
};

export default Slider;
