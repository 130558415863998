import { useState, useEffect,useCallback } from 'react';

export function useChartDimensions(chartRef) {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    margin: { top: 20, right: 20, bottom: 40, left: 40 },
  });

  useEffect(() => {
    if (chartRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        const { width, height } = entries[0].contentRect;
        setDimensions(prev => ({
          ...prev,
          width,
          height,
        }));
      });

      resizeObserver.observe(chartRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [chartRef]);

  return dimensions;
}



export function useTooltip() {
  const [tooltip, setTooltip] = useState(null);

  const handleMouseMove = useCallback((event, data) => {
    const svgElement = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - svgElement.left;
    const y = event.clientY - svgElement.top;

    setTooltip({
      date: new Date().toISOString(),
      investment: 0,
      returns: 0,
      x,
      y,
    });
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltip(null);
  }, []);

  return { tooltip, handleMouseMove, handleMouseLeave };
}