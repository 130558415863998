import React from 'react';

const RemoveIcon = ({ className }) => (
  <svg 
    focusable="false" 
    aria-hidden="true" 
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M19 13H5v-2h14z" />
  </svg>
);

export default RemoveIcon;