import React from 'react';

const BackArrowIcon = ({ className }) => {
  return (
    <svg 
      viewBox="0 0 24 24" 
      className={className || 'back-arrow-icon'}
    >
      <path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
    </svg>
  );
};

export default BackArrowIcon;