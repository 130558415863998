import React, { useState } from 'react';
import './WithdrawWallet.css';
import { BackButton } from '../Buttons';
import TransferArrow from '../Icons/TransferArrow';
import Header from '../PageHeader/Header';

const WithdrawWallet = () => {
  const [amount, setAmount] = useState('');

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  return (
    <div className="add-money-container">
      <div className="add-money-grid">
        <div className="add-money-content">
          <div className="content-wrapper">
            <Header
              title="Withdraw from Plan"
              
            />

            <div className="wallet-info">
              <div className="wallet-details">
                <p>Test</p>
                <p className="amount">$0.00</p>
              </div>
              <TransferArrow className="text-primary text-2xl mx-auto -mt-8" />
              <div className="wallet-details">
                <p>Rise Wallet</p>
                <p className="amount">$0.00</p>
              </div>
            </div>

            <TransferArrow className="transfer-icon" />

            <div className="amount-input">
              <label htmlFor="dollar-amount">$</label>
              <input
                type="text"
                id="dollar-amount"
                inputMode="numeric"
                autoComplete="off"
                placeholder="0.00"
                value={amount}
                onChange={handleAmountChange}
              />
            </div>

            <button 
              className="continue-button" 
              type="button" 
              disabled={!amount}
            >
              Withdraw to Wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawWallet;