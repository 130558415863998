import { useState } from "react";
import style from "./style.module.css";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";

export function InputField({ label, name, type, value, onChange }) {
    const [hidden, setHidden] = useState(false);

    const handleChangeVisibility = () => {
        setHidden(!hidden);
    };

    const iType = type === "password" ?
        hidden ? "password" : "text"
        : type;

    return (
        <div className={style.control} >
            <label
                className={style.label}
                htmlFor={`${name}-id`}
                id={`${name}-label`}
            >
                {label}
            </label>
            <div className={style.inputRoot}>
                <input
                    id={`${name}-id`}
                    type={iType}
                    className={style.input}
                    defaultValue=""
                    placeholder=" "
                    name={name}
                    value={value}
                    onChange={onChange}
                />

                {
                    type === "password" && <HideButton
                        hidden={hidden}
                        onClick={handleChangeVisibility}
                    />
                }
            </div>
        </div>
    );
}

function HideButton({ hidden, onClick }) {
    const handleChange = (e) => {
        e.preventDefault();
        onClick();
    };

    return (
        <button className={style.hideButton} onClick={handleChange}>
            {hidden ? <EyeInvisibleFilled /> : <EyeFilled />}
        </button>
    );
}