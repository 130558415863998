import React from 'react';
import { Carousel } from 'antd';
import './carousel.css';

const CustomCarousel = ({ slides, onSlideChange }) => {
  return (
    <div className="custom-carousel">
      <Carousel
        autoplay
        afterChange={onSlideChange}
      >
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={slide.image} alt={slide.title} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;