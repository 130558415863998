import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WalletPage from '../../Component/Wallet/WalletPage';
import WalletWithdraw from '../WalletWithdraw/WalletWithdraw';
import TransferRoute from '../TransferRoute/TransferRoute';
import CurrencySelectorRoute from '../CurrencySelectorRouter/CurrencySelectorRouter';
import Withdraw from '../Withdraw/Withdraw';
import SelectBank from '../SelectBank/SelectBank';
import AddBankAccount from '../AddBankAccount/AddBankAccount';
import BankSelector from '../BankSelector/BankSelector';
import BankAccountDetails from '../BankAccountDetails/BankAccountDetails';


const WalletRoute = () => {
  return (
    <Routes>
      <Route index element={<WalletPage />} />
      <Route path="fund-wallet/*" element={<CurrencySelectorRoute />} />
      <Route path="withdraw-wallet" element={<WalletWithdraw />} />
      <Route path="withdraw-wallet/withdraw" element={<Withdraw />} />
      <Route path="withdraw-wallet/withdraw/select-bank" element={<SelectBank />} />
      <Route path="withdraw-wallet/withdraw/select-bank/add-bank-account" element={<AddBankAccount />} />
      <Route path="withdraw-wallet/withdraw/select-bank/add-bank-account/select-bank" element={<BankSelector />} />
      <Route path="withdraw-wallet/withdraw/select-bank/add-bank-account/select-bank/account-details" element={<BankAccountDetails />} />
      <Route path="transfer-wallet/*" element={<TransferRoute />} />
    </Routes>
  );
};

export default WalletRoute;