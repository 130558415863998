import React from 'react';
import './FeedPage.css';
import ClockIcon from '../Icons/ClockIcon';

const FeedCard = ({ href, imageUrl, title, excerpt, category, time }) => (
  <a href={href} className="feed-card">
    <div className="feed-card__content">
      <div className="feed-card__image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
      <div className="feed-card__text">
        <p className="feed-card__title">{title}</p>
        <p className="feed-card__excerpt">{excerpt}</p>
      </div>
    </div>
    <div className="feed-card__footer">
      <p className="feed-card__category">{category}</p>
      <p className="feed-card__time">
        <ClockIcon />
        <span>{time}</span>
      </p>
    </div>
  </a>
);

const Feed = ({ feedItems }) => {
  return (
    <div className="feed">
      <section className="feed__section">
        <div className="feed__header">
          <h1 className="feed__title">Feed</h1>
        </div>
        <div className="feed__content">
          <p className="feed__subtitle">RECENT UPDATES</p>
          <div className="feed__grid">
            {feedItems.map((item, index) => (
              <FeedCard key={index} {...item} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feed;