import React from 'react';
import './index.css';
import StandardLayout from '../../Component/StandardLayout';
import Policy from './Policy';



export default function PrivacyPolicy() {
  return (
    <StandardLayout>
    <div className="policy">
      <Policy />
    </div>
    </StandardLayout>
  );
}