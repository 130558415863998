import { Alert, Button, Form, Input } from "antd";
import { requestPasswordReset } from "../../API/authService";
import { useEffect, useState } from "react";
import "../ResetPasswordPage/style.css";

function SendResetPasswordPage() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);


    const onSubmit = ({ email }) => {
        setError(null);
        setIsLoading(true);
        requestPasswordReset(email)
            .then(() => {
                setIsSent(true);
            }).catch((e) => {
                setError(e.detail);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const handleTimerFinish = () => {
        setIsSent(false);
    }

    return (
        <div className="rp-container">
            <div className="wrapper">
                <div className="top">
                    <img src="/tradetrackr_logo2_transparent.png" alt="logo" className="logo" />
                    <h1>Сброс пароля</h1>
                    <p>Введите адрес электронной почты</p>
                </div>
                {error && <Alert type="error" message={error} onClose={() => setError(null)} showIcon closable />}
                {
                    isSent && <div>
                        <Alert type="success" message="Ссылка на сброс отправлена на вашу электронную почту" showIcon />
                        <p className="timer">Время до очередного запроса: <Timer initialTime={60} onFinish={handleTimerFinish} /> </p>
                    </div>
                }
                <ResetPasswordForm loading={isLoading} disabled={isSent} onFinish={onSubmit} />

                <div className="bottom">
                    <p>Не помните свой пароль? <a href="/login">Нажмите здесь, чтобы войти</a></p>
                </div>
            </div>
        </div>
    );
}

function ResetPasswordForm({ loading, disabled, onFinish }) {
    return (
        <Form
            name="basic"
            variant="filled"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"

        >
            <Form.Item
                label="Почта"
                name="email"
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
                htmlType="email"
            >
                <Input />
            </Form.Item>

            <Button type="primary" htmlType="submit" className="submit-btn" loading={loading} disabled={disabled}>
                Отправить
            </Button>
        </Form>
    );
}

const Timer = ({ initialTime, onFinish }) => {
    const [seconds, setSeconds] = useState(initialTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prev) => {
                if (prev === 0) {
                    clearInterval(interval);
                    onFinish();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return <span>{formatTime(seconds)}</span>;
};

export default SendResetPasswordPage;