import React, { useEffect, useRef } from 'react'

function QualitiesSection() {
  const animatedRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          animatedRef.current.classList.add('isVisible');
          observer.unobserve(animatedRef.current); // Stop observing once the class is added
        }
      },
      { threshold: 0.1 }
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="qualities container wide">
      <div className="qualities__container">
        <article className="qualities__article">
          <h2 className="qualities__title">
            Easy. Diversified. <br />
            Managed by{" "}
            <span>Experts.</span>
          </h2>
          <p className="qualities__description">
            Explore an array of investment options that offer superior returns,
            curated and managed by experts, all from the comfort of your device.
            Simple, right?
          </p>
          <div>
            <a
              href="/home"
              target="_blank"
              rel='noreferrer'
              className="qualities__cta-button"
            >
              Start investing
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="new-arrow-right "
              >
                <path
                  d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </article>
        <div className="qualities__images" ref={animatedRef}>
          <div className="qualities__image-desktop">
            <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
              <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                <img
                  alt=""
                  aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27580%27%20height=%27159%27/%3e"
                  style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }}
                />
              </span>
              <img
                alt=""
                src="https://risevest.com/_next/static/media/half-phone-lg.3c9bde80.webp"
                decoding="async"
                data-nimg="intrinsic"
                style={{ position: "absolute", inset: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
              />
            </span>
          </div>
          <div className="qualities__image-mobile">
            <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
              <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                <img
                  alt=""
                  aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27336%27%20height=%27127%27/%3e"
                  style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }}
                />
              </span>
              <img
                alt=""
                src="https://risevest.com/_next/static/media/half-phone-sm.ea68f08a.webp"
                decoding="async"
                data-nimg="intrinsic"
                style={{ position: "absolute", inset: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>

  );
}

export default QualitiesSection;