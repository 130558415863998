import { useSearchParams } from "react-router-dom";
import "./style.css";
import { Alert, Button, Form, Input } from "antd";
import { resetPassword } from "../../API/authService";
import { useState } from "react";

function ResetPasswordPage() {
    const [params] = useSearchParams();
    const code = params.get("code");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const onSubmit = ({ password }) => {
        setError(null);
        setIsLoading(true);
        resetPassword({ password, code })
        .then(() => {
            setIsSent(true);
        }).catch((e) => {
            setError(e.detail);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <div className="rp-container">
            <div className="wrapper">
                <div className="top">
                    <img src="/tradetrackr_logo2_transparent.png" alt="logo" className="logo" />
                    <h1>Сброс пароля</h1>
                    <p>Введите новый пароль ниже</p>
                </div>
                {isSent && <Alert type="success" message="Пароль успешно сброшен" showIcon />}
                {error && <Alert type="error" message={error} showIcon closable/>}

                <ResetPasswordForm loading={isLoading} disabled={isSent} onFinish={onSubmit} />

                <div className="bottom">
                    <p>Не помните свой пароль? <a href="/login">Нажмите здесь, чтобы войти</a></p>
                </div>
            </div>
        </div>
    );
}

function ResetPasswordForm({ loading, disabled, onFinish }) {
    return (
        <Form
            name="basic"
            variant="filled"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            
        >
            <Form.Item
                label="Пароль"
                name="password"
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Button type="primary" htmlType="submit" className="submit-btn" loading={loading} disabled={disabled}>
                Отправить
            </Button>
        </Form>
    );
}

export default ResetPasswordPage;