import { memo } from "react";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Rectangle, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { formatCurrency } from "../../utils";
import dayjs from "dayjs";

const moneyFormat = (value) => formatCurrency(value) + ' ₽';
const fixedFormat = (target) => (value) => value.toFixed(target);
const timeToDay = (time) => dayjs(time).format('DD.MM.YYYY');
const kformat = (num) => Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num);
const spacedNumberFormat = (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const PortfolioChangeChart = memo(({ ...props }) => (
    <ResponsiveContainer width="100%" height={200}>
        <AreaChart
            width={500}
            height={200}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
            {...props}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={kformat} />
            <Tooltip formatter={moneyFormat}/>
            <Area type="monotone" dataKey="portfolio" stroke="#2475FE" fill="#4489FB66" />
        </AreaChart>
    </ResponsiveContainer>
));

export const DirectionChart = memo(({ ...props }) => (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
            width={500}
            height={300}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            {...props}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={kformat}/>
            <Tooltip formatter={moneyFormat}/>
            <Bar dataKey="result" activeBar={<Rectangle fill="pink" stroke="blue" />} />
        </BarChart>
    </ResponsiveContainer>
));

export const RefundFactorChart = memo(({ ...props }) => (
    <ResponsiveContainer width="100%" height={200}>
        <LineChart
            width={500}
            height={200}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}

            {...props}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" formatter={fixedFormat(4)} dataKey="refund" stroke="#849A72" fill="#849A72" />
            <Line type="monotone" formatter={fixedFormat(4)} dataKey="factor" stroke="#F3722C" fill="#F3722C" />
        </LineChart>
    </ResponsiveContainer>
));

export const TransactionHistoryChart = memo(({ data }) => (
    <ResponsiveContainer width="100%" height={200}>
        <ScatterChart
            margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            }}
        >
            <CartesianGrid />
            <XAxis type="number" dataKey="date" name="date" domain={['auto', 'auto']} tickFormatter={timeToDay} />
            <YAxis dataKey="result" tickFormatter={spacedNumberFormat} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={(value, name) => ({date: timeToDay, result: moneyFormat}[name](value))}/>
            <Scatter name="History" data={data} fill="#4489FB" />
        </ScatterChart>
    </ResponsiveContainer>
));


export const PatternChart = memo(({ ...props }) => (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
            width={500}
            height={300}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            {...props}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="pattern" />
            <YAxis tickFormatter={spacedNumberFormat}/>
            <Tooltip formatter={moneyFormat} />
            <Legend />
            <Bar dataKey="gain" fill="#42CC7D" activeBar={<Rectangle stroke="black" />} />
            <Bar dataKey="loss" fill="#E6521F" activeBar={<Rectangle stroke="black" />} />
        </BarChart>
    </ResponsiveContainer>
));

export const AccuracyChart = memo(({ ...props }) => (
    <ResponsiveContainer width="100%" height={200}>
        <LineChart
            width={500}
            height={200}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
            {...props}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={(value) => value + ' %'} domain={[0, 100]} />
            <Tooltip formatter={(value) => `${value.toFixed(2)} %`}/>
            <Line type="monotone" dataKey="accuracy" stroke="#42CC7D"/>
        </LineChart>
    </ResponsiveContainer>
));