import React from 'react';
import Feed from './Feed';



const FeedPage = () => {
  const feedItems = [
  {
    href: "6c056c44-560d-4347-9ff6-8eb4ee9a9435",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727772759173.jpg",
    title: "Nigeria at 64: Reflecting on Six Defining Moments of Strength, Unity, and Progress",
    excerpt: "Through six defining moments, we retrace Nigeria's steps, remembering its resilience, wounds, victories, and dreams.",
    category: "Marketing",
    time: "6 days ago"
  },
  {
    href: "7d6dc224-1614-43c5-b8e3-7c5795091f4a",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727691855411.png",
    title: "My Name is Eric and this is How i Make My #MoneyRise",
    excerpt: "Eric talks to Rise about his decision to invest with Rise, inspired by his son, and how his investing journey has been so far.",
    category: "User Stories",
    time: "7 days ago"
  },
  {
    href: "0e262a8b-b503-40a1-93a8-d655d66d9d89",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727259124603.jpg",
    title: "Why Cinema's Numbers in Nigeria are Dropping",
    excerpt: "From the golden age of early cinemas in Lagos to the present-day struggle for relevance, this article examines the decline of cinema in Nigeria, why it matters, and whether there is still hope for revival.",
    category: "Marketing",
    time: "12 days ago"
  },
  {
    href: "a3ea5ab3-fe33-4707-83dc-36afc6d47de9",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1726755927889.png",
    title: "Rise Acquires Hisa",
    excerpt: "We are thrilled to announce that Rise has officially acquired Hisa, a Kenyan startup making strides in simplified investment solutions and educational content.",
    category: "Investing",
    time: "18 days ago"
  },
  {
    href: "3a68e5d4-061a-47ca-b838-7b209151720f",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1725973803428.jpg",
    title: "Risevest August Portfolio Report",
    excerpt: "Here's How Our Portfolio Performed in August",
    category: "Investing",
    time: "27 days ago"
  },
  {
    href: "682203f7-3ff5-4660-924a-0dc52944aadd",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1724836123296.jpg",
    title: "The Power of Compounding: The Eighth Wonder of Wealth Creation",
    excerpt: "Albert Einstein is often attributed with the quote, \"Compound interest is the eighth wonder of the world. He who understands it earns it; he who doesn't pays it.\"",
    category: "Investing",
    time: "1 month ago"
  },
  {
    href: "c9724841-354c-493e-bae3-7512716502ac",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1724664104028.jpg",
    title: "\"As A Frequent Traveller, I Use My Rise Account Statement As Proof Of Funds To Apply For Travel Visas\" — Aremu on investing with Rise.",
    excerpt: "In this episode of MoneyRise, we chat with Aremu Anuoluwapo, a linguist who's breaking new ground by integrating local languages into AI tools like Siri and Alexa.",
    category: "User Stories",
    time: "1 month ago"
  },
  {
    href: "/feed/4a166d94-9cf8-4d4c-b945-6e1b262f3213",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1723458530714.png",
    title: "Risevest July Portfolio Report",
    excerpt: "Here's how our portfolio performed in July",
    category: "News",
    time: "2 months ago"
  },
  {
    href: "/feed/0d7f9ace-0e8d-407d-9d25-df6dbb830e33",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1722937996139.jpg",
    title: "Get paid monthly from your Rise Wallet",
    excerpt: "Get paid monthly from your Rise Wallet",
    category: "Investing",
    time: "2 months ago"
  },
  {
    href: "/feed/75aff064-10aa-462f-b789-2c1be638bcf9",
    imageUrl: "https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1722633509329.png",
    title: "Can You win the House? A careful look at Nigeria's sport betting sub culture",
    excerpt: "We explored sports betting from the eye of a betting fan and analysed the roots and its effect on the society",
    category: "News",
    time: "2 months ago"
  }
];

  return (
    <div className="app">
      <Feed feedItems={feedItems} />
    </div>
  );
};



export default FeedPage;