import React from 'react'
/*

*/
const data = [
  {
    offset: 0,
    title: "We bring the world's best companies to you.",
    description: "The Rise stock portfolio is a selection of stocks of the world's most profitable companies, curated by experts to deliver great long term returns.",
    cta: "Create a stock plan now",
    img: "https://risevest.com/_next/static/media/stock.47cdd9e8.webp",
    bg: "rgb(237, 255, 255)"
  },
  {
    offset: 2.25,
    title: "We offer diverse investment options.",
    description: "Our real estate offering is a portfolio of rental properties in the US that provide steady monthly returns, price appreciation and capital gains. Your investments are more stable and protect you from market declines.",
    cta: "Create a real estate plan",
    img: "https://risevest.com/_next/static/media/real-estate.47c947d1.webp",
    bg: "#F5F1FE"
  },
  {
    offset: 4.5,
    title: "There's something for everyone.",
    description: "Looking for stable and safe returns? Our fixed-income portfolio delivers consistent returns annually and is perfect for low-risk investors",
    cta: "Create a fixed-income plan now",
    img: "https://risevest.com/_next/static/media/fixed-income.c294bdb4.webp",
    bg: "rgb(246, 228, 240)"
  },
  {
    offset: 6.75,
    title: "Invest toward your goals.",
    description: "Rise gives you the ability to choose a financial goal, whether tuition, a wedding, travel or retirement and put money away regularly toward them.",
    cta: "Explore our goal based plan",
    img: "https://risevest.com/_next/static/media/goal-based.10556ed2.webp",
    bg: "#FEF3EF"
  }
]

function VCarouselSection() {
  return (
    <section className="st-cards">
      {
        data.map(item => (
          <div className='st-card' style={{'--plan-offset': `${item.offset}rem`, '--bg': item.bg}}>
            <div className="st-card__wrapper container wide">
              <div className="st-card__container container wide">
                <article className='st-card__article'>
                  <h2 className="st-card__title">
                    {item.title}
                  </h2>
                  <p className="st-card__description">
                    {item.description}
                  </p>
                  <div className='st-card__cta'>
                    <a href='/home'>
                      {item.cta}
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="new-arrow-right "
                      >
                        <path
                          d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </div>
                </article>
                <div className="st-card__img flex-center">
                  <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }} >
                    <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }} >
                      <img
                        style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }}
                        alt=""
                        aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27240%27%20height=%27320%27/%3e"
                      />
                    </span>
                    <img
                      alt={item.title}
                      src={item.img}
                      decoding="async"
                      data-nimg="intrinsic"
                      style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "border-box", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }}
                    />
                  </span>
                </div>

              </div>
            </div>
          </div>
        ))
      }
    </section>
  );
}

export default VCarouselSection;