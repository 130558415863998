import React from 'react';

const EmptyCommissionIcon = ({ className }) => {
  return (
    <svg 
      width="68" 
      height="68" 
      viewBox="0 0 68 68" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1858_122772)">
        <rect width="68" height="68" rx="34" fill="#71879C" fillOpacity="0.1" />
        <circle cx="33.5" cy="27" r="9" fill="white" />
        <rect x="18.5" y="41" width="31" height="45" rx="15.5" fill="white" />
        <g filter="url(#filter0_f_1858_122772)">
          <rect x="-12.5" y="55" width="93" height="25" fill="#71879C" fillOpacity="0.2" />
        </g>
        <circle cx="49.5" cy="44" r="7" fill="#94A1AD" />
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M50.2498 43.2498V40.8887H48.7498V43.2498H46.3887V44.7498H48.7498V47.1109H50.2498V44.7498H52.6109V43.2498H50.2498Z" 
          fill="white"
        />
      </g>
      <defs>
        <filter 
          id="filter0_f_1858_122772" 
          x="-42.1" 
          y="25.4" 
          width="152.2" 
          height="84.2" 
          filterUnits="userSpaceOnUse" 
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="14.8" result="effect1_foregroundBlur_1858_122772" />
        </filter>
        <clipPath id="clip0_1858_122772">
          <rect width="68" height="68" rx="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyCommissionIcon;