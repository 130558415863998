import _ from "lodash";
import { store } from "./redux/store";

export const formatNumber = (number) => {
    const prefix = number > 0 ? '' : '';
    const formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const formattedNum = formatter.format(number)
    return prefix + formattedNum.replace(',', '.');
}

export function formatNumberWithCommas(number) {
    const parts = number.toString().split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1] ? '.' + parts[1] : '';
    return integerPart + decimalPart;
}

export const formatNumberForQuantity = (number) => {
    const formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(number);
}
export const getColorForNumber = (number) => number < 0 ? 'red' : 'green'

export function userAuthenticated() {
    const auth = store.getState().auth;
    return !_.isEmpty(auth);
}


export function getDateWeek(date) {
    const currentDate =
        (typeof date === 'object') ? date : new Date();
    const januaryFirst =
        new Date(currentDate.getFullYear(), 0, 1);
    const daysToNextMonday =
        (januaryFirst.getDay() === 1) ? 0 :
            (7 - januaryFirst.getDay()) % 7;
    const nextMonday =
        new Date(currentDate.getFullYear(), 0,
            januaryFirst.getDate() + daysToNextMonday);
    return (currentDate < nextMonday) ? 52 :
        (currentDate > nextMonday ? Math.ceil(
            (currentDate - nextMonday) / (24 * 3600 * 1000) / 7) : 1);
}

export const formatCurrency = (number, withSign = false) => {
    const strNumber = String(number);
    const parts = strNumber.split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const sign = withSign && number > 0 ? '+' : '';
    return sign + formattedInteger + decimalPart;
}

export const sectorInRussian = (sector) => ({
    telecom: 'телекоммуникации', materials: 'материалы', energy: 'энергетика',
    consumer: 'потребитель', it: 'ит', financial: 'финансы',
    utilities: 'коммунальные услуги', industrials: 'промышленность', other: 'другое',
}[sector] || sector);

export const instrInRussian = (instr) => ({
    share: 'Акции', bond: 'облигация', etf: 'ETF', future: 'будуще'
}[trimEnd(instr, 's')] || instr);

export const trimEnd = (str, toRemove = /\s*/) => {
    const pattern = typeof toRemove === 'string' ? toRemove.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : toRemove.source;
    const re = new RegExp(`${pattern}+$`);
    return str.replace(re, '');
}