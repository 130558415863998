import React from 'react';
import WelcomePageLeftSide from "../RegisterPage/WelcomePageLeftSide/WelcomePageLeftSide";
import PagesRightSide from "../RegisterPage/PagesRightSide/PagesRightSide";
import './welcome.css'
import {Col, Row} from "antd";

const Welcome = ({onRegisterClick}) => {

    return (
        <Row>
            <Col xs={24} lg={12}>
                <WelcomePageLeftSide onRegisterClick={onRegisterClick}/>
            </Col>
            <Col lg={12}>
                <PagesRightSide/>
            </Col>
        </Row>)
};

export default Welcome;