import React, { createContext, useState, useContext, useEffect } from 'react';

const BalanceVisibilityContext = createContext();

export const BalanceVisibilityProvider = ({ children }) => {
  const [isBalanceHidden, setIsBalanceHidden] = useState(() => {
    const stored = localStorage.getItem('isBalanceHidden');
    return stored ? JSON.parse(stored) : false;
  });

  useEffect(() => {
    localStorage.setItem('isBalanceHidden', JSON.stringify(isBalanceHidden));
  }, [isBalanceHidden]);

  const toggleBalanceVisibility = () => {
    setIsBalanceHidden(prevState => !prevState);
  };

  return (
    <BalanceVisibilityContext.Provider value={{ isBalanceHidden, toggleBalanceVisibility }}>
      {children}
    </BalanceVisibilityContext.Provider>
  );
};

export const useBalanceVisibility = () => useContext(BalanceVisibilityContext);