import React from 'react';
import HeroSection from './HeroSection';
import MeetSomeSection from './MeetSomeSection';
import QualitiesSection from './QualitiesSection';
import SeeOurNumberSection from './SeeOurNumberSection';
import VCarouselSection from './VCarouselSection';
import EstimationSection from './EstimationSection';
import MeetExpertsSection from './MeetExpertsSection';
import JoinCommunity from './JoinCommunity';
import RequestVCardSection from './RequestVCardSection';
import LastNewsSection from './LastNewsSection';
import './index.css';
import StandardLayout from '../../Component/StandardLayout';

function HomePage() {
    return (
        <StandardLayout>
            <div className='home-page'>
                <HeroSection />
                <MeetSomeSection />
                <QualitiesSection />
                <SeeOurNumberSection />
                <VCarouselSection />
                <EstimationSection />
                <RequestVCardSection />
                <MeetExpertsSection />
                <LastNewsSection />
                <JoinCommunity />
            </div>
        </StandardLayout>
    );
}

export default HomePage;