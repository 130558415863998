import { Flex } from "antd";
import './style.css';
import { useCallback, useEffect, useMemo, useState } from "react";
import { createDiaryItem, fetchTickerName, getAllDiaryItems, getDiarySettings, updateDiaryItem } from "../../API/diaryService";
import DiaryTable from "./DiaryTable";
import ChartsSection from "./ChartsSection";
import _ from "lodash";

function Diary() {
    const [diaryEntries, setDiaryEntries] = useState([]);
    const [diarySettings, setDiarySettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const attachCalculatedFields = useCallback((entry, index, items) => {
        const portfolioAmount = diarySettings.totalFunds * diarySettings.tradingPortfolioPct / 100;

        const calculateAccuracy = () => {
            const trades = items.slice(0, index + 1);
            const nbLosingTrades = trades.filter(item => item.result && item.result < 0).length;
            const nbTrades = trades.length;
            return (1 - nbLosingTrades / nbTrades) * 100;
        };

        const calculateFactor = () => {
            const trades = items.slice(0, index + 1).filter(item => !_.isNil(item.result));
            const { pros, loss } = trades.reduce((acc, item) => {
                if (item.result > 0) {
                    acc.pros.push(item);
                } else {
                    acc.loss.push(item);
                }
                return acc;
            }, { pros: [], loss: [] });

            const avgProfit = pros.length ? pros.reduce((sum, item) => sum + item.result, 0) / pros.length : 0;
            const avgLoss = pros.length ? loss.reduce((sum, item) => sum + item.result, 0) / loss.length : 0;

            return !avgLoss ? 1 : Math.abs(avgProfit / avgLoss);
        };

        const calculatePortfolio = () => {
            const trades = items.slice(0, index + 1).filter(item => !_.isNil(item.result));
            return portfolioAmount + trades.reduce((sum, item) => sum + item.result, 0);
        };

        const calculateDays = () => {
            const isPossible = entry.releaseDate && entry.entryDate;
            return isPossible
                ? Math.ceil(Math.abs(new Date(entry.releaseDate) - new Date(entry.entryDate)) / (1000 * 60 * 60 * 24))
                : null
        }

        return {
            ...entry,
            key: entry.id,
            days: calculateDays() || '-',
            refund: entry.result && entry.risk ? (entry.result / entry.risk) || 1 : null,
            factor: calculateFactor(),
            accuracy: calculateAccuracy(),
            portfolio: calculatePortfolio(),
        };
    }, [diarySettings]);

    const handleSyncUpdate = async (update) => {
        setIsLoading(true);
        const isTickerUpdate = update.hasOwnProperty('ticker');
        try {
            if (isTickerUpdate) {
                update.companyName = (await fetchTickerName(update.ticker)) || update.ticker.toUpperCase();
            }
            await updateDiaryItem(update.id, update);
            const newEntries = [...diaryEntries];
            const entryId = newEntries.findIndex(entry => entry.id === update.id);
            if (entryId !== -1) {
                const entry = newEntries[entryId];
                newEntries[entryId] = { ...entry, ...update };
                setDiaryEntries(newEntries);
            }

            return true;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            setIsLoading(false);
        }
    }

    const handleAddEntry = async () => {
        const payload = {
            ticker: '',
            companyName: '',
            pattern: diarySettings && diarySettings.patterns.length > 0 ? diarySettings.patterns[0] : '',
            strategy: diarySettings && diarySettings.strategies.length > 0 ? diarySettings.strategies[0] : '',
            direction: 'LONG',
            entryDate: null,
            entry: '',
            releaseDate: null,
            output: '',
            risk: null,
            result: null,
            status: 'OPEN'
        };

        setIsLoading(true);
        try {
            const newEntry = await createDiaryItem(payload)
            const newEntries = [...diaryEntries];
            newEntries.push(newEntry);
            setDiaryEntries(newEntries);
            return true;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        };

        return false;
    }

    useEffect(() => {
        const fetchData = async () => {
            const diaryItems = await getAllDiaryItems();
            const diarySettings = await getDiarySettings();
            if (diaryItems && diarySettings) {
                setDiaryEntries(diaryItems);
                setDiarySettings(diarySettings);
            }
        }
        fetchData().catch(console.error).finally(() => setIsLoading(false));
    }, []);
    const dataSource = useMemo(() => diaryEntries.map(attachCalculatedFields), [attachCalculatedFields, diaryEntries]);
    
    return (
        <Flex className="diary-home" vertical gap={64}>
            <DiaryTable
                dataSource={dataSource}
                diarySettings={diarySettings}
                isLoading={isLoading}
                onSyncUpdate={handleSyncUpdate}
                onClickAddEntry={handleAddEntry}
            />
            <ChartsSection
                data={dataSource}
                settings={diarySettings}
            />
        </Flex>
    );
}
export default Diary;