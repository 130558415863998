import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { currentTinkoffAccountSelector, setCurrentTinkoffAccount, tinkoffAccountsSelector } from "../../redux/slices/commonSlice";
import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { addAuth } from "../../redux/slices/authSlice";
import MenuButton from "../MenuButton";



function SideMenu() {
  const [selectedKey, setSelectedKey] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();
  const menuButtonRef = useRef();

  useEffect(() => {
    const storedKey = localStorage.getItem('key') || "/";
    navigate(storedKey);
  }, []);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  const handleMenuClick = (item) => {
    menuButtonRef.current.handleClick();
    navigate(item.key);
    localStorage.setItem('key', item.key);
  };

  return (
    <div className="SideMenu">
      <MenuButton ref={menuButtonRef} close />

      <AccountsSelector />

      <Menu
        className="SideMenuVertical"
        mode="inline"
        onClick={handleMenuClick}
        selectedKeys={[selectedKey]}
        items={[
            generateItem(selectedKey, "/", "Портфель", "Icon"),
            generateItem(selectedKey, "/diary", "Торговый дневник", "Icon"),
            generateSubItem(selectedKey, "/diary-settings", "Настройки"),
            generateItem(selectedKey, "/statistics", "Статистика", "Chart"),
            generateItem(selectedKey, "/operations-history", "История операций", "2user"),
            generateItem(selectedKey, "/stock-rating", "Рейтинг акций", "Star"),
            generateItem(selectedKey, "/settings", "Настройки", "Setting"),
            generateSubItem(selectedKey, "/subscription-settings", "Настройки"),
            generateItem(selectedKey, "/priv", "Priv", "shieldDone"),
            generateItem(selectedKey, "/service", "O сервисе", "question"),
            generateItem(selectedKey, "/support", "Поддержка", "Message")
          ]}
    />
    </div>
  );
}

function generateItem(selectedKey, key, label, icon) {
  return {
    label: <span className={`menu-label ${selectedKey === key ? 'menu-label-black' : ''}`}>{label}</span>,
    key,
    icon: <img src={`./sidebar-svg/${icon}${selectedKey === key ? '-black' : ''}.svg`} alt={label} className={`menu-icon ${selectedKey === key ? 'menu-icon-black' : ''}`} />
  };
}

function generateSubItem(selectedKey, key, label) {
  return {
    label: <span className={`menu-label menu-label--sub ${selectedKey === key ? 'menu-label-black' : ''}`}>{label}</span>,
    key,
    icon: <img src={`./sidebar-svg/dot${selectedKey === key ? '-black' : ''}.svg`} alt={label} className={`menu-icon menu-icon--sub ${selectedKey === key ? 'menu-icon-black' : ''}`} />
  };
}

function filterSelected(accounts) {
    return accounts.filter(account => account.isChecked)
};

function AccountsSelector() {
  const dispatch = useDispatch();
  const state = store.getState();
  const [accounts, setAccounts] = useState(filterSelected(tinkoffAccountsSelector(state)));
  const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleAccountClick = (item) => {
    dispatch(setCurrentTinkoffAccount(accountSelect(item.key)));
    dispatch(addAuth({ accountId: item.key }));
  };

  function accountSelect(k) {
    return accounts.filter(account => account.id === k)[0];
  }

  const items = accounts.map(account => ({
    label: (
      <ul classname="accountlabellist">
        <li classname="accountLabel">{account.name}</li>
      </ul>
    ),
    key: account.id,
  }));

  const disabled = accounts.length === 0;

  useEffect(() => {
    return store.subscribe(() => {
      const state = store.getState();
      setAccounts(filterSelected(tinkoffAccountsSelector(state)));
      setSelectedAccount(currentTinkoffAccountSelector(state));
    });
  }, []);

  return (
    <Dropdown
      overlay={
        <Menu
          onClick={handleAccountClick}
          className="dropdown-overlay"
        >
          {items.map(item => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ))}
        </Menu>
      }
      disabled={disabled}
    >
      <Space id="selectAccount">
        <div className="sideBarDropDown2">
          <img src="./sidebar-svg/2user.svg" alt="img" id="selectAccountIcon" />
          <span className={`sideBarDropDown${disabled ? "--disabled" : ""}`} id="selectAccountItems">{(!disabled && selectedAccount) ? selectedAccount.name : (windowWidth > 768 ? "Пустой" : "")}</span>
          <DownOutlined id="selectAccountDownOutline" />
        </div>
      </Space>
    </Dropdown>
  )
}

export default SideMenu;
