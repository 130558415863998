import React from 'react';

const PlayIcon = ({ className }) => {
  return (
    <svg 
      className={className} 
      focusable="false" 
      aria-hidden="true" 
      viewBox="0 0 24 24"
    >
      <path d="M8 5v14l11-7z"></path>
    </svg>
  );
};

export default PlayIcon;