export default function SlantArrowDown ({ size = 24, color = 'currentColor', ...props }){
    return(
        <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 5.41L18.59 4L7 15.59V9H5v10h10v-2H8.41L20 5.41z"
        fill={color}
      />
    </svg>
  );
}