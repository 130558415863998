import React, { useState } from 'react';
import './WithdrawModal.css';
import { CloseButton } from '../Buttons';
import { useNavigate } from 'react-router-dom';

const WithdrawModal = ({ onClose }) => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate('select-bank');
  };

  return (
    <div className="modal-overlay">
      <div className="modal" tabIndex="-1">
        <div className="modal__content">
          <div className="modal__header">
            <CloseButton onClick={onClose} />
            <h2 className="modal__title">Bank account withdrawal</h2>
          </div>

          <div className="modal__details">
            <p className="modal__detail-item">
              <span className="modal__detail-label">Duration</span>
              <span className="modal__detail-value">2-3 business days</span>
            </p>
            <p className="modal__detail-item">
              <span className="modal__detail-label">Withdrawal limit</span>
              <span className="modal__detail-value">₦10,000,000 / transaction</span>
            </p>
          </div>

          <button 
            className="modal__continue-button"
            onClick={handleContinue}
            type="button"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;