import React, {useState} from 'react';
import './registrationLeft.css'
import { EyeInvisibleTwoTone, EyeTwoTone} from "@ant-design/icons";
import { LoginButton } from '@telegram-auth/react';
import { register, registerWithTelegram } from '../../API/authService';
import { useDispatch } from 'react-redux';
import { addAuth } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';

const RegistrationPageLeftSide = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        confirmPassword: '',
        email: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        const payload = {
            ...formData,
            firstname: formData.name,
            lastname: formData.name,
            username: formData.name,
            age: 18,
            phone: "xxx-xxx-xxxx"
        };
        register(payload).then(data => {
            dispatch(addAuth({user: data}));
            navigate("/");
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleTelegramAuth = (authData) => {
        const payload = {
            telegramId: authData.id,
            username: authData.username,
            firstname: authData.first_name,
            lastname: authData.last_name,
            authDate: authData.auth_date,
            photoUrl: authData.photo_url,
            hash: authData.hash
        };
        setError(null);
        setIsLoading(true);

        registerWithTelegram(payload).then(data => {
            dispatch(addAuth({user: data}));
            navigate("/");
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const Errors = () => {
        if (!error) return null;
        const { errors } = error;
        if (errors) 
            return <ul className='error'>{Object.keys(errors).map(field => 
                <li key={field}>{`${field}: ${errors[field]}`}</li>
            )}</ul>;
        return <p className='error'>{error.detail}</p>;
    }

    return (
        <div className='register-container'>

            <div className='register-title-container'>
                <span className='register-title'>СЕРВИС ДЛЯ УЧЕТА ИНВЕСТИЦИЙ</span>
            </div>
            <div style={{display:"flex",flex:1}}>
                <div className='register-card2'>
                    <div>
                        <div className='register-card-title-container'>
                            <hr className='register-card-lines'/>
                            <span className='register-card-title'>РЕГИСТРАЦИЯ В СЕРВИСЕ</span>
                            <hr className='register-card-lines'/>
                        </div>
                        {Errors()}
                        <form onSubmit={handleSubmit} className='form-display'>
                            <div className='input-display' >
                                <label htmlFor="name" className='input-label-display'>Логин*</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    placeholder={"логин"}
                                    className='input-containers'
                                />
                            </div>

                            <div className='input-display'>
                                <label htmlFor="password" className='input-label-display'>Пароль*</label>

                                <div className='password-containers'>
                                <input
                                    type={showPassword ? 'text' : 'password'}

                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    placeholder='Пароль'
                                    className='input-password'

                                />
                                {showPassword ? (
                                    <EyeTwoTone onClick={togglePasswordVisibility} twoToneColor="#AAAAAA" />
                                ) : (
                                    <EyeInvisibleTwoTone onClick={togglePasswordVisibility}  twoToneColor="#AAAAAA"/>
                                )}
                            </div>


                            </div>
                            <div className='input-display' >
                                <label htmlFor="confirmPassword" className='input-label-display'>Пароль еще раз*</label>
                                <div className='password-containers'>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                    className='input-password'
                                    placeholder='Пароль еще раз'
                                />
                                {showConfirmPassword ? (
                                    <EyeTwoTone onClick={toggleConfirmPasswordVisibility} twoToneColor="#AAAAAA" />
                                ) : (
                                    <EyeInvisibleTwoTone onClick={toggleConfirmPasswordVisibility} twoToneColor="#AAAAAA"/>
                                )}
                                </div>
                            </div>
                            <div className='input-display'>
                                <label htmlFor="surname" className='input-label-display'>Почта* </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    placeholder='Почта для уведомления'
                                    className='input-containers'
                                />
                            </div>
                            <button type="submit" disabled={isLoading} className={`submit-button  ${isLoading ? 'submit-button--loading' : ''}`}>
                                {
                                    isLoading && formData.email !== '' ? <Spinner /> : <span className='submit-text'>Зарегистрироваться</span>
                                }
                            </button>
                            <div className='telegram-button-container'>
                                <LoginButton
                                    botUsername="TinkoffAuthTestBot"
                                    onAuthCallback={handleTelegramAuth}
                                    buttonSize="large"
                                    cornerRadius={8}
                                    showAvatar={true}
                                    lang="ru"
                                />
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default RegistrationPageLeftSide;