export function CloseIcon({ size = 24, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            className="feather feather-x"
            {...props}
            width={size}
            height={size}
        >
            <path d="M18 6 6 18M6 6l12 12" />
        </svg>
    );
}

export default CloseIcon;