import React from 'react';

const ContinueButton = ({ onClick, text = 'Yes, Continue' }) => {
    return (
        <button 
            type="button"
            className="confirm-button"
            data-test-id="continue"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export  default ContinueButton;