import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DurationSelector.css';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const DurationSelector = () => {
  const [selectedDuration, setSelectedDuration] = useState(12);
  const navigate = useNavigate();
  
  const durations = [
    { value: 3, label: 'Months' },
    { value: 6, label: 'Months' },
    { value: 12, label: 'Months' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('success', { state: { duration: selectedDuration } });
  };


  return (
    <div className="duration-selector">
      <Header
        title="Duration"
        
      />

      <div className="progress">
        <p className="caption">Question 2 of 2</p>
        <div className="progress-bar">
          <div className="progress-fill" style={{ width: '100%' }} />
        </div>
      </div>

      <p className="question">How long do you want to invest for?</p>

      <div className="returns-preview">
        <div>
          <p className="returns-value">10%</p>
          <p className="returns-label">Est. returns</p>
        </div>
        <div className="chart-icon" />
      </div>

      <p className="selection-label">Choose a duration</p>

      <form onSubmit={handleSubmit}>
        <div className="duration-options">
          {durations.map(({ value, label }) => (
            <label 
              key={value}
              className={`duration-option ${selectedDuration === value ? 'selected' : ''}`}
            >
              <p className="duration-value">{value}</p>
              <p className="duration-label">{label}</p>
              <input
                type="radio"
                name="duration"
                value={value}
                checked={selectedDuration === value}
                onChange={(e) => setSelectedDuration(Number(e.target.value))}
              />
            </label>
          ))}
        </div>

        <p className="disclaimer">
          The longer you invest, the higher your potential returns. 
          These estimates are based on historical results. Actual returns may vary.
        </p>

        <button type="submit" className="submit-button">
          Create plan
        </button>
      </form>
    </div>
  );
};

export default DurationSelector;