import style from "./style.module.css";

export function TinkoffButton() {
    return (
        <button className={style.tinkoffButton}>
            <div>Войти c</div>
            <div className='bold'>Tinkoff</div>
            <img src='./LoginSignUp/tinkoff-id.svg' alt="tinkoff" />
        </button>
    );
}