import React from 'react';

const style = {
  fontSize: '2rem',
  margin: '-1px auto 0',
  color: 'hsl(183, 90%, 33%)',
  display: 'block'
};

const HorizontalArrowIcon = ({ className }) => {
  return (
    <svg 
      width="1em" 
      height="1em" 
      viewBox="0 0 25 25" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M12.3818 24.0215C19.0091 24.0215 24.3818 18.6487 24.3818 12.0215C24.3818 5.39425 19.0091 0.0214844 12.3818 0.0214844C5.75461 0.0214844 0.381836 5.39425 0.381836 12.0215C0.381836 18.6487 5.75461 24.0215 12.3818 24.0215" 
        fill="currentColor"
      />
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M14.5534 17.232C14.5534 17.232 18.9975 12.7984 19.0403 12.7613C19.2491 12.5809 19.3818 12.3175 19.3818 12.0215C19.3818 11.7131 19.2371 11.4403 19.0129 11.2599C18.9647 11.2213 14.5534 6.81097 14.5534 6.81097C14.1651 6.42499 13.5351 6.42499 13.1473 6.81097C12.759 7.19695 12.759 7.82312 13.1473 8.20861L15.9884 11.0331H6.38123C5.82933 11.0331 5.38184 11.4759 5.38184 12.0215C5.38184 12.5676 5.82933 13.0099 6.38123 13.0099H15.9884L13.1473 15.8344C12.759 16.2198 12.759 16.846 13.1473 17.232C13.5351 17.618 14.1651 17.618 14.5534 17.232Z" 
        fill="white"
      />
      <path 
        d="M14.5534 17.232C14.5534 17.232 18.9975 12.7984 19.0403 12.7613C19.2491 12.5809 19.3818 12.3175 19.3818 12.0215C19.3818 11.7131 19.2371 11.4403 19.0129 11.2599C18.9647 11.2213 14.5534 6.81097 14.5534 6.81097C14.1651 6.42499 13.5351 6.42499 13.1473 6.81097C12.759 7.19695 12.759 7.82312 13.1473 8.20861L15.9884 11.0331H6.38123C5.82933 11.0331 5.38184 11.4759 5.38184 12.0215C5.38184 12.5676 5.82933 13.0099 6.38123 13.0099H15.9884L13.1473 15.8344C12.759 16.2198 12.759 16.846 13.1473 17.232C13.5351 17.618 14.1651 17.618 14.5534 17.232" 
        stroke="#0a917f"
      />
    </svg>
  );
};

export default HorizontalArrowIcon;