import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userAuthenticated } from '../../utils';
import { useEffect, useLayoutEffect, useState } from 'react';
import { fetchTinkoffAccounts } from '../../API/accountsService';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { addTinkoffAccounts, currentTinkoffAccountSelector, existTinkoffTokenSelector, setExistTinkoffToken } from '../../redux/slices/commonSlice';
import LoadingSection from '../LoadingSection';

export default function RequireToken() {
    const location = useLocation();
    const dispatch = useDispatch();
    const tokenExist = existTinkoffTokenSelector(store.getState());
    const currentAccount = currentTinkoffAccountSelector(store.getState());
    const [isLoading, setIsLoading] = useState(true);

    const setTokenExist = (value) => {
        dispatch(setExistTinkoffToken(value));
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accounts = await fetchTinkoffAccounts();
                dispatch(addTinkoffAccounts(accounts));
                setTokenExist(true);
            } catch (error) {
                console.error(error);
                dispatch(addTinkoffAccounts([]));
                setTokenExist(false);
            } finally {
                setIsLoading(false);
            }
        };
        if (!tokenExist) {
            fetchData();
        } else {
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return <LoadingSection />;
    }

    if (tokenExist) {
        if (!currentAccount) {
            return <Navigate to="/settings" replace state={{ from: location, error: 'Не выбран ни один счет. Пожалуйста, выберите хотя бы 1 аккаунт' }} />;
        }
        return <Outlet />;
    }



    return <Navigate to="/settings" replace state={{ from: location, error: 'Пожалуйста, добавьте свой токен Тинькофф' }} />;
};
