import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userAuthenticated } from '../../utils';

export default function RequireAuth() {
    const location = useLocation();

    return userAuthenticated() 
        ? <Outlet /> 
        : <Navigate to="/register" replace state={{ from: location }}/>;
};
