const RiseLogo = () => (
  <svg 
    className="rise-logo"
    viewBox="0 0 120 36" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M114.899 10.9973C117.716 10.9973 120 8.71337 120 5.89606C120 3.07875 117.716 0.794861 114.899 0.794861C112.081 0.794861 109.798 3.07875 109.798 5.89606C109.798 8.71337 112.081 10.9973 114.899 10.9973Z" />
    <path d="M107.83 19.1776H81.8722C82.066 25.6348 86.0722 29.2504 91.7518 29.2504C96.4006 29.2504 99.565 26.4742 100.404 22.729H107.571C106.538 30.9292 99.1774 35.3848 91.7518 35.3848C81.2266 35.3848 74.3176 29.3152 74.3176 17.5648C74.3176 6.71505 81.0976 -0.000152588 91.3642 -0.000152588C101.564 -0.000152588 107.83 7.42545 107.83 16.5946V19.1776ZM81.8722 14.722H100.082C99.6946 9.36285 96.2722 6.13425 91.042 6.13425C86.134 6.13425 82.2616 9.04005 81.8722 14.722Z" />
    <path d="M39.6614 23.5036H47.4098C47.6678 27.4426 50.7014 29.6398 56.0624 29.6398C60.4532 29.6398 63.1652 28.0258 63.1652 25.3138C63.1652 23.3116 62.003 22.2142 58.5806 21.4396L50.186 19.6294C44.1164 18.403 40.436 15.2398 40.436 10.1998C40.436 4.00065 46.0538 -0.000152588 54.9644 -0.000152588C64.715 -0.000152588 70.4618 4.39065 70.4618 11.8798H62.9066C62.9066 8.06985 60.0656 5.74545 55.223 5.74545C50.8322 5.74545 48.1844 7.36005 48.1844 10.0054C48.1844 11.8132 49.7342 13.234 52.511 13.8796L61.2926 15.7522C67.814 17.173 70.9784 20.0122 70.9784 24.9856C70.9784 31.5076 65.4254 35.3818 55.9976 35.3818C45.731 35.3848 39.9194 31.123 39.6614 23.5036Z" />
    <path d="M26.5278 0.515869H34.4053V34.3511H26.5278V0.515869Z" />
    <path d="M8.13747 13.7531C9.62247 4.06727 13.0449 0.515869 22.3431 0.515869V7.16687C11.9469 7.81247 8.07266 11.1059 7.87886 21.2435V34.3511H0.00146484V1.03247H7.62146L7.87946 13.7525L8.13747 13.7531Z" />
  </svg>
);

export default RiseLogo;
