import { ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Tooltip } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { AccuracyChart, DirectionChart, PatternChart, PortfolioChangeChart, RefundFactorChart, TransactionHistoryChart } from "./charts";
import _ from "lodash";

function ChartsSection({ data }) {

    const sortedData = data
        .filter((e) => !_.isNil(e.releaseDate) && !_.isNil(e.entryDate))
        .sort((a, b) => new Date(a.releaseDate) - new Date(b.releaseDate));

    const portfolioChartData = useMemo(() => {
        const data = sortedData.reduce((acc, entry) => {
            const date = dayjs(entry.releaseDate).format('DD.MM.YYYY');
            if (!acc[date]) {
                acc[date] = { date, portfolio: 0, count: 0 };
            }
            acc[date].portfolio += entry.portfolio;
            acc[date].count++;
            return acc;
        }, {});
        const ans =Object.values(data).map(entry => ({
            date: entry.date,
            portfolio: entry.portfolio / entry.count
        }));
        return ans;
    }, [sortedData]);

    const directionChartData = useMemo(() => {
        const _data = data.reduce((acc, entry) => {
            acc[entry.direction] += entry.result;
            return acc;
        }, { 'LONG': 0, 'SHORT': 0, 'NEUTRAL': 0 });
        return [
            { name: 'LONG', result: _data.LONG, fill: "#42CC7D" },
            { name: 'SHORT', result: _data.SHORT, fill: "#F94144" },
            { name: 'NEUTRAL', result: _data.NEUTRAL, fill: "#898989" }
        ];
    }, [data]);

    const accuracyChartData = useMemo(() => {
        const data = sortedData.reduce((acc, entry) => {
            const date = dayjs(entry.releaseDate).format('DD.MM.YYYY');
            if (!acc[date]) {
                acc[date] = { date, accuracy: 0, count: 0 };
            }
            acc[date].accuracy += entry.accuracy;
            acc[date].count++;
            return acc;
        }, {});
        return Object.values(data).map(entry => ({
            date: entry.date,
            accuracy: entry.accuracy / entry.count
        }));
    }, [sortedData]);

    const patternChartData = useMemo(() => {
        const _data = data.reduce((acc, entry) => {
            if (!acc[entry.pattern]) {
                acc[entry.pattern] = { gain: 0, loss: 0 };
            }
            if (entry.result > 0) {
                acc[entry.pattern].gain += entry.result;
            } else {
                acc[entry.pattern].loss += entry.result;
            }
            return acc;
        }, {});
        return Object.keys(_data).map(pattern => ({ pattern, ..._data[pattern] }));
    }, [data]);

    const refundFactorChartData = useMemo(() => {
        const data = sortedData.reduce((acc, entry) => {
            const date = dayjs(entry.releaseDate).format('DD.MM.YYYY');
            if (!acc[date]) {
                acc[date] = { date, refund: 0, factor: 0, count: 0 };
            }
            acc[date].refund += entry.refund;
            acc[date].factor += entry.factor;
            acc[date].count++;
            return acc;
        }, {});
        return Object.values(data).map(entry => ({
            date: entry.date,
            refund: entry.refund / entry.count,
            factor: entry.factor / entry.count
        }));
    }, [sortedData]);

    const transactionHistoryChartData = useMemo(() => {
        return sortedData.map(entry => ({
            date: dayjs(entry.releaseDate).toDate().getTime(),
            result: entry.result
        }));
    }, [sortedData]);

    return (
        <div>
            <h2>Статистика</h2>
            <div className="diary-charts-grid">
                <ChartCard title="Изменение портфеля" tip="Изменение портфеля" bordered={false}>
                    <PortfolioChangeChart data={portfolioChartData} />
                </ChartCard>

                <ChartCard title="НАПРАВЛЕНИЕ" tip="НАПРАВЛЕНИЕ" bordered={false}>
                    <DirectionChart data={directionChartData} />
                </ChartCard>

                <ChartCard title="Возврат / Фактор" tip="Возврат / Фактор" bordered={false}>
                    <RefundFactorChart data={refundFactorChartData} />
                </ChartCard>

                <ChartCard title="ТОЧНОСТЬ" tip="ТОЧНОСТЬ" bordered={false}>
                    <AccuracyChart data={accuracyChartData} />
                </ChartCard>

                <ChartCard title="История сделок" tip="История сделок" bordered={false}>
                    <TransactionHistoryChart data={transactionHistoryChartData} />
                </ChartCard>

                <ChartCard title="ПАТТЕРН" tip="ПАТТЕРН" bordered={false}>
                    <PatternChart data={patternChartData} />
                </ChartCard>
            </div>
        </div>
    );
}

function ChartCard({ tip, children, ...props }) {
    const tooltip = (
        <Tooltip title={tip}>
            <ExclamationCircleFilled className="tooltip-icon" />
        </Tooltip>
    );

    return (
        <Card bordered={false} extra={tip ? tooltip : null} {...props}>
            {children}
        </Card>
    );
}

export default ChartsSection;