import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, Minus, Plus, DollarSign } from 'lucide-react';
import './PortfolioEditor.css';
import Header from '../PageHeader/Header';

const PortfolioEditor = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('conservative');
  
  const investmentTypes = [
    { id: 'aggressive', icon: '🔥', label: 'aggressive' },
    { id: 'balanced', icon: '🌟', label: 'balanced' },
    { id: 'conservative', icon: '🌲', label: 'conservative' }
  ];

  const handleSave = () => {
    
    navigate('save-portfolio');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="portfolio-editor">
       <Header
            title="Edit Portfolio"
            
      />

      <div className="chart-container">
        <div className="donut-chart">
        </div>
        <div className="chart-icon">
          {investmentTypes.find(type => type.id === selectedType)?.icon}
        </div>
      </div>

      <p className="portfolio-description">
        <span className="emphasis">Conservative Investment</span> portfolio is for users who have zero-risk tolerance. 
        They want an asset that can ride out all types of market volatility.
      </p>

      <section className="investment-types">
        <div className="section-header">
          <div className="divider" />
          <p>Investment type</p>
          <div className="divider" />
        </div>

        <div className="type-options">
          {investmentTypes.map(({ id, icon, label }) => (
            <div 
              key={id} 
              className={`type-option ${id === selectedType ? 'active' : ''}`}
              onClick={() => setSelectedType(id)}
            >
              <div className="icon-container">
                <span className="icon">{icon}</span>
              </div>
              <p>{label}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="asset-adjustment">
        <div className="section-header">
          <div className="divider" />
          <p>adjust assets</p>
          <div className="divider" />
        </div>

        <div className="asset-control">
          <div className="asset-icon">
            <DollarSign size={20} />
          </div>
          <div className="asset-info">
            <p>Fixed Income</p>
          </div>
          <div className="asset-inputs">
            <input 
              type="text" 
              value="100" 
              disabled 
              className="percentage-input"
            />
            <div className="adjustment-buttons">
              <button disabled className="adjust-button decrease">
                <Minus size={20} />
              </button>
              <button disabled className="adjust-button increase">
                <Plus size={20} />
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="action-buttons">
        <button 
          className="btn secondary"
          onClick={handleGoBack}
        >
          Go Back
        </button>
        <button 
          className="btn primary"
          onClick={handleSave}
        >
          Save Portfolio
        </button>
      </div>
    </div>
  );
};

export default PortfolioEditor;