import React, { useState } from 'react';
import { Button, Card, Divider, Typography, Row, Col, Tooltip } from 'antd';
import { CalendarOutlined, InfoCircleOutlined, QuestionOutlined, SettingOutlined } from '@ant-design/icons';
import './StockPlan.css';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { BackButton } from '../Buttons';
import PageHeader from '../PageHeader/PageHeader';

const { Title, Paragraph, Text } = Typography;

const PerformanceCard = ({ year, performance }) => (
  <Card className="performance-card">
    <Text className="performance-year">{year}</Text><br></br>
    <Text className={`performance ${performance >= 0 ? 'positive' : 'negative'}`}>
      {performance >= 0 ? '+' : ''}{performance}%
    </Text>
  </Card>
);

const StepIcon = ({ icon }) => (
  <div className="step-icon">
    {icon}
  </div>
);

const StockPlan = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = () => {
    setShowModal(false);
    navigate('add');
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="stock-plan">
      <PageHeader
        title="Stocks"
     />
      
      <img src="/tradetrackr/stocks-icon.png" alt="Stocks" className="plan-image" />
      
      <Paragraph className="plan-description">
        Our Stocks plan is perfect for you if you've got a huge risk appetite and <br/>
        would like to own a slice of high-growth companies like Microsoft, Netflix, <br/> 
        and Square. We've delivered historical returns of 14% per annum <br/> to our investors.
      </Paragraph>
      
      <div className="features">
        <div className="feature">
          <div className="feature-icon">
            <QuestionOutlined />
          </div>
          <p>Getting started</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <CalendarOutlined />
          </div>
          <p>Turn on auto-invest</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <SettingOutlined />
          </div>
          <p>Modify as you progress</p>
        </div>
      </div>
      
      <Divider />
      
      <Title level={3} className="historical-performance-title">Historical performance</Title>
      <Row gutter={[16, 16]} className="performance-grid">
        <Col span={8}><PerformanceCard year="2019" performance={27.5} /></Col>
        <Col span={8}><PerformanceCard year="2020" performance={40.77} /></Col>
        <Col span={8}><PerformanceCard year="2021" performance={3.55} /></Col>
        <Col span={8}><PerformanceCard year="2022" performance={-22.86} /></Col>
        <Col span={8}><PerformanceCard year="2021" performance={3.55} /></Col>
        <Col span={8}><PerformanceCard year="2023" performance={22} /></Col>
      </Row>
      
      <Card className="info-card">
        <Tooltip title="Information">
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
        <Text>To manage your investments, Risevest charges a fee of 0.5% per year on your funds.</Text>
      </Card>
      
      <Button 
        type="primary" 
        size="large" 
        block 
        className="get-started-button"
        onClick={() => setShowModal(true)}
      >
        Get started
      </Button>

      {showModal && (
        <ConfirmationModal 
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default StockPlan;