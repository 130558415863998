import React from 'react'

function SeeOurNumberSection() {
  return (
    <div className="stats container wide">
      <h2 className="stats__title">
        See our <span>numbers</span>
      </h2>
      <div className="stats__list">
        <p className="stats__list-item">
          <span className="stats__list-item-value">
            600k +
          </span>
          <span className="stats__list-item-suffix"> Users</span>
        </p>
        <p className="stats__list-item">
          <span className="stats__list-item-value">
            $42m +
          </span>
          <span className="stats__list-item-suffix">
            Paid out to users
          </span>
        </p>
        <p className="stats__list-item">
          <span className="stats__list-item-value stats__list-item-value--sp">
            <span>5 </span>
            <span className="stats__list-item-imgcm">
              <img
                alt=""
                src="https://risevest.com/_next/static/media/countries-sm.45327785.webp"
                width={147}
                height={44}
                decoding="async"
                data-nimg="future"
                loading="lazy"
                style={{ color: "transparent" }}
              />
            </span>
            <span className="stats__list-item-imgcd">
              <img
                alt=""
                src="https://risevest.com/_next/static/media/countries.4d16dce7.webp"
                width={170}
                height={67}
                decoding="async"
                data-nimg="future"
                loading="lazy"
                style={{ color: "transparent" }}
              />
            </span>
          </span>
          <span className="stats__list-item-suffix"> Countries</span>
        </p>
      </div>
    </div>

  );
}

export default SeeOurNumberSection;    