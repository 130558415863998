import React from 'react';

const Terms = () => {
  return (
    <>
      <section>
        <div className="hero">
          <h1>Terms of Use</h1>
          <img
            className="dark-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/dark-ball.2a2bff54.webp"
            width="213"
            height="208"
            decoding="async"
            data-nimg="future"
          />
          <img
            className="light-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/light-ball.6236400f.webp"
            width="462"
            height="252"
            decoding="async"
            data-nimg="future"
          />
        </div>
      </section>

      <main>
        <div className="sidebar">
          <div className="nav">
            <a href="#purpose">1.1 Purpose</a>
            <a href="#agreement">1.2 Agreement</a>
            <a href="#access">2.2 Your Access</a>
            <a href="#alerts">2.3 Alerts, Notifications and Service Communications</a>
            <a href="#information">3.1 Information Provided to User Not Guaranteed</a>
            <a href="#disclaimer">3.2 Disclaimer Warranties</a>
            <a href="#liability">3.3 Limitation of Liability</a>
            <a href="#dos">4.1 Dos</a>
            <a href="#donts">4.2 Dont's</a>
            <a href="#integration">5.1 Integration & Severability</a>
            <a href="#advice">5.2 Advice may differ</a>
            <a href="#portfolio">5.3 Portfolio Aggregation</a>
            <a href="#communication">5.4 Communication</a>
            <a href="#feedback">5.5 Feedback</a>
            <a href="#questions">5.6 Questions</a>
          </div>
        </div>
         <div style={{ gridColumn: 'span 1 / span 1' }}></div>
        <div className="content">
          <h2>Terms of Use</h2>
          <div className="date">
            <svg width="1em" height="1em" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.75 1.65625H11.7188V0.875C11.7188 0.75068 11.6694 0.631451 11.5815 0.543544C11.4935 0.455636 11.3743 0.40625 11.25 0.40625C11.1257 0.40625 11.0065 0.455636 10.9185 0.543544C10.8306 0.631451 10.7812 0.75068 10.7812 0.875V1.65625H4.21875V0.875C4.21875 0.75068 4.16936 0.631451 4.08146 0.543544C3.99355 0.455636 3.87432 0.40625 3.75 0.40625C3.62568 0.40625 3.50645 0.455636 3.41854 0.543544C3.33064 0.631451 3.28125 0.75068 3.28125 0.875V1.65625H1.25C0.959919 1.65625 0.68172 1.77148 0.476602 1.9766C0.271484 2.18172 0.15625 2.45992 0.15625 2.75V15.25C0.15625 15.5401 0.271484 15.8183 0.476602 16.0234C0.68172 16.2285 0.959919 16.3438 1.25 16.3438H13.75C14.0401 16.3438 14.3183 16.2285 14.5234 16.0234C14.7285 15.8183 14.8438 15.5401 14.8438 15.25V2.75C14.8438 2.45992 14.7285 2.18172 14.5234 1.9766C14.3183 1.77148 14.0401 1.65625 13.75 1.65625ZM1.25 2.59375H3.28125V3.375C3.28125 3.49932 3.33064 3.61855 3.41854 3.70646C3.50645 3.79436 3.62568 3.84375 3.75 3.84375C3.87432 3.84375 3.99355 3.79436 4.08146 3.70646C4.16936 3.61855 4.21875 3.49932 4.21875 3.375V2.59375H10.7812V3.375C10.7812 3.49932 10.8306 3.61855 10.9185 3.70646C11.0065 3.79436 11.1257 3.84375 11.25 3.84375C11.3743 3.84375 11.4935 3.79436 11.5815 3.70646C11.6694 3.61855 11.7188 3.49932 11.7188 3.375V2.59375H13.75C13.7914 2.59375 13.8312 2.61021 13.8605 2.63951C13.8898 2.66882 13.9062 2.70856 13.9062 2.75V5.40625H1.09375V2.75C1.09375 2.70856 1.11021 2.66882 1.13951 2.63951C1.16882 2.61021 1.20856 2.59375 1.25 2.59375ZM13.75 15.4062H1.25C1.20856 15.4062 1.16882 15.3898 1.13951 15.3605C1.11021 15.3312 1.09375 15.2914 1.09375 15.25V6.34375H13.9062V15.25C13.9062 15.2914 13.8898 15.3312 13.8605 15.3605C13.8312 15.3898 13.7914 15.4062 13.75 15.4062Z" fill="currentColor" />
            </svg>
            <p>Policy Effective Date: April 14, 2022</p>
          </div>
          <div id="purpose" className="section">
            <h3>1.1 Purpose</h3>
            <p>Rise Vest Technologies Global Limited ("Rise", "we" or "us") offers automated investment services. Should you choose to become our Client, we will create one or more accounts for you:</p>
          </div>
          <ul>
            <li>A Cash Wallet (the "Cash Account")</li>
            <li>An Invested Account (the "Invested Account")</li>
            <li>Collectively, (the "Account")</li>
          </ul>
          <p>The Cash Wallet is for assets that are liquid and not invested, typically used for emergency or short-term need purposes. The Invested Account provides Users with access to professionally managed asset allocation strategies (each, a "Model") managed by Rise.</p>
          <div id="agreement" className="section">
            <h3>1.2 Agreement</h3>
            <p>When you access our website at www.risevest.com ("our website" or "this website") and/or our mobile applications ("our app" or "this app") as a User, you're agreeing to be bound by the following Terms of Use and if you've elected to become a Client, you are also bound by our Client Agreement - Rise Client Agreement. Please therefore take the time to read the following binding Terms of Use below.</p>
          </div>
          <div id="access" className="section">
            <h3>2.2 Your Access</h3>
            <p>In order to access certain features of this app, you must register to create an account ("User Account"). When you register, you will be asked to choose a password, which you will be required to use to access your User Account. Rise has physical, electronic and procedural safeguards that comply with regulatory standards to guard Users' and Clients' nonpublic personal information (see Privacy Policy). You are responsible for safeguarding your password and other User Account information.<br />You agree not to disclose your password to any third party and you will notify Rise Support immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. As a User you agree that you shall be solely responsible for any activities or actions under your User Account, whether or not you have authorized such activities or actions. You agree that the information you provide to us on Account or User Account registration through our website or our app will be true, accurate, current, and complete.</p>
          </div>
          <div id="alerts" className="section">
            <h3>2.3 Alerts, Notifications and Service Communications</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="information" className="section">
            <h3>3.1 Information Provided to User Not Guaranteed</h3>
            <p>You understand and acknowledge that Rise cannot guarantee any investment results you could obtain from investment information and financial insights it provides prior to you becoming a Client and that Rise is not liable for these results. All investments entail a risk of loss and that you may lose money. Rise may offer other investment management services to individuals who become Clients at its sole discretion. Your election to engage our investment management services are subject to your explicit enrollment and acceptance of the separate Client Agreement and related fee schedule.</p>
          </div>
          <div id="disclaimer" className="section">
            <h3>3.2 Disclaimer Warranties</h3>
            <p>Your use of this app or our website, and the personal information you provide is at your sole discretion and risk. This app and our website and all materials, information, products and services included therein, are provided on an AS IS and AS AVAILABLE basis without warranties of any kind from Rise.</p>
            <p>RISE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THIS APP AND OUR WEBSITE, THEIR CONTENT AND/OR USER INFORMATION, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. RISE DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED:</p>
            <ul>
                <li>REGARDING THE AVAILABILITY, SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THIS APP AND OUR WEBSITE, THEIR CONTENT AND/OR USER INFORMATION;</li>
                <li>THAT THIS APP AND OUR WEBSITE WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED;</li>
                <li>THAT THIS APP AND OUR WEBSITE WILL BE FREE FROM ELECTRONIC VIRUSES; OR</li>
                <li>REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED BY THIS APP OR OUR WEBSITE INCLUDING BUT NOT LIMITED TO INFORMATION OBTAINED THROUGH SOCIAL MEDIA.</li>
            </ul>
            <p> No advice or information you obtain from this app or our website shall create any warranty not expressly stated in this Agreement. If you choose to rely on such information, you do so solely at your own risk. Some countries or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you.</p>
          </div>
          <div id="liability" className="section">
            <h3>3.3 Limitation of Liability</h3>
            <p>IN NO EVENT SHALL RISE OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THIS APP OR OUR WEBSITE, THEIR CONTENT AND/OR USER INFORMATION, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THIS APP OR FOR ANY INVESTMENT DECISIONS MADE ON THE BASIS OF SUCH INFORMATION, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT RISE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>
            <p>You may request termination of your User Account at any time and for any reason by sending an email to hello@risevest.com We may terminate or suspend your access to this app or our website, in our sole discretion, at any time for any reason without notice to you. Further, if we believe, in our sole discretion, that a violation of these Terms of Use has occurred, we may take any other corrective action we deem appropriate. We reserve the right to investigate suspected violations of these Terms of Use. We may seek to gather information from a user who is suspected of violating these Terms of Use (or from any other user) and you agree to provide us with such information. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting, publishing, or otherwise making available any User information, emails, or other materials that are believed to violate these Terms of Use.</p>
            <p>Any suspension, termination, or cancellation shall not affect your obligations to Rise under these Terms of Use (including but not limited to ownership, indemnification, and limitation of liability), which by their sense and context are intended to survive such suspension, termination, or cancellation</p>
            <p>You agree that these Terms of Use shall be governed by and interpreted in accordance with the laws of the Cayman Islands. Any legal action or proceeding arising under these Terms of Use will be brought exclusively in courts located and the parties hereby irrevocably consent to the personal jurisdiction and venue therein.</p>
          </div>
          <div id="dos" className="section">
            <h3>4.1 Dos</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="donts" className="section">
            <h3>4.2 Dont's</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="integration" className="section">
            <h3>5.1 Integration & Severability</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="advice" className="section">
            <h3>5.2 Advice may differ</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="portfolio" className="section">
            <h3>5.3 Portfolio Aggregation</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="communication" className="section">
            <h3>5.4 Communication</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="feedback" className="section">
            <h3>5.5 Feedback</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          <div id="questions" className="section">
            <h3>5.6 Questions</h3>
            <p>By creating a User Account, you automatically sign up for various types of alerts via email and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</p>
          </div>
          {/* Add more sections here */}
          <div className="contact-info">
            <p className="title">Contact Information</p>
            <p>If you have any questions about this Policy, the practices of the App, or your dealings with the App, please contact us at:</p>
            <p>
              Rise Vest Technologies Global Limited<br />
              P.O. Box 31119 Grand Pavilion<br />
              Hibiscus Way, 802 West Bay Road<br />
              KYI-1205<br />
              Cayman Islands
            </p>
            <p>Or</p>
            <p>
              358 Herbert Macaulay Way,<br />
              Yaba, Lagos<br />
              Nigeria<br />
            </p>
            <p>Email: <a href="mailto:hello@risevest.com">hello@risevest.com</a></p>
          </div>
        </div>
      </main>
    </>
  );
};
export default Terms;