export default function PlayStoreLogo(props) {
    return (
        <svg
            width="1.5em"
            height="1.5625em"
            viewBox="0 0 24 25"
            fill="none"
            {...props}
        >
            <g clipPath="url(#play-store-logo-clip-path)" fill="currentColor">
                <path d="M4.67.878A2.353 2.353 0 002.85.563l10.825 10.825 3.508-3.508L4.67.878zM1.507 1.443a2.352 2.352 0 00-.476 1.415v19.283c0 .521.174 1.014.476 1.415L12.563 12.5 1.507 1.443zM21.762 10.441L18.61 8.678 14.787 12.5l3.822 3.822 3.154-1.764a2.331 2.331 0 001.206-2.058 2.33 2.33 0 00-1.207-2.059zM13.675 13.612L2.851 24.436a2.356 2.356 0 001.819-.314l12.514-7.002-3.509-3.508z" />
            </g>
            <defs>
                <clipPath id="play-store-logo-clip-path">
                    <path
                        fill="currentColor"
                        transform="translate(0 .5)"
                        d="M0 0H24V24H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}