import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import './PageHeader.css';
import BackArrowIcon from '../Icons/BackArrowIcon';

const Header = ({ 
  title, 
  subtitle,
  className = '',
  titleClassName = ''
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
      navigate(-1);
  };

  return (
    <header className={`header ${className}`}>
      <Button 
        type="text"
        className="back-button"
        onClick={handleBack}
      >
        <BackArrowIcon />
        <span className="sr-only">Go back</span>
      </Button>

      <div className="title-container">
        <h2 className={`page-title ${titleClassName}`}>
          {title}
        </h2>
        {subtitle && (
          <p className="subtitle">{subtitle}</p>
        )}
      </div>
    </header>
  );
};

export default Header;