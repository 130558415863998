import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BankAccountDetails.css';
import BankIcon from '../Icons/BankIcon';
import PageHeader from '../PageHeader/PageHeader';

const BankAccountDetails = () => {
  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText('7820951274');
  };

  const handleDone = () => {
    navigate('/trackr-home/wallet/fund-wallet/ngn-details');
  };

  return (
    <div className="bank-details">
      <PageHeader />
      <div className="bank-details__content">

        <h2>Naira Account Numbers</h2>
        <p className="bank-details__description">
          Make a transfer to any of these account numbers and your Rise wallet will be funded immediately.
        </p>

        <div className="bank-details__account">
          <div>
            <p className="bank-name">WEMA BANK</p>
            <p className="account-number">7820951274</p>
          </div>
          <button onClick={handleCopy} className="copy-button">
            <span className="sr-only">copy account number</span>
            <BankIcon />
          </button>
        </div>

        <div className="bank-details__rate">
          <div>
            <p>USD Buy Rate</p>
            <p className="rate-description">We buy US dollars at this rate</p>
          </div>
          <span className="rate-amount">₦ 1690</span>
        </div>

        <div className="bank-details__footer">
          <button onClick={handleDone} className="done-button">
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankAccountDetails;