import React from 'react';
import PagesRightSide from "../RegisterPage/PagesRightSide/PagesRightSide";
import './registration.css'
import RegistrationLeft from "./RegistrationLeft";
import {Col, Row} from "antd";

const Registration = () => {
    return (
        <Row>
            <Col xs={24} lg={12}>
                <RegistrationLeft/>
            </Col>
            <Col lg={12}>
                <PagesRightSide/>
            </Col>
        </Row>
    );
};

export default Registration;