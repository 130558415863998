import React from 'react';
import './BuildWealth.css';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '../Icons/InfoIcon';
import Header from '../PageHeader/Header';

const BuildWealth = () => {
  const navigate = useNavigate();

  const handleStartInvesting = () => {
    navigate('start');
  };

  return (
    <div className="build-wealth">
      <div className="build-wealth__container">
        <div className="build-wealth__content">
          <div className="build-wealth__header">
            <Header
            
            />
            <img src={'/tradetrackr/bw.png'} alt="" className="background-image" />
            <img src={'/tradetrackr/bw4.png'} alt="" className="diamond-image" />
          </div>
          
          <section className="info-section">
            <h2>Build Wealth</h2>
            <p>Build Wealth is a long term investment plan to help you retire or reach financial freedom.</p>
            <hr />
            <h2>How it works</h2>
            <div className="info-text">
              <p>Choose a goal and a time frame and we'll break your goal into smaller amounts you can invest regularly until you achieve your target.</p>
              <p>We mix multiple assets into your plan based on your time horizon, with high risk assets in longer dated plans and low risk assets in short term plans. The plan also composition adjusts dynamically as you get closer to your target.</p>
            </div>
            <hr />
            <div className="risk-level">
              <p>Risk level:</p>
              <div className="risk-badge">
                <span>Dynamic</span>
                <InfoIcon className="info-icon" />
              </div>
            </div>
            <div className="info-box">
              <InfoIcon className="info-icon" />
              <p>To manage your investments, Risevest charges a fee of 0.5% per year on your funds.</p>
            </div>
          </section>
          
          <div className="button-container">
            <button 
              className="start-investing-button"
              onClick={handleStartInvesting}
            >
              Start Investing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildWealth;