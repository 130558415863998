import React from 'react';

const Policy = () => {
  return (
    <>
     <section>
        <div className="hero">
          <h1>Privacy Policy</h1>
          <img
            className="dark-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/dark-ball.2a2bff54.webp"
            width="213"
            height="208"
            decoding="async"
            data-nimg="future"
          />
          <img
            className="light-ball"
            role="presentation"
            alt=""
            src="https://risevest.com/_next/static/media/light-ball.6236400f.webp"
            width="462"
            height="252"
            decoding="async"
            data-nimg="future"
          />
        </div>
      </section>

      <main>
        <div className="sidebar">
          <div className="nav">
            <a href="#information">Information We Collect and How We Collect It</a>
            <a href="#automatic">Automatic Information Collection</a>
            <a href="#information-techniques">Information Collection Technologies</a>
            <a href="#third-party">Third-Party Information Collection</a>
            <a href="#how-we-use">How We Use Your Information</a>
            <a href="#disclosure">Disclosure of Your Information</a>
            <a href="#choices">Your Choices About Our Collection, Use and Disclosure of Your Information</a>
            <a href="#correcting">Accessing and Correcting Your Personal Information</a>
            <a href="#links">Links to Other Sites</a>
            <a href="#security">Data Security</a>
            <a href="#retention">Data Retention</a>
            <a href="#changes">Changes to This Policy</a>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="content">
          <h2>Privacy Policy</h2>
          <div className="date">
            <svg width="1em" height="1em" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.75 1.65625H11.7188V0.875C11.7188 0.75068 11.6694 0.631451 11.5815 0.543544C11.4935 0.455636 11.3743 0.40625 11.25 0.40625C11.1257 0.40625 11.0065 0.455636 10.9185 0.543544C10.8306 0.631451 10.7812 0.75068 10.7812 0.875V1.65625H4.21875V0.875C4.21875 0.75068 4.16936 0.631451 4.08146 0.543544C3.99355 0.455636 3.87432 0.40625 3.75 0.40625C3.62568 0.40625 3.50645 0.455636 3.41854 0.543544C3.33064 0.631451 3.28125 0.75068 3.28125 0.875V1.65625H1.25C0.959919 1.65625 0.68172 1.77148 0.476602 1.9766C0.271484 2.18172 0.15625 2.45992 0.15625 2.75V15.25C0.15625 15.5401 0.271484 15.8183 0.476602 16.0234C0.68172 16.2285 0.959919 16.3438 1.25 16.3438H13.75C14.0401 16.3438 14.3183 16.2285 14.5234 16.0234C14.7285 15.8183 14.8438 15.5401 14.8438 15.25V2.75C14.8438 2.45992 14.7285 2.18172 14.5234 1.9766C14.3183 1.77148 14.0401 1.65625 13.75 1.65625ZM1.25 2.59375H3.28125V3.375C3.28125 3.49932 3.33064 3.61855 3.41854 3.70646C3.50645 3.79436 3.62568 3.84375 3.75 3.84375C3.87432 3.84375 3.99355 3.79436 4.08146 3.70646C4.16936 3.61855 4.21875 3.49932 4.21875 3.375V2.59375H10.7812V3.375C10.7812 3.49932 10.8306 3.61855 10.9185 3.70646C11.0065 3.79436 11.1257 3.84375 11.25 3.84375C11.3743 3.84375 11.4935 3.79436 11.5815 3.70646C11.6694 3.61855 11.7188 3.49932 11.7188 3.375V2.59375H13.75C13.7914 2.59375 13.8312 2.61021 13.8605 2.63951C13.8898 2.66882 13.9062 2.70856 13.9062 2.75V5.40625H1.09375V2.75C1.09375 2.70856 1.11021 2.66882 1.13951 2.63951C1.16882 2.61021 1.20856 2.59375 1.25 2.59375ZM13.75 15.4062H1.25C1.20856 15.4062 1.16882 15.3898 1.13951 15.3605C1.11021 15.3312 1.09375 15.2914 1.09375 15.25V6.34375H13.9062V15.25C13.9062 15.2914 13.8898 15.3312 13.8605 15.3605C13.8312 15.3898 13.7914 15.4062 13.75 15.4062Z" fill="currentColor" />
            </svg>
            <p>Last modified: April 21, 2022</p>
          </div>
          <p>We at Rise Vest Technologies Global Limited (“Rise” or “we”) respect your privacy and are committed to protecting it through our compliance with this privacy policy (the “Policy”). This policy describes:</p>
          <div>
            <p>i. THE TYPES OF INFORMATION WE MAY COLLECT OR THAT YOU MAY PROVIDE WHEN YOU DOWNLOAD, REGISTER WITH OR USE THE RISE APPLICATION DOWNLOADABLE TO YOUR MOBILE DEVICE (the “App”).</p>
            <p>ii. OUR PRACTICES FOR COLLECTING, USING, MAINTAINING, PROTECTING AND DISCLOSING THAT INFORMATION.</p>
          </div>
          <p>This Policy applies only to information we collect on the App and in email, text and other electronic communications sent through or in connection with the App. This policy DOES NOT apply to information that:</p>
          <div>
            <p>i. We collect offline or on any other Rise apps or websites including websites you may access through the App,</p>
            <p>ii. You provide to or is collected by any third party (see Third-Party Information Collection).</p>
          </div>
          <p>Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with or use the App. By downloading , registering with or using the App, you agree to this Policy. This Policy may change from time to time (see Changes to This Policy). Your continued use of the App after we revise this Policy means you accept those changes, so please check this Policy periodically for updates.</p>

          
          <div id="information" className="section">
            <h3>Information We Collect and How We Collect It</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="automatic" className="section">
            <h3>Automatic Information Collection</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="information-techniques" className="section">
            <h3>Information Collection Technologies</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="third-party" className="section">
            <h3>Third-Party Information Collection</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="how-we-use" className="section">
            <h3>How We Use Your Information</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="disclosure" className="section">
            <h3>Disclosure of Your Information</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="choices" className="section">
            <h3>Your Choices About Our Collection, Use and Disclosure of Your Information</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="correcting" className="section">
            <h3>Accessing and Correcting Your Personal Information</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="links" className="section">
            <h3>Links to Other Sites</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="security" className="section">
            <h3>Data Security</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="retention" className="section">
            <h3>Data Retention</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>
          <div id="changes" className="section">
            <h3>Changes to This Policy</h3>
            <p>We collect information from and about users of the App directly from you when you provide information to us and automatically when you use the App.</p>
          </div>

          {/* ... Continue with the rest of the sections ... */}

          <div className="section">
            <h3>Contact Information</h3>
            <p>If you have any questions about this Policy, the practices of the App, or your dealings with the App, please contact us at:</p>
            <p>
              Rise Vest Technologies Global Limited<br />
              P.O. Box 31119 Grand Pavilion<br />
              Hibiscus Way, 802 West Bay Road<br />
              KYI-1205<br />
              Cayman Islands
            </p>
            <p>Or</p>
            <p>
              358 Herbert Macaulay Way,<br />
              Yaba, Lagos<br />
              Nigeria<br />
            </p>
            <p>Email: <a href="mailto:hello@risevest.com">hello@risevest.com</a></p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Policy;