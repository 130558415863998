export default function SlantArrowUp({ size = 24, color = 'currentColor', ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z"
        fill={color}
      />
    </svg>
  );
}