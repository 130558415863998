import React from 'react';
import './SelectBank.css';
import { BackButton } from '../Buttons';
import { InfoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import BankIcon from '../Icons/BankIcon';
import Header from '../PageHeader/Header';



const SelectBank = () => {
  const navigate = useNavigate();

  const handleAddBank = () => {
    navigate('add-bank-account');
  };

  return (
    <main className="page-content">
      <div className="select-bank">
        <form>
        <Header
          title="Select Bank"
          
        />

          <div className="info-box">
            <InfoOutlined />
            <p>Withdrawals can only be made to bank accounts that match the name of your Risevest account</p>
          </div>

          <div className="empty-state">
            <div className="icon-container">
              <BankIcon />
            </div>
            <p>You haven't added any bank accounts</p>
          </div>

          <button 
            className="add-bank-button"
            onClick={handleAddBank}
          >
            Add a new bank account
          </button>
        </form>
      </div>
    </main>
  );
};

export default SelectBank;