export default function RiseWalletLogo (){
    return(
        <svg width="116" height="26" viewBox="0 0 116 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7976_42941)">
                <path d="M2.928 13.112L2.832 8.384H0V20.768H2.928V15.896C3 12.128 4.44 10.904 8.304 10.664V8.192C4.848 8.192 3.576 9.512 3.024 13.112H2.928Z" fill="#171C22">
                    </path>
                    <path d="M10.1083 20.768H13.0363V8.384H10.1083V20.768Z" fill="#171C22">
                        </path>
                        <path d="M21.0998 21.152C24.6038 21.152 26.6678 19.712 26.6678 17.288C26.6678 15.44 25.4918 14.384 23.0678 13.856L19.8038 13.16C18.7718 12.92 18.1957 12.392 18.1957 11.72C18.1957 10.736 19.1798 10.136 20.8118 10.136C22.6118 10.136 23.6678 11 23.6678 12.416H26.4757C26.4757 9.632 24.3398 8 20.7157 8C17.4037 8 15.3158 9.488 15.3158 11.792C15.3158 13.664 16.6838 14.84 18.9398 15.296L22.0597 15.968C23.3317 16.256 23.7638 16.664 23.7638 17.408C23.7638 18.416 22.7558 19.016 21.1238 19.016C19.1318 19.016 18.0038 18.2 17.9078 16.736H15.0278C15.1237 19.568 17.2838 21.152 21.0998 21.152Z" fill="#171C22">
                        </path>
                        <path d="M40.4274 14.168C40.4274 10.76 38.0994 8 34.3074 8C30.4914 8 27.9714 10.496 27.9714 14.528C27.9714 18.896 30.5394 21.152 34.4514 21.152C37.2114 21.152 39.9474 19.496 40.3314 16.448H37.6674C37.3554 17.84 36.1794 18.872 34.4514 18.872C32.3394 18.872 30.8514 17.528 30.7794 15.128H40.4274V14.168ZM34.1874 10.28C36.1314 10.28 37.4034 11.48 37.5474 13.472H30.7794C30.9234 11.36 32.3634 10.28 34.1874 10.28Z" fill="#171C22">
                        </path>
                        <path d="M56.416 21L59.008 12.144H59.128L61.72 21H64.72L69.064 8.616H65.944L64.792 11.976C64.168 13.944 63.688 15.336 63.064 17.496H62.944C62.344 15.24 61.96 13.872 61.408 11.952L60.424 8.616H57.736L56.728 11.952C56.2 13.872 55.792 15.24 55.192 17.496H55.072C54.448 15.336 53.968 13.944 53.344 11.976L52.192 8.616H49.072L53.416 21H56.416ZM69.0994 17.952C69.0994 20.256 70.8994 21.384 73.2994 21.384C75.7954 21.384 77.9074 20.016 78.2194 17.064H78.3394L78.4594 21H81.2194V13.056C81.2194 10.44 79.6114 8.232 75.4114 8.232C71.3554 8.232 69.2914 10.68 69.1954 13.464H72.0994C72.2434 11.832 73.2754 10.512 75.4114 10.512C77.6194 10.512 78.3394 11.664 78.3394 12.6C78.3394 14.016 77.1874 14.424 74.0914 14.376C70.6834 14.328 69.0994 15.792 69.0994 17.952ZM72.0994 17.688C72.0994 16.68 72.8194 16.08 73.9714 16.032C77.1874 15.864 77.9794 15.216 78.2194 13.752H78.3394V15.648C78.3394 17.496 76.6354 19.344 74.3074 19.368C72.8194 19.368 72.0994 18.744 72.0994 17.688ZM83.6224 21H86.5504V4.2H83.6224V21ZM89.0131 21H91.9411V4.2H89.0131V21ZM106.332 14.4C106.332 10.992 104.004 8.232 100.212 8.232C96.3957 8.232 93.8757 10.728 93.8757 14.76C93.8757 19.128 96.4437 21.384 100.356 21.384C103.116 21.384 105.852 19.728 106.236 16.68H103.572C103.26 18.072 102.084 19.104 100.356 19.104C98.2437 19.104 96.7557 17.76 96.6837 15.36H106.332V14.4ZM100.092 10.512C102.036 10.512 103.308 11.712 103.452 13.704H96.6837C96.8277 11.592 98.2677 10.512 100.092 10.512ZM115.157 8.616H111.653L111.557 5.376H108.773V8.616H106.757V10.488H108.773V17.688C108.773 20.424 110.021 21.12 112.829 21.144C113.525 21.144 114.293 21.096 115.157 21.024V18.72C114.533 18.768 114.029 18.792 113.501 18.792C112.133 18.792 111.701 18.504 111.701 17.256V10.488H115.157V8.616Z" fill="#171C22">
                        </path>
                        <path d="M44.9158 11.9446C45.9739 11.9446 46.8316 11.0616 46.8316 9.97232C46.8316 8.88304 45.9739 8 44.9158 8C43.8577 8 43 8.88304 43 9.97232C43 11.0616 43.8577 11.9446 44.9158 11.9446Z" fill="#171C22">
                        </path>
            </g>
                    <defs>
                <clipPath id="clip0_7976_42941"><rect width="116" height="26" fill="white">
            </rect></clipPath>
            </defs>
        </svg>
    );
}