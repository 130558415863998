import React from 'react';

const ContactIcon = ({ className }) => {
  return (
    <svg 
      className={className} 
      focusable="false" 
      aria-hidden="true" 
      viewBox="0 0 24 24"
    >
      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2"></path>
    </svg>
  );
};

export default ContactIcon;