import { forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import MenuIcon from '../Icons/MenuIcon';
import styles from './style.module.css';
import CloseIcon from '../Icons/CloseIcon';

const MenuButton = forwardRef(({ close = false }, ref) => {
    const sidebarRef = useRef();
    const triggerRef = useRef();

    const isOpen = () => {
        return sidebarRef.current.classList.contains('active');
    };

    const toggle = (open) => {
        const sidebar = document.querySelector('.SideMenu');
        
        if (open === undefined) {
            sidebar.classList.toggle('active');
        } else {
            if (open) {
                sidebar.classList.add('active');
            } else {
                sidebar.classList.remove('active');
            }
        }
         
        if (isOpen()) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        toggle();
    };

    useImperativeHandle(ref, () => ({
        handleClick: toggle
    }));

    useEffect(() => {
        const sidebar = document.querySelector('.SideMenu');
        sidebarRef.current = sidebar;

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    useEffect(() => {
        const toggleIfOpen = (e) => {
            if (!sidebarRef.current.contains(e.target)) {
                toggle(false);
            }
        };
        document.addEventListener('click', toggleIfOpen);
        return () => {
            document.removeEventListener('click', toggleIfOpen);
        };
    }, []);

    const className = styles['menu-button'] + (close ? ' ' + styles.close : '');

    return (
        <button id="menu-button" ref={triggerRef} className={className} onClick={handleClick}>
            {
                close ? <CloseIcon /> : <MenuIcon />
            }
        </button>
    );
});

export default MenuButton;