import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import "./statisticsPortfolio.css"
import { fetchCurrentAccountStatistics } from '../../API/accountsService';
import { store } from '../../redux/store';
import { currentTinkoffAccountSelector } from '../../redux/slices/commonSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const currentDate = dayjs();
const formattedMonth = currentDate.locale('ru').format('MMMM');
const currentYear = dayjs().locale('ru').format('YYYY');

const { Title } = Typography;

const StatisticsPortfolioSection = () => {
    const state = store.getState();
    const [selectedAccount, setSelectedAccount] = useState(currentTinkoffAccountSelector(state));
    const [startDate, setStartDate] = useState(new Date());
    const inputRef = useRef(null);

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState();
            setSelectedAccount(currentTinkoffAccountSelector(state));
        });
    }, [setSelectedAccount]);

    useEffect(() => {
        const fetchStatistics = async () => {
            const stats = await fetchCurrentAccountStatistics(selectedAccount.id);
            console.log(stats);
            // Do something with the stats data
        };

        fetchStatistics().catch(console.error);
    }, [selectedAccount]);

    const colorFromValue = (value) => value === 0 ? 'transparent' : value < 0 ? '#FD3549' : '#42CC7D';


    return (
        <div className='statistics-container'>

            <div>
                <div className='statistics-title-line'>
                    <Title level={2} className='statistics-title-line-text2'>Статистика за </Title>
                    <span className='statistics-title-line-text'>
                        {startDate.toLocaleDateString('ru-RU', { year: 'numeric', month: 'long' })}
                    </span>
                    <span className='statistics-title-line-calendar'>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="hidden-datepicker"
                            showMonthYearPicker={true}
                            customInput={<CalendarImage />}
                        />
                    </span>
                </div>

                <div>
                    <Row gutter={{ xs: 8, sm: 12, md: 20, lg: 25 }}>
                        <Col span={7.333}>
                            <Card bordered={false} className='statistics-card'>
                                <Statistic
                                    title={<div>
                                        <span className='statistics-title-month'>{formattedMonth}</span>
                                        <span className='statistics-title-day'>{currentYear}</span>
                                    </div>}
                                    value={'Операций нет'}
                                    precision={3}
                                    valueStyle={{ color: '#898989', fontWeight: 300, fontSize: 16, display: 'flex' }}

                                />
                            </Card>
                        </Col>
                        <Col span={7.333}>
                            <Card bordered={false} className='statistics-card'>
                                <Statistic
                                    title={<div>
                                        <span className='statistics-title-month'>{formattedMonth}</span>
                                        <span className='statistics-title-day'>{currentYear}</span>
                                        <span className='statistics-title-details'>подробнее</span>
                                    </div>}
                                    value={'51020.93 ₽'}
                                    precision={3}
                                    valueStyle={{ background: colorFromValue(51020.93) }}
                                />
                            </Card>
                        </Col>
                        <Col span={7.333}>
                            <Card bordered={false} className='statistics-card'>
                                <Statistic
                                    title={<div>
                                        <span className='statistics-title-month'>{formattedMonth}</span>
                                        <span className='statistics-title-day'>{currentYear}</span>
                                        <span className='statistics-title-details'>подробнее</span>
                                    </div>}
                                    value={'-51020.93 ₽'}
                                    precision={3}
                                    valueStyle={{ background: colorFromValue(-51020.93) }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

const CalendarImage = forwardRef(({onClick}, ref) => (
    <img src='/statistics-page-images/Calendar.svg' alt='icon' onClick={onClick} ref={ref} />
));

export default StatisticsPortfolioSection;
