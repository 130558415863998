import React from 'react';

const ChevronRightIcon = ({ className }) => {
  return (
    <svg 
      viewBox="0 0 24 24" 
      className={className}
      width="20"
      height="20"
    >
      <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z" />
    </svg>
  );
};

export default ChevronRightIcon;