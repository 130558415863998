import React from 'react';
import './NotificationsPage.css';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../Buttons';

const NotificationsPage = () => {
  const notifications = [
    {
      type: 'article',
      title: 'Nigeria at 64: Reflecting on Six Defining Moments of Strength, Unity, and Progress',
      date: '7 days ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727772759173.jpg',
      link: '6c056c44-560d-4347-9ff6-8eb4ee9a9435'
    },
    {
      type: 'article',
      title: 'My Name is Eric and this is How i Make My #MoneyRise',
      date: '8 days ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727691855411.png',
      link: '7d6dc224-1614-43c5-b8e3-7c5795091f4a'
    },
    {
      type: 'article',
      title: 'Why Cinemas Numbers in Nigeria are Dropping',
      date: '13 days ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1727259124603.jpg',
      link: '0e262a8b-b503-40a1-93a8-d655d66d9d89'
    },
    {
      type: 'article',
      title: 'Rise Acquires Hisa',
      date: '19 days ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1726755927889.png',
      link: 'a3ea5ab3-fe33-4707-83dc-36afc6d47de9'
    },
    {
      type: 'planFunded',
      amount: '-$76.63',
      date: '25 days ago',
      link: '/plans/550664'
    },
    {
      type: 'walletFunded',
      amount: '+$76.55',
      date: '25 days ago',
      link: '/wallet'
    },
    {
      type: 'article',
      title: 'Risevest August Portfolio Report',
      date: '28 days ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1725973803428.jpg',
      link: '3a68e5d4-061a-47ca-b838-7b209151720f'
    },
    {
      type: 'planMatured',
      planName: 'Rema Wedding',
      date: 'about 1 month ago',
      link: '/plans/363911'
    },
    {
      type: 'article',
      title: 'The Power of Compounding: The Eighth Wonder of Wealth Creation',
      date: 'about 1 month ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1724836123296.jpg',
      link: '682203f7-3ff5-4660-924a-0dc52944aadd'
    },
    {
      type: 'article',
      title: '"As A Frequent Traveller, I Use My Rise Account Statement As Proof Of Funds To Apply For Travel Visas" — Aremu on investing with Rise.',
      date: 'about 1 month ago',
      imageUrl: 'https://rise-feeds-articles.s3.eu-west-1.amazonaws.com/1724664104028.jpg',
      link: 'c9724841-354c-493e-bae3-7512716502ac'
    }
  ];

  const renderNotification = (notification) => {
    switch (notification.type) {
      case 'article':
        return (
          <div className="notification article">
            <div className="notification-header">
              <div className="circular-container" style={{backgroundImage: `url(${notification.imageUrl})`}}></div>
              <p>New Article • {notification.date}</p>
            </div>
            <div className="notification-image" style={{backgroundImage: `url(${notification.imageUrl})`}}></div>
            <p>{notification.title}</p>
            <a href={notification.link} className="button">Read Article</a> 
          </div>
        );
      case 'planFunded':
        return (
          <div className="notification plan-funded">
            <div className="notification-header">
              <div className="circular-container">
                <span className="icon">-</span>
              </div>
              <p>Plan Funded • {notification.date}</p>
            </div>
            <div className="amount-container">
              <p className="amount">{notification.amount}</p>
            </div>
            <p>You just funded your plan successfully with {notification.amount}.</p>
            <a href={notification.link} className="button">View Plan</a>
          </div>
        );
      case 'walletFunded':
        return (
          <div className="notification wallet-funded">
            <div className="notification-header">
              <div className="circular-container">
                <span className="icon">+</span>
              </div>
              <p>Wallet Funded • {notification.date}</p>
            </div>
            <div className="amount-container">
              <p className="amount">{notification.amount}</p>
            </div>
            <p>You just added {notification.amount} to your Rise Wallet. Well done Adeogo.</p>
            <a href={notification.link} className="button">View Wallet Balance</a>
          </div>
        );
      case 'planMatured':
        return (
          <div className="notification plan-matured">
            <div className="notification-header">
              <div className="circular-container">
                <span className="icon">-</span>
              </div>
              <p>Your plan has matured • {notification.date}</p>
            </div>
            <div className="amount-container">
              <p className="amount">-$</p>
            </div>
            <p>Your {notification.planName} plan, {notification.planName} has matured, funds remitted into your wallet and closed. If you would like to reinvest this plan, go to your closed plans page to do that.</p>
            <a href={notification.link} className="button">View Plan</a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="notifications-page">
      <header>
        <BackButton />
        <h2>Notifications</h2>
      </header>
      <div className="notifications-container">
        {notifications.map((notification, index) => (
          <div key={index} className="notification-wrapper">
            {renderNotification(notification)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsPage;