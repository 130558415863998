import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IncomeForm.css';
import { BackButton } from '../Buttons';
import Header from '../PageHeader/Header';

const IncomeForm = () => {
  const [income, setIncome] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (income) {
      navigate('savings-form');
    }
  };

  const handleIncomeChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) return;
    if (parts[1]?.length > 2) return;
    
    setIncome(value);
  };

  const progressPercentage = 40;

  return (
    <div className="income-form">
      <form onSubmit={handleSubmit}>
        <div className="income-form__content">
          <Header
            title="Income"
            
          />

          <div className="income-form__progress">
            <p className="income-form__progress-text">Question 2 of 5</p>
            <div className="income-form__progress-bar">
              <div 
                className="income-form__progress-fill"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>

          <p className="income-form__question">
            How much do you earn monthly?
          </p>

          <div className="income-form__input-wrapper">
            <div className="income-form__input-container">
              <span className="income-form__currency">$</span>
              <input
                type="text"
                inputMode="decimal"
                placeholder="0.00"
                value={income}
                onChange={handleIncomeChange}
                aria-label="Monthly income"
                className="income-form__input"
              />
            </div>
            <p className="income-form__helper-text">
              This is a required field
            </p>
          </div>

          <button
            type="submit"
            className={`income-form__submit-button ${!income ? 'income-form__submit-button--disabled' : ''}`}
            disabled={!income}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default IncomeForm;