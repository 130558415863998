import axiosClient, { publicAxiosClient } from "../axiosClient";
import { addAuth, refreshTokenSelector, removeAuth, tokenSelector } from "../redux/slices/authSlice";
import { addTinkoffAccounts, setExistTinkoffToken } from "../redux/slices/commonSlice";
import { store } from "../redux/store";

export async function register(payload) {
    return publicAxiosClient.post('/auth/register', payload);
}

export async function login(payload) {
    const resp = await publicAxiosClient.post('/auth/login', payload);
    return resp;
}

export async function registerWithTelegram(payload) {
    return publicAxiosClient.post('/auth/register-with-telegram', payload);
}

export async function loginWithTelegram(payload) {
    const resp = await publicAxiosClient.post('/auth/login-with-telegram', payload);
    store.dispatch(addAuth(resp));
    return resp;
}

export function logout() {
    store.dispatch(removeAuth());
    store.dispatch(addTinkoffAccounts([]));
    store.dispatch(setExistTinkoffToken(false));
    axiosClient.defaults.headers.common.Authorization = '';
}


export async function refreshToken() {
    const data = await publicAxiosClient.post('/auth/refresh-token', {
        refreshToken: refreshTokenSelector(store.getState())
    });
    return data.accessToken;
}

export async function resetPassword(payload) {
    return axiosClient.post('/auth/reset-password', payload);
}

export async function requestPasswordReset(email) {
    return axiosClient.post(`/auth/send-password-reset?email=${email}`);
}

export async function saveTinkoffToken(token) {
    return axiosClient.put(`/tinkoffApiToken?isSandbox=false`, { token });	
}