import React from 'react';
import './index.css';
import StandardLayout from '../../Component/StandardLayout';
import Disclose from './Disclose';


export default function Disclosure() {
  return (
    <StandardLayout>
    <div className="disclose">
      <Disclose />
    </div>
    </StandardLayout>
  );
}