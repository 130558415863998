import React, { useState } from 'react';
import './WalletWithdraw.css';
import { useNavigate } from 'react-router-dom';
import { NigerianFlag } from '../CurrencyFlags/CurrencyFlags';
import Header from '../PageHeader/Header';
import ExchangeRateModal from '../ExchangeRateModal/ExchangeRateModal';

const WalletWithdraw = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleAccept = () => {
    setShowModal(false);
    navigate('withdraw');
  };

  return (
     <div className="wallet-withdraw">
      <div className="currency-grid">
        <div className="currency-content">

            <Header
              title="Select currency"
              
              containerClassName="header-container"
              titleClassName="header-title"
              backButtonClassName="back-button"
              backIconClassName="back-icon"
            />
          
          <div className="currency-list">
            <div className="currency-option">
              <div className="option-content">
                <NigerianFlag />
                <div className="currency-info">
                  <span className="currency-name">
                    Nigerian Naira (₦)
                  </span>
                </div>
              </div>
              <button
                className="select-button"
                onClick={() => setShowModal(true)}
              >
                <span className="sr-only">Go to withdrawal methods</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {showModal && (
        <ExchangeRateModal 
          onClose={() => setShowModal(false)}
          onAccept={handleAccept}
        />
      )}
    </div>
  );
};

export default WalletWithdraw;