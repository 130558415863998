import React from 'react';
import './SavedCardModal.css';
import { CloseButton } from '../Buttons';

const SaveCardModal = ({ onClose, onSave, onDontSave }) => {
  return (
    <div className="save-card-modal">
      <div className="save-card-modal__content">
        <div className="save-card-modal__header">
          <CloseButton onClick={onClose} />
          <h2>Save this card?</h2>
        </div>

        <p className="save-card-modal__description">
          Would you like to save the card information you are about to enter as a means of future funding?
        </p>

        <button 
          className="save-card-modal__button save-card-modal__button--primary"
          onClick={onSave}
        >
          Yes, save for later
        </button>

        <button 
          className="save-card-modal__button save-card-modal__button--secondary"
          onClick={onDontSave}
        >
          No, don't save this card
        </button>
      </div>
    </div>
  );
};

export default SaveCardModal;