import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PortfolioView.css';
import BackArrowIcon from '../Icons/BackArrowIcon';
import FixedIncomeIcon from '../Icons/FixedIncomeIcon';
import DonutChart from '../DonutChart/DonutChart';
import Header from '../PageHeader/Header';


const PortfolioView = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [allocation, setAllocation] = useState({
    fixedIncome: 100
  });

  const handleContinue = () => {
    navigate('review', { 
      state: { allocation } 
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
    navigate('edit', { 
      state: { allocation } 
    });
  };

  const handleAllocationChange = (value) => {
    const newValue = Math.max(0, Math.min(100, value));
    setAllocation({
      fixedIncome: newValue
    });
  };

  return (
    <div className="portfolio-view">
      <Header
            title="Your Portfolio"
            
          />

      <p className="subtitle">
        Based on your answers, this is the kind of investor you are.
      </p>

      <div className="profile-box">
        <div className="profile-icon">🌲</div>
        <h2>Conservative Investor</h2>
        <p>We'll recommend a portfolio of assets to match your risk appetite.</p>
      </div>

      <div className="distribution-header">
        <div className="line"></div>
        <p>distribution</p>
        <div className="line"></div>
      </div>

      <div className="chart-section">
        <DonutChart />
        <div className="chart-center-icon">🌲</div>
      </div>

      <div className="asset-info">
        <div className="asset-item">
          <div className="asset-icon">
            <FixedIncomeIcon />
          </div>
          <div className="asset-text">
            <p className="asset-name">Fixed Income</p>
            {isEditing ? (
              <input
                type="number"
                value={allocation.fixedIncome}
                onChange={(e) => handleAllocationChange(parseFloat(e.target.value))}
                className="asset-input"
                min="0"
                max="100"
              />
            ) : (
              <p className="asset-value">{allocation.fixedIncome}%</p>
            )}
          </div>
        </div>
      </div>

      <div className="button-group">
        <button 
          className="btn btn-primary"
          onClick={handleContinue}
        >
          Continue
        </button>
        <button 
          className="btn btn-secondary"
          onClick={handleEdit}
        >
          Edit Portfolio
        </button>
      </div>
    </div>
  );
};

export default PortfolioView;