import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ConfirmAmount from '../ConfirmAmount/ConfirmAmount';
import ChooseCard from '../ChooseCard/ChooseCard';

const ConfirmAmountRoute = () => {
  return (
    <Routes>
      <Route index element={<ConfirmAmount />} />
      <Route path="confirmed" element={<ChooseCard />} />
    </Routes>
  );
};

export default ConfirmAmountRoute;