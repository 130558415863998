import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './subscription.css';

const features = [
  { text: 'Период обновления операций из API', investor: '1 раз в сутки', trader: 'каждые 20 минут', premium: 'каждые 5 минут' },
  { text: 'Турбо период обновления операций из API', investor: '', trader: 'каждые 5 минут', premium: 'каждые 1-2 минуты', detail: 'для двух счетов' },
  { text: 'Includes essential features to get started', investor: '✔', trader: '✔', premium: '✔' },
  { text: 'Статистика по фьючерсам', investor: '', trader: '', premium: '✔' },
  { text: 'Дневник Инвестора', investor: '', trader: '', premium: '✔' },
  { text: 'Кол-во брокерских счетов доступных для обновления', investor: '2 счет', trader: '3 счет', premium: '5 счет' },
  { text: 'Telegram бот-помощник', investor: '✔', trader: '✔', premium: '✔' },
  { text: 'Обновление операций по требованию', investor: '', trader: '✔', premium: '✔' },
  { text: 'Уведомление о новых операциях', investor: '', trader: '', premium: '✔' },
  { text: 'Уведомление о новых бумагах', investor: '', trader: '✔', premium: '✔' },
  { text: 'Приоритетная поддержка', investor: '✔', trader: '✔', premium: '✔' },
]

const packs = [
  { id: 0, name: 'Инвестор', price: '99' },
  { id: 1, name: 'Трейдер', price: '249' },
  { id: 2, name: 'Премиум', price: '799' },
];


export default function Subscription() {
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [isLaptop, setIsLaptop] = useState(false);

  const handleClick = () => {
    console.log('click');
  }

  const handleChange = () => {
    console.log('change');
  }

  const handleSwipe = (swiper) => {
    setSelectedPlan(swiper.activeIndex);
  }

  useEffect(() => {
    const media = window.matchMedia('(max-width: 1120px)');
    const listener = () => setIsLaptop(media.matches);
    listener();
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, []);
  
  return (
    <div className='subscription-page'>
      <h1 className='page-title'>Гибкие тарифы, для каждого инвестора</h1>

      <RadioGroup onChange={handleChange} />

      <div className="thead-left-container">
        <THeadLeft />
      </div>
      {
        isLaptop && <Swiper
          spaceBetween={20}
          slidesPerView={1.5}
          centeredSlides={true}
          slidesOffsetAfter={100}
          initialSlide={selectedPlan}
          onSlideChange={handleSwipe}
        >
          {
            packs.map((pack, index) => (
              <SwiperSlide key={pack.name}>
                <OptionCard {...pack} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      }
      <div className="sub-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>
                <THeadLeft />
              </th>
              {
                packs.map(({ name, id }, index) => (
                  <TableHeadItem key={name} isSelected={selectedPlan === id} name={name} onClick={handleClick} />
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              features.map(({ text, investor, trader, premium, detail }, index) => {
                const cn0 = `content-cell ${selectedPlan === 0 ? 'content-cell--active' : ''}`,
                  cn1 = `content-cell ${selectedPlan === 1 ? 'content-cell--active' : ''}`,
                  cn2 = `content-cell ${selectedPlan === 2 ? 'content-cell--active' : ''}`;

                return (
                  <tr key={text} className={selectedPlan === 0 ? 'active' : ''}>
                    <td>{text}</td>
                    <td className={cn0}>
                      {investor !== '✔' ? investor : <img src="./subscription/subscription-check.svg" alt="Checkmark" />}
                    </td>
                    <td className={cn1}>
                      {trader !== '✔' ? trader : <img src="./subscription/subscription-check.svg" alt="Checkmark" />}
                    </td>
                    <td className={cn2}>
                      <div>
                        {premium !== '✔' ? premium : <img src="./subscription/subscription-check.svg" alt="Checkmark" />}
                        {detail && <div className='cell-detail'>{detail}</div>}
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td className='tfoot-first'>
                <div>Стоимость подписки</div>
                <p>при оплате на год-скидка 18%</p>
              </td>
              {
                packs.map(({ price, id }) => (
                  <td key={id} className={'tfoot-option' + (selectedPlan === id ? ' tfoot-option--active' : '')}>
                    <div>{price} ₽/мес</div>
                    <button>Сменить тариф</button>
                  </td>
                ))
              }
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

function TableHeadItem({ name, isSelected, onClick }) {
  return (
    <th onClick={onClick} className={`th-item ${isSelected ? 'th-item--active' : ''}`}>
      <h1>{name}</h1>
      <button onClick={onClick}> Выберите этот план </button>
    </th>
  )
}

function THeadLeft() {
  return (
    <div className='thead-left'>
      <div className='thead-left__compare'>
        <span>Compare plans</span>
        <span className="thead-left__badge">40% Off</span>
      </div>
      <div className='thead-left__choose'>
        Choose your workspace plan <br /> according to  your organisational plan
      </div>
    </div>
  );
}

function OptionCard({ name, price }) {
  return (
    <div className='option-card'>
      <h1>{name}</h1>
      <div className='option-card__middle'>
        <p className='option-card__price'>{price} ₽/мес</p>
        <p className='option-card__subtitle'>Скидка 18% при оплате на год</p>
        <button className='option-card__custom-btn'>Сменить тариф</button>
      </div>
      <button className='option-card__select-btn'>Выберите этот план</button>
    </div>
  );
}

function RadioGroup({ onChange }) {
  const [value, setValue] = useState('left');

  const handleChange = (e) => {
    const nValue = e.target.value;
    setValue(nValue);
    onChange(nValue);
  }

  return (
    <Radio.Group value={value} onChange={handleChange}>
      <Radio.Button value="left">
        Оплата за год
      </Radio.Button>
      <Radio.Button value="right">
        Оплата в месяц
      </Radio.Button>
    </Radio.Group>
  )
}
