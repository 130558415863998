import React from 'react';
import { List, Progress } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import './AssetBreakDownList.css';
import FixedIncomeIcon from '../Icons/FixedIncomeIcon';

const StockIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 11 14" fill="currentColor">
    <path d="M2.896 8.098l-1.178 4.594c-.124.485.491.816.846.456l7.996-8.124c.305-.31.08-.825-.36-.825H7.226L8.34.941A.49.49 0 007.866.3H3.2a.498.498 0 00-.48.353l-2 6.824a.49.49 0 00.48.621h1.696zM6.06 4.532a.49.49 0 00.474.642h2.494L3.144 11.15l.878-3.42a.49.49 0 00-.485-.606H1.863l1.714-5.85h3.596L6.059 4.533z" />
  </svg>
);

const assetData = [
  {
    name: 'Real Estate',
    percentage: 37.92,
    amount: 76.63,
    color: 'rgb(8, 152, 160)',
    icon: <HomeOutlined />
  },
  {
    name: 'Stocks',
    percentage: 62.08,
    amount: 125.44,
    color: 'rgb(134, 109, 197)',
    icon: <StockIcon />
  },
  {
    name: 'Fixed Income',
    percentage: 0,
    amount: 0,
    color: 'rgb(188, 16, 88)',
    icon: <FixedIncomeIcon />
  }
];

const AssetBreakdownList = () => (
  <List
    className="asset-breakdown-list"
    itemLayout="horizontal"
    dataSource={assetData}
    renderItem={(item) => (
      <List.Item>
        <div className="asset-item">
          <div className="asset-info">
            <div className="asset-icon" style={{ backgroundColor: `${item.color}10`, color: item.color }}>
              {item.icon}
            </div>
            <div>
              <p>{item.name}</p>
              <div className="asset-percentage">
                <Progress 
                  percent={item.percentage} 
                  showInfo={false} 
                  strokeColor={item.color}
                  trailColor="transparent"
                />
                <span style={{ color: item.color }}>{item.percentage.toFixed(2)}%</span>
              </div>
            </div>
          </div>
          <div className="asset-amount">${item.amount.toFixed(2)}</div>
        </div>
      </List.Item>
    )}
  />
);

export default AssetBreakdownList;