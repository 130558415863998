import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DebitCardFunding from '../DebitCardFunding/DebitCardFunding';
import ConfirmAmount from '../ConfirmAmount/ConfirmAmount';
import ConfirmAmountRoute from '../ConfirmAmountRoute/ConfirmAmountRoute';

const DebitCardFundingRoute = () => {
  return (
    <Routes>
      <Route index element={<DebitCardFunding />} />
      <Route path="confirm/*" element={<ConfirmAmountRoute />} />
    </Routes>
  );
};

export default DebitCardFundingRoute;