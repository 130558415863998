import React from 'react';

const ClockIcon = ({ className }) => {
  return (
    <svg 
      width="18" 
      height="19" 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path 
        d="M9 16.25C12.7279 16.25 15.75 13.2279 15.75 9.5C15.75 5.77208 12.7279 2.75 9 2.75C5.27208 2.75 2.25 5.77208 2.25 9.5C2.25 13.2279 5.27208 16.25 9 16.25Z" 
        stroke="#71879C" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M9 5.5625V9.5H12.9375" 
        stroke="#71879C" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;