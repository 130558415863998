import React from 'react';
import './InputWrapper.css';

const InputWrapper = ({ 
  id, 
  label, 
  value, 
  onChange, 
  type = 'text',
  placeholder = ' ' 
}) => {
  return (
    <div className="input-wrapper">
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default InputWrapper;