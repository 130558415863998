import React from 'react';
import './WalletPage.css';
import RiseWalletLogo from '../Icons/RiseWalletLogo';
import NotificationsIcon from '../Icons/NotificationIcon';
import VisibilityOffIcon from '../Icons/VisibilityOffIcon';
import AddIcon from '../Icons/AddIcon';
import KeyboardArrowRightIcon from '../Icons/KeyboardArrowRightIcon';
import WithdrawIcon from '../Icons/WithdrawIcon';
import TransferIcon from '../Icons/TransferIcon';
import SlantArrowDown from '../Icons/SlantArrowDown';
import SlantArrowUp from '../Icons/SlantArrowUp';
import { useNavigate } from 'react-router-dom';

const Wallet = () => {
  const navigate = useNavigate();
  const handleWithdraw = () => {
    navigate('withdraw-wallet');
  };

  const handleFundWallet = () => {
     navigate('fund-wallet');
  };

  const handleTransfer = () => {
    navigate('transfer-wallet');
  };
  return (
    <div className="wallet">
      <header className="wallet-header">
        <h1 className="sr-only">Wallet</h1>
        <RiseWalletLogo />
        <button className="notifications-button">
          <span className="sr-only">Go to notifications page</span>
          <NotificationsIcon />
          <span className="badge">24</span>
        </button>
      </header>
      
      <div className="wallet-content">
        <div className="balance-section">
          <p className="balance-header">
            <span>Wallet Balance</span>
            <button className="hide-balance-button">
               <VisibilityOffIcon color="#00a8a8" />
            </button>
          </p>
          <p className="balance-amount">$0.00</p>
        </div>
        
        <div className="action-buttons">
          <ActionButton onClick={handleWithdraw} icon={<WithdrawIcon />} text="Withdraw" />
          <ActionButton onClick={handleFundWallet} icon={<AddIcon/>} text="Fund wallet" primary />
          <ActionButton onClick={handleTransfer} icon={<TransferIcon />} text="Transfer" />
        </div>

        <div className="linked-card">
          <div>
            <h3>No linked card</h3>
            <p>You haven't linked a card to add money</p>
          </div>
          <a href="/wallet/link-card" className="link-card-button">
            Link card 
            <KeyboardArrowRightIcon />
          </a>
        </div>

        <a href="/wallet/wallet-interest" className="wallet-interest">
          <div>
            <p>
              <span>Wallet Interest • 8% P/A</span>
              <span className="new-badge">NEW</span>
            </p>
            <p className="interest-amount">$0.01</p>
          </div>
          <KeyboardArrowRightIcon />
        </a>

        <div className="recent-transactions">
          <h2>Recent Transactions</h2>
          <div className="transactions-list">
            <TransactionItem 
              icon={<SlantArrowDown size={18} color="#00a8a8" />}
              title="Transfer to Plan(Remade)"
              date="Sep 13, 2024"
              amount="- $76.63"
              href="/transactions/1"
            />
            <TransactionItem 
              icon={< AddIcon size={18} color="#00a8a8"/>}
              title={
                <>
                  Wallet Fund from BankAccount (09017314776) - EMMANUEL <br /> OLADIPO ADEOGO
                </>
              }
              date="Sep 13, 2024"
              amount="+ $76.55"
              href="/transactions/2"
            />
            <TransactionItem 
              icon={<SlantArrowUp size={18} color="#00a8a8"  />}
              title="Payout from matured Plan (Rema Wedding)"
              date="Sep 6, 2024"
              amount="+ $0.08"
              href="/transactions/3"
            />
            <TransactionItem 
              icon={<SlantArrowDown size={18} color="#00a8a8"  />}
              title="Transfer to Plan(EmmaG Risking It)"
              date="Jul 8, 2024"
              amount="- $32.17"
              href="/transactions/4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ActionButton = ({ onClick, icon, text, primary }) => (
  <button onClick={onClick} className="action-button">
    <span className={`icon-container ${primary ? 'primary' : ''}`}>
      {icon}
    </span>
    <span>{text}</span>
  </button>
);

const TransactionItem = ({ icon, title, date, amount, href }) => (
  <a href={href} className="transaction-item">
    <div className="transaction-icon">{icon}</div>
    <div className="transaction-details">
      <p className="transaction-title">{title}</p>
      <p className="transaction-date">{date}</p>
    </div>
    <span className="transaction-amount">{amount}</span>
  </a>
);

export default Wallet;