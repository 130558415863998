import React from 'react';
import './BigPlanCard.css';
import ArrowIcon from '../Icons/ArrowIcon';

const BigPlanCard = ({
  href,
  title,
  balance,
  percentage,
  amount,
  backgroundImage
}) => {
  const isNegative = parseFloat(percentage) < 0;

  return (
    <a 
      className="plan-card2"
      data-test-id="user-plan"
      href={href}
      style={{ 
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="card-content2">
        <div className="stats">
            <div className="left-stats">
            <h3 className="title">{title}</h3>
            <span className="balance" data-test-id="plan-balance">${balance}</span>
            </div>
            <div className="right-stats">
            <div className="percentage">
                <ArrowIcon className={`arrow-icon ${isNegative ? 'negative' : ''}`} />
            </div>
            <span className="amount">${amount}</span>
            </div>
        </div>
        </div>
    </a>
  );
};

export default BigPlanCard;