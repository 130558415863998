import React from 'react'
import { CtaButton } from '../../Component/Buttons';

function JoinCommunity() {
  return (
    <div className="joinc container wide">
      <div className="container wide joinc__wrapper-1">
        <div className="joinc__wrapper-2">
          <article className="joinc__article">
            <h2 className="joinc__title">
              Join the Rise Community
            </h2>
            <p className="joinc__description">
              Our Telegram community surrounds you with others who can help you
              along your financial journey with tips, advice and learning. It's
              completely free and open to new and seasoned investors.
            </p>
            <div>
              <CtaButton className="joinc__cta">Join our community</CtaButton>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default JoinCommunity;